import { Button, IconButton } from "@mui/material";
import { Checkbox, Col, Form, Input, Radio, Row, Select, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import "./styles.scss";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import mingcute_edit from "../../images/mingcute_edit.svg";
import icon_done from "../../images/icon_done.svg";

import ModalCheckSheetError from "./Modals/ModalCheckSheetError";

const PageCheckSheet = ({
  handleClickBack,
  valueCheckSheet,
  checkClickEdit,
  handleClickEdit,
  form,
  options8,
  array_logic30,
  options45,
  options48,
  options39,
  options40,
  handleCancelEdit,
  checkLogicRule,
  // handleClickSubmit,
  isModalOpenCheckSheetError,
  setIsModalOpenCheckSheetError,
  listCheckSheetError,
  handleClickSaveDraft,
}) => {
  // No30
  const [valueBE32, setValueBE32] = useState([]);
  const [valueBE35, setValueBE35] = useState([]);
  const [valueBE38, setValueBE38] = useState([]);
  const [valueBE41, setValueBE41] = useState([]);
  const [valueBE44, setValueBE44] = useState([]);
  const [valueBE47, setValueBE47] = useState([]);
  const [valueBE50, setValueBE50] = useState([]);
  const [valueBE53, setValueBE53] = useState([]);
  const [valueBE56, setValueBE56] = useState([]);
  const [valueBE59, setValueBE59] = useState([]);
  const [valueBE62, setValueBE62] = useState([]);
  const [valueBE65, setValueBE65] = useState([]);
  const [valueBE68, setValueBE68] = useState([]);
  const [valueBE74, setValueBE74] = useState([]);
  const [valueBE77, setValueBE77] = useState([]);
  const [valueBE80, setValueBE80] = useState([]);
  const [valueBE86, setValueBE86] = useState([]);
  const [valueBE89, setValueBE89] = useState([]);

  // check No bắt buộc nhập
  const [value8, setValue8] = useState([]);
  const [value9, setValue9] = useState([]);
  const [value22, setValue22] = useState([]);
  const [value23, setValue23] = useState([]);
  const [value24, setValue24] = useState([]);
  const [value25, setValue25] = useState([]);
  const [value26, setValue26] = useState([]);
  const [value27, setValue27] = useState([]);
  const [value28, setValue28] = useState([]);
  const [value29, setValue29] = useState([]);
  const [value45, setValue45] = useState([]);
  const [value47, setValue47] = useState([]);

  const [value35, setValue35] = useState([]);
  const [value36, setValue36] = useState([]);
  const [value39, setValue39] = useState([]);
  const [value40, setValue40] = useState([]);
  const [value41, setValue41] = useState([]);
  const [value42, setValue42] = useState([]);
  const [value43, setValue43] = useState([]);

  // check No46
  const [valueBY98_1, setValueBY98_1] = useState([]);
  const [valueBY98_2, setValueBY98_2] = useState([]);

  const [value52, setValue52] = useState([]);
  const [value49, setValue49] = useState([]);

  const [value48, setValue48] = useState([]);
  const [value50, setValue50] = useState([]);
  const [value53, setValue53] = useState([]);
  const [value34, setValue34] = useState([]);
  const [value10, setValue10] = useState([]);
  const [value11, setValue11] = useState([]);
  const [value12, setValue12] = useState([]);
  const [value13, setValue13] = useState([]);
  const [value14, setValue14] = useState([]);
  const [value15, setValue15] = useState([]);
  const [value16, setValue16] = useState([]);
  const [value17, setValue17] = useState([]);

  const [valueCheck_no1, setValueCheck_no1] = useState([]);
  const [valueCheck_no2, setValueCheck_no2] = useState([]);
  const [valueCheck_no3, setValueCheck_no3] = useState([]);
  const [valueCheck_no4, setValueCheck_no4] = useState([]);
  const [valueCheck_no5, setValueCheck_no5] = useState([]);
  const [valueCheck_no6, setValueCheck_no6] = useState([]);
  const [valueCheck_no57, setValueCheck_no57] = useState([]);
  const [valueCheck_no7_1, setValueCheck_no7_1] = useState([]);
  const [valueCheck_no7_2, setValueCheck_no7_2] = useState([]);
  const [valueCheck_no7_3, setValueCheck_no7_3] = useState([]);
  const [valueCheck_no7_4, setValueCheck_no7_4] = useState([]);
  const [valueCheck_no18, setValueCheck_no18] = useState([]);
  const [valueCheck_no19, setValueCheck_no19] = useState([]);
  const [valueCheck_no20, setValueCheck_no20] = useState([]);
  const [valueCheck_no21, setValueCheck_no21] = useState([]);
  const [valueCheck_no34, setValueCheck_no34] = useState([]);
  const [valueCheck_no35, setValueCheck_no35] = useState([]);
  const [valueCheck_no36, setValueCheck_no36] = useState([]);
  const [valueCheck_no37, setValueCheck_no37] = useState([]);
  const [valueCheck_no38, setValueCheck_no38] = useState([]);
  const [valueCheck_no31, setValueCheck_no31] = useState([]);
  const [valueCheck_no32, setValueCheck_no32] = useState([]);
  const [valueCheck_no33, setValueCheck_no33] = useState([]);
  const [valueCheck_no44_bp65, setValueCheck_no44_bp65] = useState([]);
  const [valueCheck_no44_bp68, setValueCheck_no44_bp68] = useState([]);
  const [valueCheck_no44_bp71, setValueCheck_no44_bp71] = useState([]);
  const [valueCheck_no44_bp74, setValueCheck_no44_bp74] = useState([]);
  const [valueCheck_no44_bp77, setValueCheck_no44_bp77] = useState([]);
  const [valueCheck_no44_bp80, setValueCheck_no44_bp80] = useState([]);
  const [valueCheck_no44_bp83, setValueCheck_no44_bp83] = useState([]);
  const [valueCheck_no44_bp86, setValueCheck_no44_bp86] = useState([]);
  const [valueCheck_no44_bp89, setValueCheck_no44_bp89] = useState([]);
  const [valueCheck_no44_bp92, setValueCheck_no44_bp92] = useState([]);
  const [valueCheck_no54, setValueCheck_no54] = useState([]);
  const [valueCheck_no55, setValueCheck_no55] = useState([]);
  const [valueCheck_no62, setValueCheck_no62] = useState([]);
  const [valueCheck_no63, setValueCheck_no63] = useState([]);
  const [valueCheck_no64, setValueCheck_no64] = useState([]);
  const [valueCheck_no65, setValueCheck_no65] = useState([]);
  const [valueCheck_no66, setValueCheck_no66] = useState([]);

  const [value1, setValue1] = useState([]);
  const [value2, setValue2] = useState([]);
  const [value3, setValue3] = useState([]);
  const [value4, setValue4] = useState([]);
  const [value57, setValue57] = useState([]);
  const [value7_1, setValue7_1] = useState([]);
  const [value7_2, setValue7_2] = useState([]);
  const [value7_3, setValue7_3] = useState([]);
  const [value7_4, setValue7_4] = useState([]);

  useEffect(() => {
    // No30
    setValueBE32(
      listCheckSheetError.filter((item) => item.cell_error.includes("BE32"))
    );
    setValueBE35(
      listCheckSheetError.filter((item) => item.cell_error.includes("BE35"))
    );
    setValueBE38(
      listCheckSheetError.filter((item) => item.cell_error.includes("BE38"))
    );
    setValueBE41(
      listCheckSheetError.filter((item) => item.cell_error.includes("BE41"))
    );
    setValueBE44(
      listCheckSheetError.filter((item) => item.cell_error.includes("BE44"))
    );
    setValueBE47(
      listCheckSheetError.filter((item) => item.cell_error.includes("BE47"))
    );
    setValueBE50(
      listCheckSheetError.filter((item) => item.cell_error.includes("BE50"))
    );
    setValueBE53(
      listCheckSheetError.filter((item) => item.cell_error.includes("BE53"))
    );
    setValueBE56(
      listCheckSheetError.filter((item) => item.cell_error.includes("BE56"))
    );
    setValueBE59(
      listCheckSheetError.filter((item) => item.cell_error.includes("BE59"))
    );
    setValueBE62(
      listCheckSheetError.filter((item) => item.cell_error.includes("BE62"))
    );
    setValueBE65(
      listCheckSheetError.filter((item) => item.cell_error.includes("BE65"))
    );
    setValueBE68(
      listCheckSheetError.filter((item) => item.cell_error.includes("BE68"))
    );
    setValueBE74(
      listCheckSheetError.filter((item) => item.cell_error.includes("BE74"))
    );
    setValueBE77(
      listCheckSheetError.filter((item) => item.cell_error.includes("BE77"))
    );
    setValueBE80(
      listCheckSheetError.filter((item) => item.cell_error.includes("BE80"))
    );
    setValueBE86(
      listCheckSheetError.filter((item) => item.cell_error.includes("BE86"))
    );
    setValueBE89(
      listCheckSheetError.filter((item) => item.cell_error.includes("BE89"))
    );

    // check No bắt buộc nhập
    setValue8(
      listCheckSheetError.filter((item) => item.cell_error.includes("8"))
    );
    setValue9(
      listCheckSheetError.filter((item) => item.cell_error.includes("9"))
    );
    setValue22(
      listCheckSheetError.filter((item) => item.cell_error.includes("22"))
    );
    setValue23(
      listCheckSheetError.filter((item) => item.cell_error.includes("23"))
    );
    setValue24(
      listCheckSheetError.filter((item) => item.cell_error.includes("24"))
    );
    setValue25(
      listCheckSheetError.filter((item) => item.cell_error.includes("25"))
    );
    setValue26(
      listCheckSheetError.filter((item) => item.cell_error.includes("26"))
    );
    setValue27(
      listCheckSheetError.filter((item) => item.cell_error.includes("27"))
    );
    setValue28(
      listCheckSheetError.filter((item) => item.cell_error.includes("28"))
    );
    setValue29(
      listCheckSheetError.filter((item) => item.cell_error.includes("29"))
    );
    setValue45(
      listCheckSheetError.filter((item) => item.cell_error.includes("45"))
    );
    setValue47(
      listCheckSheetError.filter((item) => item.cell_error.includes("47"))
    );
    // setValue511(
    //   listCheckSheetError.filter((item) => item.cell_error.includes("51.1"))
    // );
    // setValue512(
    //   listCheckSheetError.filter((item) => item.cell_error.includes("51.2"))
    // );
    // setValue513(
    //   listCheckSheetError.filter((item) => item.cell_error.includes("51.3"))
    // );
    // setValue514(
    //   listCheckSheetError.filter((item) => item.cell_error.includes("51.4"))
    // );
    setValue35(
      listCheckSheetError.filter((item) => item.cell_error.includes("35"))
    );
    setValue36(
      listCheckSheetError.filter((item) => item.cell_error.includes("36"))
    );
    // setValue37(
    //   listCheckSheetError.filter((item) => item.cell_error.includes("37"))
    // );
    // setValue38(
    //   listCheckSheetError.filter((item) => item.cell_error.includes("38"))
    // );
    setValue39(
      listCheckSheetError.filter((item) => item.cell_error.includes("39"))
    );
    setValue40(
      listCheckSheetError.filter((item) => item.cell_error.includes("40"))
    );
    setValue41(
      listCheckSheetError.filter((item) => item.cell_error.includes("41"))
    );
    setValue42(
      listCheckSheetError.filter((item) => item.cell_error.includes("42"))
    );
    setValue43(
      listCheckSheetError.filter((item) => item.cell_error.includes("43"))
    );
    // console.log(listCheckSheetError);

    // check No46
    setValueBY98_1(
      listCheckSheetError.filter((item) => item.cell_error.includes("BY98_1"))
    );
    setValueBY98_2(
      listCheckSheetError.filter((item) => item.cell_error.includes("BY98_2"))
    );

    // check No52
    setValue52(
      listCheckSheetError.filter((item) => item.cell_error.includes("52"))
    );
    setValue49(
      listCheckSheetError.filter((item) => item.cell_error.includes("49"))
    );

    setValue48(
      listCheckSheetError.filter((item) => item.cell_error.includes("48"))
    );
    setValue50(
      listCheckSheetError.filter((item) => item.cell_error.includes("50"))
    );
    setValue53(
      listCheckSheetError.filter((item) => item.cell_error.includes("53"))
    );
    setValue34(
      listCheckSheetError.filter((item) => item.cell_error.includes("34"))
    );
    setValue10(
      listCheckSheetError.filter((item) => item.cell_error.includes("10"))
    );
    setValue11(
      listCheckSheetError.filter((item) => item.cell_error.includes("11"))
    );
    setValue12(
      listCheckSheetError.filter((item) => item.cell_error.includes("12"))
    );
    setValue13(
      listCheckSheetError.filter((item) => item.cell_error.includes("13"))
    );
    setValue14(
      listCheckSheetError.filter((item) => item.cell_error.includes("14"))
    );
    setValue15(
      listCheckSheetError.filter((item) => item.cell_error.includes("15"))
    );
    setValue16(
      listCheckSheetError.filter((item) => item.cell_error.includes("16"))
    );
    setValue17(
      listCheckSheetError.filter((item) => item.cell_error.includes("17"))
    );

    setValueCheck_no1(
      listCheckSheetError.filter((item) =>
        item.cell_error.includes("check_no1")
      )
    );
    setValueCheck_no2(
      listCheckSheetError.filter((item) =>
        item.cell_error.includes("check_no2")
      )
    );
    setValueCheck_no3(
      listCheckSheetError.filter((item) =>
        item.cell_error.includes("check_no3")
      )
    );
    setValueCheck_no4(
      listCheckSheetError.filter((item) =>
        item.cell_error.includes("check_no4")
      )
    );
    setValueCheck_no5(
      listCheckSheetError.filter((item) =>
        item.cell_error.includes("check_no5")
      )
    );
    setValueCheck_no6(
      listCheckSheetError.filter((item) =>
        item.cell_error.includes("check_no6")
      )
    );
    setValueCheck_no57(
      listCheckSheetError.filter((item) =>
        item.cell_error.includes("check_no57")
      )
    );
    setValueCheck_no7_1(
      listCheckSheetError.filter((item) =>
        item.cell_error.includes("check_no7_1")
      )
    );
    setValueCheck_no7_2(
      listCheckSheetError.filter((item) =>
        item.cell_error.includes("check_no7_2")
      )
    );
    setValueCheck_no7_3(
      listCheckSheetError.filter((item) =>
        item.cell_error.includes("check_no7_3")
      )
    );
    setValueCheck_no7_4(
      listCheckSheetError.filter((item) =>
        item.cell_error.includes("check_no7_4")
      )
    );
    setValueCheck_no18(
      listCheckSheetError.filter((item) =>
        item.cell_error.includes("check_no18")
      )
    );
    setValueCheck_no19(
      listCheckSheetError.filter((item) =>
        item.cell_error.includes("check_no19")
      )
    );
    setValueCheck_no20(
      listCheckSheetError.filter((item) =>
        item.cell_error.includes("check_no20")
      )
    );
    setValueCheck_no21(
      listCheckSheetError.filter((item) =>
        item.cell_error.includes("check_no21")
      )
    );
    setValueCheck_no34(
      listCheckSheetError.filter((item) =>
        item.cell_error.includes("check_no34")
      )
    );
    setValueCheck_no35(
      listCheckSheetError.filter((item) =>
        item.cell_error.includes("check_no35")
      )
    );
    setValueCheck_no36(
      listCheckSheetError.filter((item) =>
        item.cell_error.includes("check_no36")
      )
    );
    setValueCheck_no37(
      listCheckSheetError.filter((item) =>
        item.cell_error.includes("check_no37")
      )
    );
    setValueCheck_no38(
      listCheckSheetError.filter((item) =>
        item.cell_error.includes("check_no38")
      )
    );
    setValueCheck_no31(
      listCheckSheetError.filter((item) =>
        item.cell_error.includes("check_no31")
      )
    );
    setValueCheck_no32(
      listCheckSheetError.filter((item) =>
        item.cell_error.includes("check_no32")
      )
    );
    setValueCheck_no33(
      listCheckSheetError.filter((item) =>
        item.cell_error.includes("check_no33")
      )
    );
    setValueCheck_no44_bp65(
      listCheckSheetError.filter((item) =>
        item.cell_error.includes("check_no44_bp65")
      )
    );
    setValueCheck_no44_bp68(
      listCheckSheetError.filter((item) =>
        item.cell_error.includes("check_no44_bp68")
      )
    );
    setValueCheck_no44_bp71(
      listCheckSheetError.filter((item) =>
        item.cell_error.includes("check_no44_bp71")
      )
    );
    setValueCheck_no44_bp74(
      listCheckSheetError.filter((item) =>
        item.cell_error.includes("check_no44_bp74")
      )
    );
    setValueCheck_no44_bp77(
      listCheckSheetError.filter((item) =>
        item.cell_error.includes("check_no44_bp77")
      )
    );
    setValueCheck_no44_bp80(
      listCheckSheetError.filter((item) =>
        item.cell_error.includes("check_no44_bp80")
      )
    );
    setValueCheck_no44_bp83(
      listCheckSheetError.filter((item) =>
        item.cell_error.includes("check_no44_bp83")
      )
    );
    setValueCheck_no44_bp86(
      listCheckSheetError.filter((item) =>
        item.cell_error.includes("check_no44_bp86")
      )
    );
    setValueCheck_no44_bp89(
      listCheckSheetError.filter((item) =>
        item.cell_error.includes("check_no44_bp89")
      )
    );
    setValueCheck_no44_bp92(
      listCheckSheetError.filter((item) =>
        item.cell_error.includes("check_no44_bp92")
      )
    );
    setValueCheck_no54(
      listCheckSheetError.filter((item) =>
        item.cell_error.includes("check_no54")
      )
    );
    setValueCheck_no55(
      listCheckSheetError.filter((item) =>
        item.cell_error.includes("check_no55")
      )
    );
    setValueCheck_no62(
      listCheckSheetError.filter((item) =>
        item.cell_error.includes("check_no62")
      )
    );
    setValueCheck_no63(
      listCheckSheetError.filter((item) =>
        item.cell_error.includes("check_no63")
      )
    );
    setValueCheck_no64(
      listCheckSheetError.filter((item) =>
        item.cell_error.includes("check_no64")
      )
    );
    setValueCheck_no65(
      listCheckSheetError.filter((item) =>
        item.cell_error.includes("check_no65")
      )
    );
    setValueCheck_no66(
      listCheckSheetError.filter((item) =>
        item.cell_error.includes("check_no66")
      )
    );

    setValue1(
      listCheckSheetError.filter((item) => item.cell_error.includes("1"))
    );
    setValue2(
      listCheckSheetError.filter((item) => item.cell_error.includes("2"))
    );
    setValue3(
      listCheckSheetError.filter((item) => item.cell_error.includes("3"))
    );
    setValue4(
      listCheckSheetError.filter((item) => item.cell_error.includes("4"))
    );
    setValue57(
      listCheckSheetError.filter((item) => item.cell_error.includes("57"))
    );
    setValue7_1(
      listCheckSheetError.filter((item) => item.cell_error.includes("7.1"))
    );
    setValue7_2(
      listCheckSheetError.filter((item) => item.cell_error.includes("7.2"))
    );
    setValue7_3(
      listCheckSheetError.filter((item) => item.cell_error.includes("7.3"))
    );
    setValue7_4(
      listCheckSheetError.filter((item) => item.cell_error.includes("7.4"))
    );
  }, [listCheckSheetError]);

  return (
    <>
      <Row style={{ margin: "10px" }}>
        <Col span={8} style={{ display: "flex", alignItems: "center" }}>
          <IconButton onClick={handleClickBack}>
            <ArrowBackIosNewIcon />
          </IconButton>
          <span
            style={{
              marginLeft: 10,
              color: "black",
              fontSize: "16px",
              fontWeight: 700,
            }}
          >
            {valueCheckSheet?.[4]}
          </span>
        </Col>
        <Col
          span={8}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
        {/* Complete: 完了 */}
          {valueCheckSheet?.status === "完了" ? (
            <img src={icon_done} alt="" style={{ width: 20, height: 20 }} />
          ) : (
            <img src={mingcute_edit} alt="" style={{ width: 20, height: 20 }} />
          )}
          <span
            className="status-column-table"
            data-status={valueCheckSheet?.status}
          >
            {valueCheckSheet?.status}
          </span>
        </Col>
        <Col
          span={8}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          {!checkClickEdit && (
            <IconButton onClick={handleClickEdit}>
              <svg
                width="25"
                height="25"
                viewBox="0 0 50 50"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="0.5"
                  y="0.5"
                  width="49"
                  height="49"
                  rx="9.5"
                  fill="white"
                />
                <rect
                  x="0.5"
                  y="0.5"
                  width="49"
                  height="49"
                  rx="9.5"
                  stroke="#D3D3D3"
                />
                <path
                  d="M34.323 11.2667L38.7334 15.6771M37.1584 7.38126L25.2272 19.3125C24.6088 19.9268 24.1881 20.7117 24.0188 21.5667L22.9167 27.0833L28.4334 25.9792C29.2876 25.8083 30.0709 25.3896 30.6876 24.7729L42.6188 12.8417C42.9774 12.4831 43.2618 12.0575 43.4558 11.589C43.6499 11.1206 43.7497 10.6185 43.7497 10.1115C43.7497 9.60442 43.6499 9.10234 43.4558 8.63389C43.2618 8.16544 42.9774 7.73979 42.6188 7.38126C42.2603 7.02272 41.8347 6.73831 41.3662 6.54427C40.8978 6.35024 40.3957 6.25037 39.8886 6.25037C39.3816 6.25037 38.8795 6.35024 38.411 6.54427C37.9426 6.73831 37.517 7.02272 37.1584 7.38126Z"
                  stroke="#0531D1"
                  stroke-width="4"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M39.5834 31.25V37.5C39.5834 38.6051 39.1444 39.6649 38.363 40.4463C37.5816 41.2277 36.5218 41.6667 35.4167 41.6667H12.5C11.395 41.6667 10.3352 41.2277 9.55376 40.4463C8.77236 39.6649 8.33337 38.6051 8.33337 37.5V14.5834C8.33337 13.4783 8.77236 12.4185 9.55376 11.6371C10.3352 10.8557 11.395 10.4167 12.5 10.4167H18.75"
                  stroke="#0531D1"
                  stroke-width="4"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </IconButton>
          )}
        </Col>
      </Row>

      <div className="container-checksheet">
        <Form
          form={form}
          // onFinish={onFinishAdd}
        >
          <Row className="body-1">
            <Col style={{ width: "90%" }}>
              <Row>
                <Col style={{ width: "60%" }}>
                  <Row className="body-1-area-left">
                    <Col
                      span={12}
                      className="body-1-area-left-col"
                      data-display={false}
                      style={{ textAlign: "center" }}
                    >
                      製造チェックシート
                    </Col>
                    <Col span={12} className="body-1-area-left-col">
                      <span
                        style={{
                          marginLeft: 5,
                          display: "flex",
                          alignItems: "center",
                          width: "40%",
                        }}
                      >
                        制定 ：
                      </span>
                      <Form.Item name="58" className="checksheet-form-item">
                        <Input
                          style={{
                            width: "100%",
                            marginLeft: "5%",
                          }}
                          className="page-check-sheet-input"
                          readOnly={!checkClickEdit}
                        ></Input>
                      </Form.Item>
                    </Col>
                    <Col
                      span={12}
                      className="body-1-area-left-col"
                      data-display={false}
                    >
                      <Row style={{ height: "100%" }}>
                        <Col
                          span={24}
                          style={{
                            fontWeight: 600,
                            textAlign: "center",
                            fontSize: 18,
                            margin: "auto",
                          }}
                        >
                          LK小型
                        </Col>
                      </Row>
                    </Col>
                    <Col span={12}>
                      <Row>
                        <Col span={24} className="body-1-area-left-col">
                          <span
                            style={{
                              marginLeft: 5,
                              display: "flex",
                              alignItems: "center",
                              width: "40%",
                            }}
                          >
                            実施 ：
                          </span>
                          <Form.Item name="59" className="checksheet-form-item">
                            <Input
                              style={{
                                width: "100%",
                                marginLeft: "5%",
                              }}
                              className="page-check-sheet-input"
                              readOnly={!checkClickEdit}
                            ></Input>
                          </Form.Item>
                        </Col>
                        <Col span={24} className="body-1-area-left-col">
                          <span
                            style={{
                              marginLeft: 5,
                              display: "flex",
                              alignItems: "center",
                              width: "40%",
                            }}
                          >
                            改訂 ：
                          </span>
                          <Form.Item name="60" className="checksheet-form-item">
                            <Input
                              style={{
                                width: "100%",
                                marginLeft: "5%",
                              }}
                              className="page-check-sheet-input"
                              readOnly={!checkClickEdit}
                            ></Input>
                          </Form.Item>
                          {/* <div
                            style={{
                              height: "29.84px",
                            }}
                          >
                            <Tooltip
                              placement="top"
                              title={
                                value22.length !== 0 ? value22[0]["22"] : ""
                              }
                            >
                              <Form.Item
                                name="check_new14"
                                valuePropName="checked"
                                style={{
                                  height: "100%",
                                  marginRight: 5,
                                }}
                              >
                                <Checkbox
                                  disabled={!checkClickEdit}
                                  className={
                                    value22.length !== 0
                                      ? "check-box-checksheet"
                                      : ""
                                  }
                                ></Checkbox>
                              </Form.Item>
                            </Tooltip>
                          </div> */}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
                <Col
                  style={{ width: "40%" }}
                  className="body-1-area-center-col"
                >
                  <Form.Item
                    name="61"
                    style={{
                      width: "100%",
                      height: "100%",
                    }}
                    className="checksheet-form-item-61"
                  >
                    <Input
                      style={{
                        width: "100%",
                        height: "100%",
                        textAlign: "right",
                        boxSizing: "border-box",
                        fontSize: 18,
                      }}
                      className="page-check-sheet-input"
                      readOnly={!checkClickEdit}
                    ></Input>
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col
                  style={{
                    borderRight: "1px solid black",
                    borderBottom: "1px solid black",
                    width: "60%",
                  }}
                >
                  <Row style={{ display: "flex" }}>
                    <div
                      className="body-1-area-item-name"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      機種名
                    </div>
                    <Tooltip
                      placement="top"
                      title={value2.length !== 0 ? value2[0]["2"] : ""}
                    >
                      <Form.Item
                        name="2"
                        className="checksheet-form-item"
                        // style={{
                        //   outline:
                        //     value2.length !== 0 ? "1px solid red" : "none",
                        // }}
                      >
                        <Input
                          style={{
                            width: "100%",
                          }}
                          className={`page-check-sheet-input ${value2.length !== 0 ? 'style-error-input' : ''}`}
                          readOnly={!checkClickEdit}
                        ></Input>
                      </Form.Item>
                    </Tooltip>
                    <Tooltip
                      placement="top"
                      title={
                        valueCheck_no2.length !== 0
                          ? valueCheck_no2[0]["check_no2"]
                          : ""
                      }
                    >
                      <Form.Item
                        name="check_no2"
                        valuePropName="checked"
                        style={{
                          height: "100%",
                          marginLeft: "auto",
                          marginBottom: 0,
                          marginRight: 5,
                        }}
                        className={
                            valueCheck_no2.length !== 0
                              ? "style-error-checkbox"
                              : ""
                          }
                      >
                        <Checkbox
                          disabled={!checkClickEdit}
                          // className={
                          //   valueCheck_no2.length !== 0
                          //     ? "check-box-checksheet"
                          //     : ""
                          // }
                        ></Checkbox>
                      </Form.Item>
                    </Tooltip>
                  </Row>
                </Col>
                <Col
                  style={{
                    borderBottom: "1px solid black",
                    width: "40%",
                  }}
                >
                  <Row style={{ display: "flex" }}>
                    <div
                      className="body-1-area-item-name"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      製番
                    </div>
                    <Form.Item name="63" className="checksheet-form-item">
                      <Input
                        style={{
                          width: "100%",
                        }}
                        className="page-check-sheet-input"
                        readOnly={!checkClickEdit}
                      ></Input>
                    </Form.Item>
                    <Tooltip
                      placement="top"
                      title={
                        valueCheck_no63.length !== 0
                          ? valueCheck_no63[0]["check_no63"]
                          : ""
                      }
                    >
                      <Form.Item
                        name="check_no63"
                        valuePropName="checked"
                        style={{
                          height: "100%",
                          marginLeft: "auto",
                          marginBottom: 0,
                          marginRight: 5,
                        }}
                         className={
                            valueCheck_no63.length !== 0
                              ? "style-error-checkbox"
                              : ""
                          }
                      >
                        <Checkbox
                          disabled={!checkClickEdit}
                          // className={
                          //   valueCheck_no63.length !== 0
                          //     ? "check-box-checksheet"
                          //     : ""
                          // }
                        ></Checkbox>
                      </Form.Item>
                    </Tooltip>
                  </Row>
                </Col>
              </Row>
            </Col>

            <Col style={{ width: "10%" }} className="body-1-area-right-col">
              <Col
                span={24}
                className="right-custom"
                style={{ textAlign: "center" }}
              >
                作業指示
              </Col>
              <Col span={24} style={{ height: "55%" }}>
                <Tooltip
                  placement="top"
                  title={value1.length !== 0 ? value1[0]["1"] : ""}
                >
                  <Form.Item
                    name="1"
                    className="checksheet-form-item"
                    // style={{
                    //   outline: value1.length !== 0 ? "1px solid red" : "none",
                    // }}
                  >
                    {/* <span
                    style={{
                      width: "100%",
                    }}
                    className="page-check-sheet-input"
                  > */}
                    <Input
                      style={{
                        width: "100%",
                      }}
                      className={`page-check-sheet-input ${value1.length !== 0 ? 'style-error-input' : ''}`}
                      readOnly={!checkClickEdit}
                    ></Input>
                    {/* {valueCheckSheet?.[1]} */}
                    {/* </span> */}
                  </Form.Item>
                </Tooltip>
              </Col>
              <Col
                span={24}
                style={{
                  height: "29.84px",
                  textAlign: "center",
                }}
              >
                <Tooltip
                  placement="top"
                  title={
                    valueCheck_no1.length !== 0
                      ? valueCheck_no1[0]["check_no1"]
                      : ""
                  }
                >
                  <Form.Item
                    name="check_no1"
                    valuePropName="checked"
                    style={{
                      height: "100%",
                      marginLeft: "auto",
                      marginBottom: 0,
                    }}
                    className={
                            valueCheck_no1.length !== 0
                              ? "style-error-checkbox"
                              : ""
                          }
                  >
                    <Checkbox
                      disabled={!checkClickEdit}
                      // className={
                      //   valueCheck_no1.length !== 0
                      //     ? "check-box-checksheet"
                      //     : ""
                      // }
                    ></Checkbox>
                  </Form.Item>
                </Tooltip>
              </Col>
            </Col>

            <Col span={24} style={{ borderBottom: "2px solid black" }}>
              <Row>
                <Col
                  style={{
                    borderRight: "1px solid black",
                    borderBottom: "1px solid black",
                    width: "54%",
                  }}
                >
                  <Row style={{ display: "flex" }}>
                    <div
                      className="body-1-area-item-name"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      客先名
                    </div>
                    <Form.Item name="62" className="checksheet-form-item">
                      <Input
                        style={{
                          width: "100%",
                        }}
                        className="page-check-sheet-input"
                        readOnly={!checkClickEdit}
                      ></Input>
                    </Form.Item>
                    <Tooltip
                      placement="top"
                      title={
                        valueCheck_no62.length !== 0
                          ? valueCheck_no62[0]["check_no62"]
                          : ""
                      }
                    >
                      <Form.Item
                        name="check_no62"
                        valuePropName="checked"
                        style={{
                          height: "100%",
                          marginLeft: "auto",
                          marginBottom: 0,
                          marginRight: 5,
                        }}
                        className={
                            valueCheck_no62.length !== 0
                              ? "style-error-checkbox"
                              : ""
                          }
                      >
                        <Checkbox
                          disabled={!checkClickEdit}
                          // className={
                          //   valueCheck_no62.length !== 0
                          //     ? "check-box-checksheet"
                          //     : ""
                          // }
                        ></Checkbox>
                      </Form.Item>
                    </Tooltip>
                  </Row>
                </Col>
                <Col
                  style={{
                    borderBottom: "1px solid black",
                    width: "46%",
                  }}
                >
                  <Row style={{ display: "flex" }}>
                    <div
                      className="body-1-area-item-name"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      MFG.No.
                    </div>
                    <Tooltip
                      placement="top"
                      title={value4.length !== 0 ? value4[0]["4"] : ""}
                    >
                      <Form.Item
                        name="4"
                        className="checksheet-form-item"
                        // style={{
                        //   outline:
                        //     value4.length !== 0 ? "1px solid red" : "none",
                        // }}
                      >
                        <Input
                          style={{
                            width: "100%",
                          }}
                          className={`page-check-sheet-input ${value4.length !== 0 ? 'style-error-input' : ''}`}
                          readOnly={!checkClickEdit}
                        ></Input>
                      </Form.Item>
                    </Tooltip>
                    <Tooltip
                      placement="top"
                      title={
                        valueCheck_no4.length !== 0
                          ? valueCheck_no4[0]["check_no4"]
                          : ""
                      }
                    >
                      <Form.Item
                        name="check_no4"
                        valuePropName="checked"
                        style={{
                          height: "100%",
                          marginLeft: "auto",
                          marginBottom: 0,
                          marginRight: 5,
                        }}
                        className={
                            valueCheck_no4.length !== 0
                              ? "style-error-checkbox"
                              : ""
                          }
                      >
                        <Checkbox
                          disabled={!checkClickEdit}
                          // className={
                          //   valueCheck_no4.length !== 0
                          //     ? "check-box-checksheet"
                          //     : ""
                          // }
                        ></Checkbox>
                      </Form.Item>
                    </Tooltip>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col
                  style={{
                    borderRight: "1px solid black",
                    borderBottom: "1px solid black",
                    width: "54%",
                  }}
                >
                  <Row style={{ display: "flex" }}>
                    <Col span={12} style={{ display: "flex" }}>
                      <div
                        className="body-1-area-item-name"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        受注番号
                      </div>
                      <Tooltip
                        placement="top"
                        title={value3.length !== 0 ? value3[0]["3"] : ""}
                      >
                        <Form.Item
                          name="3"
                          className="checksheet-form-item"
                          style={{
                            width: "50%",
                            // outline:
                            //   value3.length !== 0 ? "1px solid red" : "none",
                          }}
                        >
                          <Input
                            style={{
                              width: "100%",
                            }}
                            className={`page-check-sheet-input ${value3.length !== 0 ? 'style-error-input' : ''}`}
                            readOnly={!checkClickEdit}
                          ></Input>
                        </Form.Item>
                      </Tooltip>
                      <Tooltip
                        placement="top"
                        title={
                          valueCheck_no3.length !== 0
                            ? valueCheck_no3[0]["check_no3"]
                            : ""
                        }
                      >
                        <Form.Item
                          name="check_no3"
                          valuePropName="checked"
                          style={{
                            height: "100%",
                            marginLeft: "auto",
                            marginBottom: 0,
                            marginRight: 5,
                          }}
                           className={
                            valueCheck_no3.length !== 0
                              ? "style-error-checkbox"
                              : ""
                          }
                        >
                          <Checkbox
                            disabled={!checkClickEdit}
                            // className={
                            //   valueCheck_no3.length !== 0
                            //     ? "check-box-checksheet"
                            //     : ""
                            // }
                          ></Checkbox>
                        </Form.Item>
                      </Tooltip>
                    </Col>
                    <Col span={12} style={{ display: "flex" }}>
                      <div
                        className="body-1-area-item-name"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        脱脂洗浄
                      </div>
                      <Form.Item
                        name="65"
                        className="checksheet-form-item"
                        style={{ width: "50%" }}
                      >
                        <Input
                          style={{
                            width: "100%",
                          }}
                          className="page-check-sheet-input"
                          readOnly={!checkClickEdit}
                        ></Input>
                      </Form.Item>
                      <Tooltip
                        placement="top"
                        title={
                          valueCheck_no65.length !== 0
                            ? valueCheck_no65[0]["check_no65"]
                            : ""
                        }
                      >
                        <Form.Item
                          name="check_no65"
                          valuePropName="checked"
                          style={{
                            height: "100%",
                            marginLeft: "auto",
                            marginBottom: 0,
                            marginRight: 5,
                          }}
                          className={
                            valueCheck_no65.length !== 0
                              ? "style-error-checkbox"
                              : ""
                          }
                        >
                          <Checkbox
                            disabled={!checkClickEdit}
                            // className={
                            //   valueCheck_no65.length !== 0
                            //     ? "check-box-checksheet"
                            //     : ""
                            // }
                          ></Checkbox>
                        </Form.Item>
                      </Tooltip>
                    </Col>
                  </Row>
                </Col>
                <Col
                  style={{
                    borderBottom: "1px solid black",
                    width: "46%",
                  }}
                >
                  <Row style={{ display: "flex" }}>
                    <div
                      className="body-1-area-item-name"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      ITEM.No.
                    </div>
                    <Form.Item name="5" className="checksheet-form-item">
                      <Input
                        style={{
                          width: "100%",
                        }}
                        className="page-check-sheet-input"
                        readOnly={!checkClickEdit}
                      ></Input>
                    </Form.Item>
                    <Tooltip
                      placement="top"
                      title={
                        valueCheck_no5.length !== 0
                          ? valueCheck_no5[0]["check_no5"]
                          : ""
                      }
                    >
                      <Form.Item
                        name="check_no5"
                        valuePropName="checked"
                        style={{
                          height: "100%",
                          marginLeft: "auto",
                          marginBottom: 0,
                          marginRight: 5,
                        }}
                        className={
                            valueCheck_no5.length !== 0
                              ? "style-error-checkbox"
                              : ""
                          }
                      >
                        <Checkbox
                          disabled={!checkClickEdit}
                          // className={
                          //   valueCheck_no5.length !== 0
                          //     ? "check-box-checksheet"
                          //     : ""
                          // }
                        ></Checkbox>
                      </Form.Item>
                    </Tooltip>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col
                  style={{
                    borderRight: "1px solid black",
                    borderBottom: "1px solid black",
                    width: "54%",
                  }}
                >
                  <Row style={{ display: "flex" }}>
                    <Col span={12} style={{ display: "flex" }}>
                      <div
                        className="body-1-area-item-name"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        成績表
                      </div>
                      <Form.Item
                        name="6"
                        className="checksheet-form-item"
                        style={{ width: "50%" }}
                      >
                        <Input
                          style={{
                            width: "100%",
                          }}
                          className="page-check-sheet-input"
                          readOnly={!checkClickEdit}
                        ></Input>
                      </Form.Item>
                      <Tooltip
                        placement="top"
                        title={
                          valueCheck_no6.length !== 0
                            ? valueCheck_no6[0]["check_no6"]
                            : ""
                        }
                      >
                        <Form.Item
                          name="check_no6"
                          valuePropName="checked"
                          style={{
                            height: "100%",
                            marginLeft: "auto",
                            marginBottom: 0,
                            marginRight: 5,
                          }}
                          className={
                            valueCheck_no6.length !== 0
                              ? "style-error-checkbox"
                              : ""
                          }
                        >
                          <Checkbox
                            disabled={!checkClickEdit}
                            // className={
                            //   valueCheck_no6.length !== 0
                            //     ? "check-box-checksheet"
                            //     : ""
                            // }
                          ></Checkbox>
                        </Form.Item>
                      </Tooltip>
                    </Col>
                    <Col span={12} style={{ display: "flex" }}>
                      <div
                        className="body-1-area-item-name"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        立会
                      </div>
                      <Form.Item
                        name="66"
                        className="checksheet-form-item"
                        style={{
                          width: "50%",
                        }}
                      >
                        <Input
                          style={{
                            width: "100%",
                          }}
                          className="page-check-sheet-input"
                          readOnly={!checkClickEdit}
                        ></Input>
                      </Form.Item>
                      <Tooltip
                        placement="top"
                        title={
                          valueCheck_no66.length !== 0
                            ? valueCheck_no66[0]["check_no66"]
                            : ""
                        }
                      >
                        <Form.Item
                          name="check_no66"
                          valuePropName="checked"
                          style={{
                            height: "100%",
                            marginLeft: "auto",
                            marginBottom: 0,
                            marginRight: 5,
                          }}
                          className={
                            valueCheck_no66.length !== 0
                              ? "style-error-checkbox"
                              : ""
                          }
                        >
                          <Checkbox
                            disabled={!checkClickEdit}
                            // className={
                            //   valueCheck_no66.length !== 0
                            //     ? "check-box-checksheet"
                            //     : ""
                            // }
                          ></Checkbox>
                        </Form.Item>
                      </Tooltip>
                    </Col>
                  </Row>
                </Col>
                <Col style={{ borderBottom: "1px solid black", width: "46%" }}>
                  <Row style={{ display: "flex" }}>
                    <Col span={12} style={{ display: "flex" }}>
                      <div
                        className="body-1-area-item-name"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        写真撮影
                      </div>
                      <Form.Item
                        name="64"
                        className="checksheet-form-item"
                        style={{ width: "40%" }}
                      >
                        <Input
                          style={{
                            width: "100%",
                            textAlign: "center",
                          }}
                          className="page-check-sheet-input"
                          readOnly={!checkClickEdit}
                        ></Input>
                      </Form.Item>
                      <Tooltip
                        placement="top"
                        title={
                          valueCheck_no64.length !== 0
                            ? valueCheck_no64[0]["check_no64"]
                            : ""
                        }
                      >
                        <Form.Item
                          name="check_no64"
                          valuePropName="checked"
                          style={{
                            height: "100%",
                            marginLeft: "auto",
                            marginBottom: 0,
                            marginRight: 5,
                          }}
                          className={
                              valueCheck_no64.length !== 0
                                ? "style-error-checkbox"
                                : ""
                            }
                        >
                          <Checkbox
                            disabled={!checkClickEdit}
                            // className={
                            //   valueCheck_no64.length !== 0
                            //     ? "check-box-checksheet"
                            //     : ""
                            // }
                          ></Checkbox>
                        </Form.Item>
                      </Tooltip>
                    </Col>
                    <Col span={12} style={{ display: "flex" }}>
                      <div
                        className="body-1-area-item-name"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        数量
                      </div>
                      <Tooltip
                        placement="top"
                        title={value57.length !== 0 ? value57[0]["57"] : ""}
                      >
                        <Form.Item
                          name="57"
                          className="checksheet-form-item"
                          style={{
                            width: "50%",
                            // outline:
                            //   value57.length !== 0 ? "1px solid red" : "none",
                          }}
                        >
                          <Input
                            style={{
                              width: "100%",
                            }}
                            className={`page-check-sheet-input ${value57.length !== 0 ? 'style-error-input' : ''}`}
                            readOnly={!checkClickEdit}
                          ></Input>
                        </Form.Item>
                      </Tooltip>
                      <Tooltip
                        placement="top"
                        title={
                          valueCheck_no57.length !== 0
                            ? valueCheck_no57[0]["check_no57"]
                            : ""
                        }
                      >
                        <Form.Item
                          name="check_no57"
                          valuePropName="checked"
                          style={{
                            height: "100%",
                            marginLeft: "auto",
                            marginBottom: 0,
                            marginRight: 5,
                          }}
                          className={
                            valueCheck_no57.length !== 0
                              ? "style-error-checkbox"
                              : ""
                          }
                        >
                          <Checkbox
                            disabled={!checkClickEdit}
                            // className={
                            //   valueCheck_no57.length !== 0
                            //     ? "check-box-checksheet"
                            //     : ""
                            // }
                          ></Checkbox>
                        </Form.Item>
                      </Tooltip>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row>
                <Col
                  style={{
                    width: "54%",
                  }}
                >
                  <Row style={{ display: "flex" }}>
                    <Col span={12} style={{ display: "flex" }}>
                      <div
                        className="body-1-area-item-name"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        銘板指定
                      </div>
                      <Tooltip
                        placement="top"
                        title={value7_1.length !== 0 ? value7_1[0]["7.1"] : ""}
                      >
                        <Form.Item
                          name="7.1"
                          className="checksheet-form-item"
                          style={{
                            width: "50%",
                            // outline:
                            //   value7_1.length !== 0 ? "1px solid red" : "none",
                          }}
                        >
                          <Input
                            style={{
                              width: "100%",
                            }}
                            className={`page-check-sheet-input ${value7_1.length !== 0 ? 'style-error-input' : ''}`}
                            readOnly={!checkClickEdit}
                          ></Input>
                        </Form.Item>
                      </Tooltip>
                      <Tooltip
                        placement="top"
                        title={
                          valueCheck_no7_1.length !== 0
                            ? valueCheck_no7_1[0]["check_no7_1"]
                            : ""
                        }
                      >
                        <Form.Item
                          name="check_no7_1"
                          valuePropName="checked"
                          style={{
                            height: "100%",
                            marginLeft: "auto",
                            marginBottom: 0,
                            marginRight: 5,
                          }}
                          className={
                            valueCheck_no7_1.length !== 0
                              ? "style-error-checkbox"
                              : ""
                          }
                        >
                          <Checkbox
                            disabled={!checkClickEdit}
                            // className={
                            //   valueCheck_no7_1.length !== 0
                            //     ? "check-box-checksheet"
                            //     : ""
                            // }
                          ></Checkbox>
                        </Form.Item>
                      </Tooltip>
                    </Col>
                    <Col span={12} style={{ display: "flex" }}>
                      <div
                        className="body-1-area-item-name"
                        data-no-background={true}
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        L/min
                      </div>
                      <Tooltip
                        placement="top"
                        title={value7_2.length !== 0 ? value7_2[0]["7.2"] : ""}
                      >
                        <Form.Item
                          name="7.2"
                          className="checksheet-form-item"
                          style={{
                            width: "50%",
                            // outline:
                            //   value7_2.length !== 0 ? "1px solid red" : "none",
                          }}
                        >
                          <Input
                            style={{
                              width: "100%",
                            }}
                            className={`page-check-sheet-input ${value7_2.length !== 0 ? 'style-error-input' : ''}`}
                            readOnly={!checkClickEdit}
                          ></Input>
                        </Form.Item>
                      </Tooltip>
                      <Tooltip
                        placement="top"
                        title={
                          valueCheck_no7_2.length !== 0
                            ? valueCheck_no7_2[0]["check_no7_2"]
                            : ""
                        }
                      >
                        <Form.Item
                          name="check_no7_2"
                          valuePropName="checked"
                          style={{
                            height: "100%",
                            marginLeft: "auto",
                            marginBottom: 0,
                            marginRight: 5,
                          }}
                           className={
                            valueCheck_no7_2.length !== 0
                              ? "style-error-checkbox"
                              : ""
                          }
                        >
                          <Checkbox
                            disabled={!checkClickEdit}
                            // className={
                            //   valueCheck_no7_2.length !== 0
                            //     ? "check-box-checksheet"
                            //     : ""
                            // }
                          ></Checkbox>
                        </Form.Item>
                      </Tooltip>
                    </Col>
                  </Row>
                </Col>
                <Col
                  style={{
                    width: "46%",
                  }}
                >
                  <Row style={{ display: "flex" }}>
                    <Col span={12} style={{ display: "flex" }}>
                      <div
                        className="body-1-area-item-name"
                        data-no-background={true}
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        MPa
                      </div>
                      <Tooltip
                        placement="top"
                        title={value7_3.length !== 0 ? value7_3[0]["7.3"] : ""}
                      >
                        <Form.Item
                          name="7.3"
                          className="checksheet-form-item"
                          style={{
                            width: "50%",
                            // outline:
                            //   value7_3.length !== 0 ? "1px solid red" : "none",
                          }}
                        >
                          <Input
                            style={{
                              width: "100%",
                            }}
                            className={`page-check-sheet-input ${value7_3.length !== 0 ? 'style-error-input' : ''}`}
                            readOnly={!checkClickEdit}
                          ></Input>
                        </Form.Item>
                      </Tooltip>
                      <Tooltip
                        placement="top"
                        title={
                          valueCheck_no7_3.length !== 0
                            ? valueCheck_no7_3[0]["check_no7_3"]
                            : ""
                        }
                      >
                        <Form.Item
                          name="check_no7_3"
                          valuePropName="checked"
                          style={{
                            height: "100%",
                            marginLeft: "auto",
                            marginBottom: 0,
                            marginRight: 5,
                          }}
                           className={
                            valueCheck_no7_3.length !== 0
                              ? "style-error-checkbox"
                              : ""
                          }
                        >
                          <Checkbox
                            disabled={!checkClickEdit}
                            // className={
                            //   valueCheck_no7_3.length !== 0
                            //     ? "check-box-checksheet"
                            //     : ""
                            // }
                          ></Checkbox>
                        </Form.Item>
                      </Tooltip>
                    </Col>
                    <Col span={12} style={{ display: "flex" }}>
                      <div
                        className="body-1-area-item-name"
                        data-no-background={true}
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        spm
                      </div>
                      <Tooltip
                        placement="top"
                        title={value7_4.length !== 0 ? value7_4[0]["7.4"] : ""}
                      >
                        <Form.Item
                          name="7.4"
                          className="checksheet-form-item"
                          style={{
                            width: "40%",
                            // outline:
                            //   value7_4.length !== 0 ? "1px solid red" : "none",
                          }}
                        >
                          <Input
                            style={{
                              width: "100%",
                            }}
                            className={`page-check-sheet-input ${value7_4.length !== 0 ? 'style-error-input' : ''}`}
                            readOnly={!checkClickEdit}
                          ></Input>
                        </Form.Item>
                      </Tooltip>
                      <div
                        style={{
                          textAlign: "center",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        Hz
                      </div>
                      <Tooltip
                        placement="top"
                        title={
                          valueCheck_no7_4.length !== 0
                            ? valueCheck_no7_4[0]["check_no7_4"]
                            : ""
                        }
                      >
                        <Form.Item
                          name="check_no7_4"
                          valuePropName="checked"
                          style={{
                            height: "100%",
                            marginLeft: "auto",
                            marginBottom: 0,
                            marginRight: 5,
                          }}
                           className={
                            valueCheck_no7_4.length !== 0
                              ? "style-error-checkbox"
                              : ""
                          }
                        >
                          <Checkbox
                            disabled={!checkClickEdit}
                            // className={
                            //   valueCheck_no7_4.length !== 0
                            //     ? "check-box-checksheet"
                            //     : ""
                            // }
                          ></Checkbox>
                        </Form.Item>
                      </Tooltip>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className="body-2">
            <Col span={16} className="body-2-area-left">
              <Row style={{ borderBottom: "1px solid black" }}>
                <Col span={7} style={{ textAlign: "center" }}>
                  チェック項目
                </Col>
                <Col
                  span={6}
                  style={{ background: "#ced4da", textAlign: "center" }}
                >
                  製造指示書
                </Col>
                <Col
                  span={8}
                  style={{
                    textAlign: "center",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Tooltip
                    placement="top"
                    title={value8.length !== 0 ? value8[0]["8"] : ""}
                  >
                    <Form.Item
                      name="8"
                      className="checksheet-form-item"
                      // style={{
                      //   outline: value8.length !== 0 ? "1px solid red" : "none",
                      // }}
                    >
                      <Radio.Group size="small"
                            className={`${value8.length !== 0 ? 'style-error-radio-btn' : ''}`}
                      >
                        {options8.map((option, index) => (
                          <React.Fragment key={option.value}>
                            <Radio.Button
                              value={option.value}
                              disabled={!checkClickEdit}
                            >
                              {option.label}
                            </Radio.Button>
                            {index < options8.length - 1 && <span>/</span>}
                          </React.Fragment>
                        ))}
                      </Radio.Group>
                    </Form.Item>
                  </Tooltip>
                </Col>
                <Col
                  span={3}
                  style={{ textAlign: "center", borderLeft: "1px solid black" }}
                >
                  作業者
                </Col>
              </Row>
              <Row style={{ borderBottom: "1px solid black" }}>
                <Col
                  span={7}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    borderRight: "1px solid black",
                  }}
                >
                  <span style={{ marginLeft: 5 }}>駆動部ロットNo.</span>
                </Col>
                <Col span={14} style={{ textAlign: "center" }}>
                  <Tooltip
                    placement="top"
                    title={value9.length !== 0 ? value9[0]["9"] : ""}
                  >
                    <Form.Item
                      name="9"
                      className="checksheet-form-item"
                      // style={{
                      //   outline: value9.length !== 0 ? "1px solid red" : "none",
                      // }}
                    >
                      <Input
                        style={{
                          width: "100%",
                        }}
                        className={`page-check-sheet-input ${value9.length !== 0 ? 'style-error-input' : ''}`}
                        data-input-body-center={true}
                        readOnly={!checkClickEdit}
                      ></Input>
                    </Form.Item>
                  </Tooltip>
                </Col>
                <Col
                  span={3}
                  style={{ textAlign: "center", borderLeft: "1px solid black" }}
                >
                  {/* dictError.some(item => item.cell_error.includes("BE32")) */}
                  <Tooltip
                    placement="top"
                    title={valueBE32.length !== 0 ? valueBE32[0]["30"] : ""}
                  >
                    <Form.Item
                      name="BE32"
                      className="checksheet-form-item"
                      // style={{
                      //   outline:
                      //     valueBE32.length !== 0 ? "1px solid red" : "none",
                      // }}
                    >
                      <Select
                        style={{ height: "28.84px"}}
                        disabled={!checkClickEdit}
                        className={`select-check-sheet ${valueBE32.length !== 0 ? 'style-error' : ''}`}
                        suffixIcon
                      >
                        {array_logic30.map((item) => (
                          <Select.Option key={item} value={item}>
                            {item}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Tooltip>
                </Col>
              </Row>
              <Row style={{ borderBottom: "1px solid black" }}>
                <Col
                  span={7}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    borderRight: "1px solid black",
                  }}
                >
                  <span style={{ marginLeft: 5 }}>仕様銘板確認</span>
                </Col>
                <Col span={14} style={{ textAlign: "center" }}>
                  <Form.Item className="checksheet-form-item">
                    <Input
                      style={{
                        width: "100%",
                      }}
                      className="page-check-sheet-input"
                      data-input-body-center={true}
                      readOnly={!checkClickEdit}
                    ></Input>
                  </Form.Item>
                </Col>
                <Col
                  span={3}
                  style={{ textAlign: "center", borderLeft: "1px solid black" }}
                >
                  <Tooltip
                    placement="top"
                    title={valueBE35.length !== 0 ? valueBE35[0]["30"] : ""}
                  >
                    <Form.Item
                      name="BE35"
                      className="checksheet-form-item"
                      // style={{
                      //   outline:
                      //     valueBE35.length !== 0 ? "1px solid red" : "none",
                      // }}
                    >
                      <Select
                        style={{ height: "28.84px" }}
                        disabled={!checkClickEdit}
                        className={`select-check-sheet ${valueBE35.length !== 0 ? 'style-error' : ''}`}
                        suffixIcon
                      >
                        {array_logic30.map((item) => (
                          <Select.Option key={item} value={item}>
                            {item}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Tooltip>
                </Col>
              </Row>
              <Row style={{ borderBottom: "1px solid black" }}>
                <Col
                  span={21}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <span style={{ marginLeft: 5 }}>
                    メモリロッドの接着状態確認（メモリロッドが動かないこと）
                  </span>
                </Col>
                <Col
                  span={3}
                  style={{ textAlign: "center", borderLeft: "1px solid black" }}
                >
                  <Tooltip
                    placement="top"
                    title={valueBE38.length !== 0 ? valueBE38[0]["30"] : ""}
                  >
                    <Form.Item
                      name="BE38"
                      className="checksheet-form-item"
                      // style={{
                      //   outline:
                      //     valueBE38.length !== 0 ? "1px solid red" : "none",
                      // }}
                    >
                      <Select
                        style={{ height: "28.84px" }}
                        disabled={!checkClickEdit}
                        className={`select-check-sheet ${valueBE38.length !== 0 ? 'style-error' : ''}`}
                        suffixIcon
                      >
                        {array_logic30.map((item) => (
                          <Select.Option key={item} value={item}>
                            {item}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Tooltip>
                </Col>
              </Row>
              <Row style={{ borderBottom: "1px solid black" }}>
                <Col
                  span={7}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    borderRight: "1px solid black",
                  }}
                >
                  <span style={{ marginLeft: 5 }}>ポンプヘッド材質</span>
                </Col>
                <Col span={14} style={{ textAlign: "center" }}>
                  <Tooltip
                    placement="top"
                    title={value10.length !== 0 ? value10[0]["10"] : ""}
                  >
                    <Form.Item
                      name="10"
                      className="checksheet-form-item"
                      // style={{
                      //   outline:
                      //     value10.length !== 0 ? "1px solid red" : "none",
                      // }}
                    >
                      <Input
                        style={{
                          width: "100%",
                        }}
                        className={`page-check-sheet-input ${value10.length !== 0 ? 'style-error-input' : ''}`}
                        data-input-body-center={true}
                        readOnly={!checkClickEdit}
                      ></Input>
                    </Form.Item>
                  </Tooltip>
                </Col>
                <Col
                  span={3}
                  style={{ textAlign: "center", borderLeft: "1px solid black" }}
                >
                  <Tooltip
                    placement="top"
                    title={valueBE41.length !== 0 ? valueBE41[0]["30"] : ""}
                  >
                    <Form.Item
                      name="BE41"
                      className="checksheet-form-item"
                      // style={{
                      //   outline:
                      //     valueBE41.length !== 0 ? "1px solid red" : "none",
                      // }}
                    >
                      <Select
                        style={{ height: "28.84px" }}
                        disabled={!checkClickEdit}
                        className={`select-check-sheet ${valueBE41.length !== 0 ? 'style-error' : ''}`}
                        // className="select-check-sheet"
                        suffixIcon
                      >
                        {array_logic30.map((item) => (
                          <Select.Option key={item} value={item}>
                            {item}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Tooltip>
                </Col>
              </Row>
              <Row style={{ borderBottom: "1px solid black" }}>
                <Col
                  span={7}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    borderRight: "1px solid black",
                  }}
                >
                  <span style={{ marginLeft: 5 }}>バルブ材質</span>
                </Col>
                <Col span={14} style={{ textAlign: "center" }}>
                  <Tooltip
                    placement="top"
                    title={value11.length !== 0 ? value11[0]["11"] : ""}
                  >
                    <Form.Item
                      name="11"
                      className="checksheet-form-item"
                      // style={{
                      //   outline:
                      //     value11.length !== 0 ? "1px solid red" : "none",
                      // }}
                    >
                      <Input
                        style={{
                          width: "100%",
                        }}
                        className={`page-check-sheet-input ${value11.length !== 0 ? 'style-error-input' : ''}`}
                        data-input-body-center={true}
                        readOnly={!checkClickEdit}
                      ></Input>
                    </Form.Item>
                  </Tooltip>
                </Col>
                <Col
                  span={3}
                  style={{ textAlign: "center", borderLeft: "1px solid black" }}
                >
                  <Tooltip
                    placement="top"
                    title={valueBE44.length !== 0 ? valueBE44[0]["30"] : ""}
                  >
                    <Form.Item
                      name="BE44"
                      className="checksheet-form-item"
                      // style={{
                      //   outline:
                      //     valueBE44.length !== 0 ? "1px solid red" : "none",
                      // }}
                    >
                      <Select
                        style={{ height: "28.84px" }}
                        disabled={!checkClickEdit}
                        className={`select-check-sheet ${valueBE44.length !== 0 ? 'style-error' : ''}`}
                        suffixIcon
                      >
                        {array_logic30.map((item) => (
                          <Select.Option key={item} value={item}>
                            {item}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Tooltip>
                </Col>
              </Row>
              <Row style={{ borderBottom: "1px solid black" }}>
                <Col
                  span={7}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    borderRight: "1px solid black",
                  }}
                >
                  <span style={{ marginLeft: 5 }}>V.ガイド材質</span>
                </Col>
                <Col span={14} style={{ textAlign: "center" }}>
                  <Tooltip
                    placement="top"
                    title={value12.length !== 0 ? value12[0]["12"] : ""}
                  >
                    <Form.Item
                      name="12"
                      className="checksheet-form-item"
                      // style={{
                      //   outline:
                      //     value12.length !== 0 ? "1px solid red" : "none",
                      // }}
                    >
                      <Input
                        style={{
                          width: "100%",
                        }}
                        className={`page-check-sheet-input ${value12.length !== 0 ? 'style-error-input' : ''}`}
                        data-input-body-center={true}
                        readOnly={!checkClickEdit}
                      ></Input>
                    </Form.Item>
                  </Tooltip>
                </Col>
                <Col
                  span={3}
                  style={{ textAlign: "center", borderLeft: "1px solid black" }}
                >
                  <Tooltip
                    placement="top"
                    title={valueBE47.length !== 0 ? valueBE47[0]["30"] : ""}
                  >
                    <Form.Item
                      name="BE47"
                      className="checksheet-form-item"
                      // style={{
                      //   outline:
                      //     valueBE47.length !== 0 ? "1px solid red" : "none",
                      // }}
                    >
                      <Select
                        style={{ height: "28.84px" }}
                        disabled={!checkClickEdit}
                        className={`select-check-sheet ${valueBE47.length !== 0 ? 'style-error' : ''}`}
                        suffixIcon
                      >
                        {array_logic30.map((item) => (
                          <Select.Option key={item} value={item}>
                            {item}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Tooltip>
                </Col>
              </Row>
              <Row style={{ borderBottom: "1px solid black" }}>
                <Col
                  span={7}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    borderRight: "1px solid black",
                  }}
                >
                  <span style={{ marginLeft: 5 }}>V.シート材質</span>
                </Col>
                <Col span={14} style={{ textAlign: "center" }}>
                  <Tooltip
                    placement="top"
                    title={value13.length !== 0 ? value13[0]["13"] : ""}
                  >
                    <Form.Item
                      name="13"
                      className="checksheet-form-item"
                      // style={{
                      //   outline:
                      //     value13.length !== 0 ? "1px solid red" : "none",
                      // }}
                    >
                      <Input
                        style={{
                          width: "100%",
                        }}
                        className={`page-check-sheet-input ${value10.length !== 0 ? 'style-error-input' : ''}`}
                        data-input-body-center={true}
                        readOnly={!checkClickEdit}
                      ></Input>
                    </Form.Item>
                  </Tooltip>
                </Col>
                <Col
                  span={3}
                  style={{ textAlign: "center", borderLeft: "1px solid black" }}
                >
                  <Tooltip
                    placement="top"
                    title={valueBE50.length !== 0 ? valueBE50[0]["30"] : ""}
                  >
                    <Form.Item
                      name="BE50"
                      className="checksheet-form-item"
                      // style={{
                      //   outline:
                      //     valueBE50.length !== 0 ? "1px solid red" : "none",
                      // }}
                    >
                      <Select
                        style={{ height: "28.84px" }}
                        disabled={!checkClickEdit}
                        className={`select-check-sheet ${valueBE50.length !== 0 ? 'style-error' : ''}`}
                        suffixIcon
                      >
                        {array_logic30.map((item) => (
                          <Select.Option key={item} value={item}>
                            {item}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Tooltip>
                </Col>
              </Row>
              <Row style={{ borderBottom: "1px solid black" }}>
                <Col
                  span={7}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    borderRight: "1px solid black",
                  }}
                >
                  <span style={{ marginLeft: 5 }}>Oリング材質</span>
                </Col>
                <Col span={14} style={{ textAlign: "center" }}>
                  <Tooltip
                    placement="top"
                    title={value14.length !== 0 ? value14[0]["14"] : ""}
                  >
                    <Form.Item
                      name="14"
                      className="checksheet-form-item"
                      // style={{
                      //   outline:
                      //     value14.length !== 0 ? "1px solid red" : "none",
                      // }}
                    >
                      <Input
                        style={{
                          width: "100%",
                        }}
                        className={`page-check-sheet-input ${value14.length !== 0 ? 'style-error-input' : ''}`}
                        data-input-body-center={true}
                        readOnly={!checkClickEdit}
                      ></Input>
                    </Form.Item>
                  </Tooltip>
                </Col>
                <Col
                  span={3}
                  style={{ textAlign: "center", borderLeft: "1px solid black" }}
                >
                  <Tooltip
                    placement="top"
                    title={valueBE53.length !== 0 ? valueBE53[0]["30"] : ""}
                  >
                    <Form.Item
                      name="BE53"
                      className="checksheet-form-item"
                      // style={{
                      //   outline:
                      //     valueBE53.length !== 0 ? "1px solid red" : "none",
                      // }}
                    >
                      <Select
                        style={{ height: "28.84px" }}
                        disabled={!checkClickEdit}
                        className={`select-check-sheet ${valueBE53.length !== 0 ? 'style-error' : ''}`}
                        suffixIcon
                      >
                        {array_logic30.map((item) => (
                          <Select.Option key={item} value={item}>
                            {item}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Tooltip>
                </Col>
              </Row>
              <Row style={{ borderBottom: "1px solid black" }}>
                <Col
                  span={7}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    borderRight: "1px solid black",
                  }}
                >
                  <span style={{ marginLeft: 5 }}>V.ガスケット材質</span>
                </Col>
                <Col span={14} style={{ textAlign: "center" }}>
                  <Tooltip
                    placement="top"
                    title={value15.length !== 0 ? value15[0]["15"] : ""}
                  >
                    <Form.Item
                      name="15"
                      className="checksheet-form-item"
                      // style={{
                      //   outline:
                      //     value15.length !== 0 ? "1px solid red" : "none",
                      // }}
                    >
                      <Input
                        style={{
                          width: "100%",
                        }}
                        className={`page-check-sheet-input ${value15.length !== 0 ? 'style-error-input' : ''}`}
                        data-input-body-center={true}
                        readOnly={!checkClickEdit}
                      ></Input>
                    </Form.Item>
                  </Tooltip>
                </Col>
                <Col
                  span={3}
                  style={{ textAlign: "center", borderLeft: "1px solid black" }}
                >
                  <Tooltip
                    placement="top"
                    title={valueBE56.length !== 0 ? valueBE56[0]["30"] : ""}
                  >
                    <Form.Item
                      name="BE56"
                      className="checksheet-form-item"
                      // style={{
                      //   outline:
                      //     valueBE56.length !== 0 ? "1px solid red" : "none",
                      // }}
                    >
                      <Select
                        style={{ height: "28.84px" }}
                        disabled={!checkClickEdit}
                        className={`select-check-sheet ${valueBE56.length !== 0 ? 'style-error' : ''}`}
                        suffixIcon
                      >
                        {array_logic30.map((item) => (
                          <Select.Option key={item} value={item}>
                            {item}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Tooltip>
                </Col>
              </Row>
              <Row style={{ borderBottom: "1px solid black" }}>
                <Col
                  span={7}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    borderRight: "1px solid black",
                  }}
                >
                  <span style={{ marginLeft: 5 }}>吸込側接続</span>
                </Col>
                <Col span={14} style={{ textAlign: "center" }}>
                  <Tooltip
                    placement="top"
                    title={value16.length !== 0 ? value16[0]["16"] : ""}
                  >
                    <Form.Item
                      name="16"
                      className="checksheet-form-item"
                      // style={{
                      //   outline:
                      //     value16.length !== 0 ? "1px solid red" : "none",
                      // }}
                    >
                      <Input
                        style={{
                          width: "100%",
                        }}
                        className={`page-check-sheet-input ${value16.length !== 0 ? 'style-error-input' : ''}`}
                        data-input-body-center={true}
                        readOnly={!checkClickEdit}
                      ></Input>
                    </Form.Item>
                  </Tooltip>
                </Col>
                <Col
                  span={3}
                  style={{ textAlign: "center", borderLeft: "1px solid black" }}
                >
                  <Tooltip
                    placement="top"
                    title={valueBE59.length !== 0 ? valueBE59[0]["30"] : ""}
                  >
                    <Form.Item
                      name="BE59"
                      className="checksheet-form-item"
                      // style={{
                      //   outline:
                      //     valueBE59.length !== 0 ? "1px solid red" : "none",
                      // }}
                    >
                      <Select
                        style={{ height: "28.84px" }}
                        disabled={!checkClickEdit}
                        className={`select-check-sheet ${valueBE59.length !== 0 ? 'style-error' : ''}`}
                        suffixIcon
                      >
                        {array_logic30.map((item) => (
                          <Select.Option key={item} value={item}>
                            {item}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Tooltip>
                </Col>
              </Row>
              <Row style={{ borderBottom: "1px solid black" }}>
                <Col
                  span={7}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    borderRight: "1px solid black",
                  }}
                >
                  <span style={{ marginLeft: 5 }}>吐出側接続</span>
                </Col>
                <Col span={14} style={{ textAlign: "center" }}>
                  <Tooltip
                    placement="top"
                    title={value17.length !== 0 ? value17[0]["17"] : ""}
                  >
                    <Form.Item
                      name="17"
                      className="checksheet-form-item"
                      // style={{
                      //   outline:
                      //     value17.length !== 0 ? "1px solid red" : "none",
                      // }}
                    >
                      <Input
                        style={{
                          width: "100%",
                        }}
                        className={`page-check-sheet-input ${value17.length !== 0 ? 'style-error-input' : ''}`}
                        data-input-body-center={true}
                        readOnly={!checkClickEdit}
                      ></Input>
                    </Form.Item>
                  </Tooltip>
                </Col>
                <Col
                  span={3}
                  style={{ textAlign: "center", borderLeft: "1px solid black" }}
                >
                  <Tooltip
                    placement="top"
                    title={valueBE62.length !== 0 ? valueBE62[0]["30"] : ""}
                  >
                    <Form.Item
                      name="BE62"
                      className="checksheet-form-item"
                      // style={{
                      //   outline:
                      //     valueBE62.length !== 0 ? "1px solid red" : "none",
                      // }}
                    >
                      <Select
                        style={{ height: "28.84px" }}
                        disabled={!checkClickEdit}
                        className={`select-check-sheet ${valueBE62.length !== 0 ? 'style-error' : ''}`}
                        suffixIcon
                      >
                        {array_logic30.map((item) => (
                          <Select.Option key={item} value={item}>
                            {item}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Tooltip>
                </Col>
              </Row>
              <Row style={{ borderBottom: "1px solid black" }}>
                <Col
                  span={7}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    borderRight: "1px solid black",
                  }}
                >
                  <span style={{ marginLeft: 5 }}>ポンプヘッド傾き確認</span>
                </Col>
                <Col span={14} style={{ textAlign: "center" }}></Col>
                <Col
                  span={3}
                  style={{ textAlign: "center", borderLeft: "1px solid black" }}
                >
                  <Tooltip
                    placement="top"
                    title={valueBE65.length !== 0 ? valueBE65[0]["30"] : ""}
                  >
                    <Form.Item
                      name="BE65"
                      className="checksheet-form-item"
                      // style={{
                      //   outline:
                      //     valueBE65.length !== 0 ? "1px solid red" : "none",
                      // }}
                    >
                      <Select
                        style={{ height: "28.84px" }}
                        disabled={!checkClickEdit}
                        className={`select-check-sheet ${valueBE65.length !== 0 ? 'style-error' : ''}`}
                        suffixIcon
                      >
                        {array_logic30.map((item) => (
                          <Select.Option key={item} value={item}>
                            {item}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Tooltip>
                </Col>
              </Row>
              <Row style={{ borderBottom: "1px solid black" }}>
                <Col
                  span={7}
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Row>
                    <Col span={24} style={{ marginBottom: 1 }}>
                      <span style={{ marginLeft: 5 }}>耐電圧試験</span>
                    </Col>
                    <Col span={24}>
                      <span style={{ marginLeft: 5 }}>絶縁抵抗試験</span>
                    </Col>
                  </Row>
                </Col>
                <Col span={14} style={{ textAlign: "center" }}>
                  <Row>
                    <Col span={24} style={{ marginBottom: 1 }}>
                      <Form.Item className="checksheet-form-item">
                        <Input
                          style={{
                            width: "100%",
                          }}
                          className="page-check-sheet-input"
                          defaultValue="5mA-1800V(異電圧150V以下:1300V/250V以上:2.4E+1200V) 2sec"
                          readOnly={!checkClickEdit}
                        ></Input>
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item className="checksheet-form-item">
                        <Input
                          style={{
                            width: "100%",
                          }}
                          className="page-check-sheet-input"
                          defaultValue="専用モータ50MΩ以上/汎用モータ100M2以上"
                          readOnly={!checkClickEdit}
                        ></Input>
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                <Col
                  span={3}
                  style={{ textAlign: "center", borderLeft: "1px solid black" }}
                >
                  <Tooltip
                    placement="top"
                    title={valueBE68.length !== 0 ? valueBE68[0]["30"] : ""}
                  >
                    <Form.Item
                      name="BE68"
                      className="checksheet-form-item item-input"
                      style={{
                        height: "100%",
                        // outline:
                        //   valueBE68.length !== 0 ? "1px solid red" : "none",
                      }}
                    >
                      <Select
                        style={{ height: "100%" }}
                        disabled={!checkClickEdit}
                        className={`select-check-sheet ${valueBE38.length !== 0 ? 'style-error' : ''}`}
                        suffixIcon
                      >
                        {array_logic30.map((item) => (
                          <Select.Option key={item} value={item}>
                            {item}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Tooltip>
                </Col>
              </Row>
              <Row style={{ borderBottom: "1px solid black" }}>
                <Col
                  span={21}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <span style={{ marginLeft: 5 }}>ポンプヘッド部漏れ確認</span>
                </Col>
                <Col
                  span={3}
                  style={{ textAlign: "center", borderLeft: "1px solid black" }}
                >
                  <Tooltip
                    placement="top"
                    title={valueBE74.length !== 0 ? valueBE74[0]["30"] : ""}
                  >
                    <Form.Item
                      name="BE74"
                      className="checksheet-form-item"
                      // style={{
                      //   outline:
                      //     valueBE74.length !== 0 ? "1px solid red" : "none",
                      // }}
                    >
                      <Select
                        style={{ height: "28.84px" }}
                        disabled={!checkClickEdit}
                        className={`select-check-sheet ${valueBE74.length !== 0 ? 'style-error' : ''}`}
                        suffixIcon
                      >
                        {array_logic30.map((item) => (
                          <Select.Option key={item} value={item}>
                            {item}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Tooltip>
                </Col>
              </Row>
              <Row style={{ borderBottom: "1px solid black" }}>
                <Col
                  span={21}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <span style={{ marginLeft: 5 }}>
                    異常振動・異常音の無いこと
                  </span>
                </Col>
                <Col
                  span={3}
                  style={{ textAlign: "center", borderLeft: "1px solid black" }}
                >
                  <Tooltip
                    placement="top"
                    title={valueBE77.length !== 0 ? valueBE77[0]["30"] : ""}
                  >
                    <Form.Item
                      name="BE77"
                      className="checksheet-form-item"
                      // style={{
                      //   outline:
                      //     valueBE77.length !== 0 ? "1px solid red" : "none",
                      // }}
                    >
                      <Select
                        style={{ height: "28.84px" }}
                        disabled={!checkClickEdit}
                        className={`select-check-sheet ${valueBE77.length !== 0 ? 'style-error' : ''}`}
                        suffixIcon
                      >
                        {array_logic30.map((item) => (
                          <Select.Option key={item} value={item}>
                            {item}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Tooltip>
                </Col>
              </Row>
              <Row style={{ borderBottom: "1px solid black" }}>
                <Col
                  span={6}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    borderRight: "1px solid black",
                  }}
                >
                  <span style={{ marginLeft: 5 }}>ホースタイプ付属品</span>
                </Col>
                <Col span={15} style={{ textAlign: "center" }}>
                  <Row style={{ height: "100%" }}>
                    <Col
                      span={5}
                      style={{
                        borderRight: "1px solid black",
                        borderBottom: "1px solid black",
                      }}
                    >
                      <span>ホース</span>
                    </Col>
                    <Col
                      span={5}
                      style={{
                        borderBottom: "1px solid black",
                      }}
                    >
                      <Form.Item name="18" className="checksheet-form-item">
                        <Input
                          style={{
                            width: "100%",
                          }}
                          className="page-check-sheet-input custom-input-antd"
                          data-input-body-center={true}
                          readOnly={!checkClickEdit}
                        ></Input>
                      </Form.Item>
                    </Col>
                    <Col
                      span={2}
                      style={{
                        borderRight: "1px solid black",
                        borderBottom: "1px solid black",
                        height: "29.84px",
                      }}
                        className="align-col-checkbox"
                    >
                      <Tooltip
                        placement="top"
                        title={
                          valueCheck_no18.length !== 0
                            ? valueCheck_no18[0]["check_no18"]
                            : ""
                        }
                      >
                        <Form.Item
                          name="check_no18"
                          valuePropName="checked"
                          style={{
                            height: "100%",
                          }}
                          className={
                            valueCheck_no18.length !== 0
                              ? "style-error-checkbox"
                              : ""
                          }
                        >
                          <Checkbox
                            disabled={!checkClickEdit}
                            // className={
                            //   valueCheck_no18.length !== 0
                            //     ? "check-box-checksheet"
                            //     : ""
                            // }
                          ></Checkbox>
                        </Form.Item>
                      </Tooltip>
                    </Col>
                    <Col
                      span={5}
                      style={{
                        borderRight: "1px solid black",
                        borderBottom: "1px solid black",
                      }}
                    >
                      <span>ストレーナ</span>
                    </Col>
                    <Col span={5} style={{ borderBottom: "1px solid black" }}>
                      <Form.Item name="19" className="checksheet-form-item">
                        <Input
                          style={{
                            width: "100%",
                          }}
                          className="page-check-sheet-input custom-input-antd"
                          data-input-body-center={true}
                          readOnly={!checkClickEdit}
                        ></Input>
                      </Form.Item>
                    </Col>
                    <Col
                      span={2}
                      style={{
                        borderBottom: "1px solid black",
                        height: "29.84px",
                      }}
                        className="align-col-checkbox"
                    >
                      <Tooltip
                        placement="top"
                        title={
                          valueCheck_no19.length !== 0
                            ? valueCheck_no19[0]["check_no19"]
                            : ""
                        }
                      >
                        <Form.Item
                          name="check_no19"
                          valuePropName="checked"
                          style={{
                            height: "100%",
                          }}
                          className={
                            valueCheck_no19.length !== 0
                              ? "style-error-checkbox"
                              : ""
                          }
                        >
                          <Checkbox
                            disabled={!checkClickEdit}
                            // className={
                            //   valueCheck_no19.length !== 0
                            //     ? "check-box-checksheet"
                            //     : ""
                            // }
                          ></Checkbox>
                        </Form.Item>
                      </Tooltip>
                    </Col>
                    <Col
                      span={5}
                      style={{
                        borderRight: "1px solid black",
                      }}
                    >
                      <span>BVC</span>
                    </Col>
                    <Col span={17}>
                      <Form.Item name="20" className="checksheet-form-item">
                        <Input
                          style={{
                            width: "100%",
                          }}
                          className="page-check-sheet-input"
                          data-input-body-center={true}
                          readOnly={!checkClickEdit}
                        ></Input>
                      </Form.Item>
                    </Col>
                    <Col
                      span={2}
                      style={{
                        height: "29.84px",
                      }}
                        className="align-col-checkbox"
                    >
                      <Tooltip
                        placement="top"
                        title={
                          valueCheck_no20.length !== 0
                            ? valueCheck_no20[0]["check_no20"]
                            : ""
                        }
                      >
                        <Form.Item
                          name="check_no20"
                          valuePropName="checked"
                          style={{
                            height: "100%",
                          }}
                          className={
                            valueCheck_no20.length !== 0
                              ? "style-error-checkbox"
                              : ""
                          }
                        >
                          <Checkbox
                            disabled={!checkClickEdit}
                            // className={
                            //   valueCheck_no20.length !== 0
                            //     ? "check-box-checksheet"
                            //     : ""
                            // }
                          ></Checkbox>
                        </Form.Item>
                      </Tooltip>
                    </Col>
                  </Row>
                </Col>
                <Col
                  span={3}
                  style={{ textAlign: "center", borderLeft: "1px solid black" }}
                >
                  <Tooltip
                    placement="top"
                    title={valueBE80.length !== 0 ? valueBE80[0]["30"] : ""}
                  >
                    <Form.Item
                      name="BE80"
                      className="checksheet-form-item item-input"
                      style={{
                        height: "100%",
                        // outline:
                        //   valueBE80.length !== 0 ? "1px solid red" : "none",
                      }}
                    >
                      <Select
                        style={{ height: "100%" }}
                        disabled={!checkClickEdit}
                        className={`select-check-sheet ${valueBE80.length !== 0 ? 'style-error' : ''}`}
                        suffixIcon
                      >
                        {array_logic30.map((item) => (
                          <Select.Option key={item} value={item}>
                            {item}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Tooltip>
                </Col>
              </Row>
              <Row style={{ borderBottom: "1px solid black" }}>
                <Col
                  style={{
                    display: "flex",
                    alignItems: "center",
                    borderRight: "1px solid black",
                    width: "40.2%",
                  }}
                >
                  <span style={{ marginLeft: 5 }}>Oリング（P-20）材質確認</span>
                </Col>
                <Col style={{ textAlign: "center", width: "42.3%" }}>
                  <Form.Item name="21" className="checksheet-form-item">
                    <Input
                      style={{
                        width: "100%",
                      }}
                      className="page-check-sheet-input"
                      data-input-body-center={true}
                      readOnly={!checkClickEdit}
                    ></Input>
                  </Form.Item>
                </Col>
                <Col
                  style={{
                    width: "5%",
                  }}
                        className="align-col-checkbox"
                >
                  <Tooltip
                    placement="top"
                    title={
                      valueCheck_no21.length !== 0
                        ? valueCheck_no21[0]["check_no21"]
                        : ""
                    }
                  >
                    <Form.Item
                      name="check_no21"
                      valuePropName="checked"
                      style={{
                        height: "27.84px",
                        marginBottom: "0px",
                      }}
                      className={
                            valueCheck_no21.length !== 0
                              ? "style-error-checkbox"
                              : ""
                          }
                    >
                      <Checkbox
                        disabled={!checkClickEdit}
                        // className={
                        //   valueCheck_no21.length !== 0
                        //     ? "check-box-checksheet"
                        //     : ""
                        // }
                      ></Checkbox>
                    </Form.Item>
                  </Tooltip>
                </Col>
                <Col
                  span={3}
                  style={{ textAlign: "center", borderLeft: "1px solid black" }}
                >
                  <Tooltip
                    placement="top"
                    title={valueBE86.length !== 0 ? valueBE86[0]["30"] : ""}
                  >
                    <Form.Item
                      name="BE86"
                      className="checksheet-form-item"
                      // style={{
                      //   outline:
                      //     valueBE86.length !== 0 ? "1px solid red" : "none",
                      // }}
                    >
                      <Select
                        style={{ height: "28.84px" }}
                        disabled={!checkClickEdit}
                        className={`select-check-sheet ${valueBE86.length !== 0 ? 'style-error' : ''}`}
                        suffixIcon
                      >
                        {array_logic30.map((item) => (
                          <Select.Option key={item} value={item}>
                            {item}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Tooltip>
                </Col>
              </Row>
              <Row style={{ borderBottom: "2px solid black" }}>
                <Col span={21}>
                  <Row
                    style={{ borderBottom: "1px solid black", height: 28.84 }}
                  >
                    <Col span={6} style={{ borderRight: "1px solid black" }}>
                      <Row>
                        <Col span={18}>
                          <span style={{ marginLeft: 5 }}>ねじ部締付</span>
                        </Col>
                        <Col span={6}
                        className="align-col-checkbox"
                        >
                          <Tooltip
                            placement="top"
                            title={value22.length !== 0 ? value22[0]["22"] : ""}
                          >
                            <Form.Item
                              name="22"
                              valuePropName="checked"
                              style={{
                                height: "27.84px",
                              }}
                              className={
                                value22.length !== 0
                                  ? "style-error-checkbox"
                                  : ""
                              }
                            >
                              <Checkbox
                                disabled={!checkClickEdit}
                                // className={
                                //   value22.length !== 0
                                //     ? "check-box-checksheet"
                                //     : ""
                                // }
                              ></Checkbox>
                            </Form.Item>
                          </Tooltip>
                        </Col>
                      </Row>
                    </Col>
                    <Col span={6} style={{ borderRight: "1px solid black" }}>
                      <Row>
                        <Col span={18}>
                          <span style={{ marginLeft: 5 }}>汚れ・キズ</span>
                        </Col>
                        <Col span={6}
                        className="align-col-checkbox"
                        >
                          <Tooltip
                            placement="top"
                            title={value23.length !== 0 ? value23[0]["23"] : ""}
                          >
                            <Form.Item
                              name="23"
                              valuePropName="checked"
                              style={{
                                height: "27.84px",
                              }}
                              className={
                                value23.length !== 0
                                  ? "style-error-checkbox"
                                  : ""
                              }
                            >
                              <Checkbox
                                disabled={!checkClickEdit}
                                // className={
                                //   value23.length !== 0
                                //     ? "check-box-checksheet"
                                //     : ""
                                // }
                              ></Checkbox>
                            </Form.Item>
                          </Tooltip>
                        </Col>
                      </Row>
                    </Col>
                    <Col span={6} style={{ borderRight: "1px solid black" }}>
                      <Row>
                        <Col span={18}>
                          <span style={{ marginLeft: 5 }}>塗装割れ・錆</span>
                        </Col>
                        <Col span={6}
                        className="align-col-checkbox"
                        >
                          <Tooltip
                            placement="top"
                            title={value24.length !== 0 ? value24[0]["24"] : ""}
                          >
                            <Form.Item
                              name="24"
                              valuePropName="checked"
                              style={{
                                height: "27.84px",
                              }}
                              className={
                                value24.length !== 0
                                  ? "style-error-checkbox"
                                  : ""
                              }
                            >
                              <Checkbox
                                disabled={!checkClickEdit}
                                // className={
                                //   value24.length !== 0
                                //     ? "check-box-checksheet"
                                //     : ""
                                // }
                              ></Checkbox>
                            </Form.Item>
                          </Tooltip>
                        </Col>
                      </Row>
                    </Col>
                    <Col span={6}>
                      <Row>
                        <Col span={18}>
                          <span style={{ marginLeft: 5 }}>貼り銘板</span>
                        </Col>
                        <Col span={6}
                        className="align-col-checkbox"
                        >
                          <Tooltip
                            placement="top"
                            title={value25.length !== 0 ? value25[0]["25"] : ""}
                          >
                            <Form.Item
                              name="25"
                              valuePropName="checked"
                              style={{
                                height: "27.84px",
                              }}
                              className={
                                value25.length !== 0
                                  ? "style-error-checkbox"
                                  : ""
                              }
                            >
                              <Checkbox
                                disabled={!checkClickEdit}
                                // className={
                                //   value25.length !== 0
                                //     ? "check-box-checksheet"
                                //     : ""
                                // }
                              ></Checkbox>
                            </Form.Item>
                          </Tooltip>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row style={{ height: 29.84 }}>
                    <Col span={6}>
                      <Row
                        style={{
                          height: 29.84,
                          borderRight: "1px solid black",
                        }}
                      >
                        <Col span={18}>
                          <span style={{ marginLeft: 5 }}>取説</span>
                        </Col>
                        <Col span={6}
                        className="align-col-checkbox"
                        >
                          <Tooltip
                            placement="top"
                            title={value26.length !== 0 ? value26[0]["26"] : ""}
                          >
                            <Form.Item
                              name="26"
                              valuePropName="checked"
                              style={{
                                height: "28.84px",
                              }}
                              className={
                                value26.length !== 0
                                  ? "style-error-checkbox"
                                  : ""
                              }
                            >
                              <Checkbox
                                disabled={!checkClickEdit}
                                // className={
                                //   value26.length !== 0
                                //     ? "check-box-checksheet"
                                //     : ""
                                // }
                              ></Checkbox>
                            </Form.Item>
                          </Tooltip>
                        </Col>
                      </Row>
                    </Col>
                    <Col span={6}>
                      <Row
                        style={{
                          height: 29.84,
                          borderRight: "1px solid black",
                        }}
                      >
                        <Col span={18}>
                          <span style={{ marginLeft: 5 }}>エアブリーザ</span>
                        </Col>
                        <Col span={6}
                        className="align-col-checkbox"
                        >
                          <Tooltip
                            placement="top"
                            title={value27.length !== 0 ? value27[0]["27"] : ""}
                          >
                            <Form.Item
                              name="27"
                              valuePropName="checked"
                              style={{
                                height: "28.84px",
                              }}
                              className={
                                value27.length !== 0
                                  ? "style-error-checkbox"
                                  : ""
                              }
                            >
                              <Checkbox
                                disabled={!checkClickEdit}
                                // className={
                                //   value27.length !== 0
                                //     ? "check-box-checksheet"
                                //     : ""
                                // }
                              ></Checkbox>
                            </Form.Item>
                          </Tooltip>
                        </Col>
                      </Row>
                    </Col>
                    <Col span={6}>
                      <Row
                        style={{
                          height: 29.84,
                          borderRight: "1px solid black",
                        }}
                      >
                        <Col span={18}>
                          <span style={{ marginLeft: 5 }}>ボルトSET</span>
                        </Col>
                        <Col span={6}
                        className="align-col-checkbox"
                        >
                          <Tooltip
                            placement="top"
                            title={value28.length !== 0 ? value28[0]["28"] : ""}
                          >
                            <Form.Item
                              name="28"
                              valuePropName="checked"
                              style={{
                                height: "28.84px",
                              }}
                              className={
                                value28.length !== 0
                                  ? "style-error-checkbox"
                                  : ""
                              }
                            >
                              <Checkbox
                                disabled={!checkClickEdit}
                                // className={
                                //   value28.length !== 0
                                //     ? "check-box-checksheet"
                                //     : ""
                                // }
                              ></Checkbox>
                            </Form.Item>
                          </Tooltip>
                        </Col>
                      </Row>
                    </Col>
                    <Col span={6}>
                      <Row>
                        <Col span={18}>
                          <span style={{ marginLeft: 5 }}>梱包確認</span>
                        </Col>
                        <Col span={6}
                        className="align-col-checkbox"
                        >
                          <Tooltip
                            placement="top"
                            title={value29.length !== 0 ? value29[0]["29"] : ""}
                          >
                            <Form.Item
                              name="29"
                              valuePropName="checked"
                              style={{
                                height: "28.84px",
                              }}
                              className={
                                value29.length !== 0
                                  ? "style-error-checkbox"
                                  : ""
                              }
                            >
                              <Checkbox
                                disabled={!checkClickEdit}
                                // className={
                                //   value29.length !== 0
                                //     ? "check-box-checksheet"
                                //     : ""
                                // }
                              ></Checkbox>
                            </Form.Item>
                          </Tooltip>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
                <Col
                  span={3}
                  style={{ textAlign: "center", borderLeft: "1px solid black" }}
                >
                  <Tooltip
                    placement="top"
                    title={valueBE89.length !== 0 ? valueBE89[0]["30"] : ""}
                  >
                    <Form.Item
                      name="BE89"
                      className="checksheet-form-item item-input"
                      style={{
                        height: "100%",
                        // outline:
                        //   valueBE89.length !== 0 ? "1px solid red" : "none",
                      }}
                    >
                      <Select
                        style={{ height: "100%" }}
                        disabled={!checkClickEdit}
                        className={`select-check-sheet ${valueBE89.length !== 0 ? 'style-error' : ''}`}
                        suffixIcon
                      >
                        {array_logic30.map((item) => (
                          <Select.Option key={item} value={item}>
                            {item}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Tooltip>
                </Col>
              </Row>
            </Col>
            <Col span={8} className="body-2-area-right">
              <Row style={{ borderBottom: "1px solid black", height: "25px" }}>
                <Col
                  span={24}
                  style={{ background: "#ced4da", textAlign: "center" }}
                >
                  モータ仕様
                </Col>
              </Row>
              <Row style={{ borderBottom: "1px solid black" }}>
                <Col
                  span={7}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    borderRight: "1px solid black",
                  }}
                >
                  <span style={{ marginLeft: 5 }}>メーカー</span>
                </Col>
                <Col span={15} style={{ textAlign: "center" }}>
                  <Form.Item name="31" className="checksheet-form-item">
                    <Input
                      style={{
                        width: "100%",
                      }}
                      className="page-check-sheet-input"
                      data-input-body-center={true}
                      readOnly={!checkClickEdit}
                    ></Input>
                  </Form.Item>
                </Col>
                <Col
                  span={2}
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Tooltip
                    placement="top"
                    title={
                      valueCheck_no31.length !== 0
                        ? valueCheck_no31[0]["check_no31"]
                        : ""
                    }
                  >
                    <Form.Item
                      name="check_no31"
                      valuePropName="checked"
                      style={{
                        height: "28.84px",
                        marginLeft: "auto",
                        marginBottom: 0,
                        marginRight: 5,
                      }}
                      className={
                        valueCheck_no31.length !== 0
                          ? "style-error-checkbox"
                          : ""
                      }
                    >
                      <Checkbox
                        disabled={!checkClickEdit}
                        // className={
                        //   valueCheck_no31.length !== 0
                        //     ? "check-box-checksheet"
                        //     : ""
                        // }
                      ></Checkbox>
                    </Form.Item>
                  </Tooltip>
                </Col>
              </Row>
              <Row style={{ borderBottom: "1px solid black" }}>
                <Col
                  span={7}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    borderRight: "1px solid black",
                  }}
                >
                  <span style={{ marginLeft: 5 }}>構造</span>
                </Col>
                <Col span={15} style={{ textAlign: "center" }}>
                  <Form.Item name="32" className="checksheet-form-item">
                    <Input
                      style={{
                        width: "100%",
                      }}
                      className="page-check-sheet-input"
                      data-input-body-center={true}
                      readOnly={!checkClickEdit}
                    ></Input>
                  </Form.Item>
                </Col>
                <Col
                  span={2}
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Tooltip
                    placement="top"
                    title={
                      valueCheck_no32.length !== 0
                        ? valueCheck_no32[0]["check_no32"]
                        : ""
                    }
                  >
                    <Form.Item
                      name="check_no32"
                      valuePropName="checked"
                      style={{
                        height: "28.84px",
                        marginLeft: "auto",
                        marginBottom: 0,
                        marginRight: 5,
                      }}
                      className={
                            valueCheck_no32.length !== 0
                              ? "style-error-checkbox"
                              : ""
                          }
                    >
                      <Checkbox
                        disabled={!checkClickEdit}
                        // className={
                        //   valueCheck_no32.length !== 0
                        //     ? "check-box-checksheet"
                        //     : ""
                        // }
                      ></Checkbox>
                    </Form.Item>
                  </Tooltip>
                </Col>
              </Row>
              <Row style={{ borderBottom: "1px solid black" }}>
                <Col
                  span={7}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    borderRight: "1px solid black",
                  }}
                >
                  <span style={{ marginLeft: 5 }}>屋内・外</span>
                </Col>
                <Col span={15} style={{ textAlign: "center" }}>
                  <Form.Item name="33" className="checksheet-form-item">
                    <Input
                      style={{
                        width: "100%",
                      }}
                      className="page-check-sheet-input"
                      data-input-body-center={true}
                      readOnly={!checkClickEdit}
                    ></Input>
                  </Form.Item>
                </Col>
                <Col
                  span={2}
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Tooltip
                    placement="top"
                    title={
                      valueCheck_no33.length !== 0
                        ? valueCheck_no33[0]["check_no33"]
                        : ""
                    }
                  >
                    <Form.Item
                      name="check_no33"
                      valuePropName="checked"
                      style={{
                        height: "28.84px",
                        marginLeft: "auto",
                        marginBottom: 0,
                        marginRight: 5,
                      }}
                      className={
                            valueCheck_no33.length !== 0
                              ? "style-error-checkbox"
                              : ""
                          }
                    >
                      <Checkbox
                        disabled={!checkClickEdit}
                        // className={
                        //   valueCheck_no33.length !== 0
                        //     ? "check-box-checksheet"
                        //     : ""
                        // }
                      ></Checkbox>
                    </Form.Item>
                  </Tooltip>
                </Col>
              </Row>
              <Row style={{ borderBottom: "1px solid black" }}>
                <Col
                  span={12}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    borderRight: "1px solid black",
                  }}
                >
                  <Row>
                    <Col span={10}>
                      <Tooltip
                        placement="top"
                        title={value34.length !== 0 ? value34[0]["34"] : ""}
                      >
                        <Form.Item
                          name="34"
                          className="checksheet-form-item"
                          // style={{
                          //   outline:
                          //     value34.length !== 0 ? "1px solid red" : "none",
                          // }}
                        >
                          <Input
                            style={{
                              width: "100%",
                            }}
                            className={`page-check-sheet-input ${value34.length !== 0 ? 'style-error-input' : ''}`}
                            data-input-body-center={true}
                            readOnly={!checkClickEdit}
                          ></Input>
                        </Form.Item>
                      </Tooltip>
                    </Col>
                    <Col
                      span={10}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <span
                        style={{
                          marginLeft: 5,
                        }}
                      >
                        kW
                      </span>
                    </Col>
                    <Col
                      span={4}
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Tooltip
                        placement="top"
                        title={
                          valueCheck_no34.length !== 0
                            ? valueCheck_no34[0]["check_no34"]
                            : ""
                        }
                      >
                        <Form.Item
                          name="check_no34"
                          valuePropName="checked"
                          style={{
                            height: "28.84px",
                            marginLeft: "auto",
                            marginBottom: 0,
                            marginRight: 5,
                          }}
                          className={
                            valueCheck_no34.length !== 0
                              ? "style-error-checkbox"
                              : ""
                          }
                        >
                          <Checkbox
                            disabled={!checkClickEdit}
                            // className={
                            //   valueCheck_no34.length !== 0
                            //     ? "check-box-checksheet"
                            //     : ""
                            // }
                          ></Checkbox>
                        </Form.Item>
                      </Tooltip>
                    </Col>
                  </Row>
                </Col>
                <Col span={12} style={{ textAlign: "center" }}>
                  <Row>
                    <Col span={10}>
                      <Tooltip
                        placement="top"
                        title={value35.length !== 0 ? value35[0]["35"] : ""}
                      >
                        <Form.Item
                          name="35"
                          className="checksheet-form-item"
                          // style={{
                          //   outline:
                          //     value35.length !== 0 ? "1px solid red" : "none",
                          // }}
                        >
                          <Input
                            style={{
                              width: "80%",
                            }}
                            className={`page-check-sheet-input ${value35.length !== 0 ? 'style-error-input' : ''}`}
                            data-input-body-center={true}
                            readOnly={!checkClickEdit}
                          ></Input>
                        </Form.Item>
                      </Tooltip>
                    </Col>
                    <Col
                      span={10}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <span
                        style={{
                          marginLeft: 5,
                        }}
                      >
                        P
                      </span>
                    </Col>
                    <Col
                      span={4}
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Tooltip
                        placement="top"
                        title={
                          valueCheck_no35.length !== 0
                            ? valueCheck_no35[0]["check_no35"]
                            : ""
                        }
                      >
                        <Form.Item
                          name="check_no35"
                          valuePropName="checked"
                          style={{
                            height: "28.84px",
                            marginLeft: "auto",
                            marginBottom: 0,
                            marginRight: 5,
                          }}
                           className={
                            valueCheck_no35.length !== 0
                              ? "style-error-checkbox"
                              : ""
                          }
                        >
                          <Checkbox
                            disabled={!checkClickEdit}
                            // className={
                            //   valueCheck_no35.length !== 0
                            //     ? "check-box-checksheet"
                            //     : ""
                            // }
                          ></Checkbox>
                        </Form.Item>
                      </Tooltip>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row style={{ borderBottom: "1px solid black" }}>
                <Col
                  span={12}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    borderRight: "1px solid black",
                  }}
                >
                  <Row>
                    <Col span={10}>
                      <Tooltip
                        placement="top"
                        title={value36.length !== 0 ? value36[0]["36"] : ""}
                      >
                        <Form.Item
                          name="36"
                          className="checksheet-form-item"
                          // style={{
                          //   outline:
                          //     value36.length !== 0 ? "1px solid red" : "none",
                          // }}
                        >
                          <Input
                            style={{
                              width: "80%",
                            }}
                            className={`page-check-sheet-input ${value36.length !== 0 ? 'style-error-input' : ''}`}
                            data-input-body-center={true}
                            readOnly={!checkClickEdit}
                          ></Input>
                        </Form.Item>
                      </Tooltip>
                    </Col>
                    <Col
                      span={10}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <span
                        style={{
                          marginLeft: 5,
                        }}
                      >
                        φ
                      </span>
                    </Col>
                    <Col
                      span={4}
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Tooltip
                        placement="top"
                        title={
                          valueCheck_no36.length !== 0
                            ? valueCheck_no36[0]["check_no36"]
                            : ""
                        }
                      >
                        <Form.Item
                          name="check_no36"
                          valuePropName="checked"
                          style={{
                            height: "28.84px",
                            marginLeft: "auto",
                            marginBottom: 0,
                            marginRight: 5,
                          }}
                           className={
                            valueCheck_no36.length !== 0
                              ? "style-error-checkbox"
                              : ""
                          }
                        >
                          <Checkbox
                            disabled={!checkClickEdit}
                            // className={
                            //   valueCheck_no36.length !== 0
                            //     ? "check-box-checksheet"
                            //     : ""
                            // }
                          ></Checkbox>
                        </Form.Item>
                      </Tooltip>
                    </Col>
                  </Row>
                </Col>
                <Col span={12} style={{ textAlign: "center" }}>
                  <Row>
                    <Col span={10}>
                      <Form.Item name="37" className="checksheet-form-item">
                        <Input
                          style={{
                            width: "80%",
                          }}
                          className="page-check-sheet-input"
                          data-input-body-center={true}
                          readOnly={!checkClickEdit}
                        ></Input>
                      </Form.Item>
                    </Col>
                    <Col
                      span={10}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <span
                        style={{
                          marginLeft: 5,
                        }}
                      >
                        種
                      </span>
                    </Col>
                    <Col
                      span={4}
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Tooltip
                        placement="top"
                        title={
                          valueCheck_no37.length !== 0
                            ? valueCheck_no37[0]["check_no37"]
                            : ""
                        }
                      >
                        <Form.Item
                          name="check_no37"
                          valuePropName="checked"
                          style={{
                            height: "28.84px",
                            marginLeft: "auto",
                            marginBottom: 0,
                            marginRight: 5,
                          }}
                          className={
                            valueCheck_no37.length !== 0
                              ? "style-error-checkbox"
                              : ""
                          }
                        >
                          <Checkbox
                            disabled={!checkClickEdit}
                            // className={
                            //   valueCheck_no37.length !== 0
                            //     ? "check-box-checksheet"
                            //     : ""
                            // }
                          ></Checkbox>
                        </Form.Item>
                      </Tooltip>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row style={{ borderBottom: "1px solid black" }}>
                <Col
                  span={5}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    borderRight: "1px solid black",
                    justifyContent: "center",
                  }}
                >
                  <span style={{ marginLeft: 5 }}>型式</span>
                </Col>
                <Col span={17} style={{ textAlign: "center" }}>
                  <Form.Item name="38" className="checksheet-form-item">
                    <Input
                      style={{
                        width: "100%",
                      }}
                      className="page-check-sheet-input"
                      data-input-body-center={true}
                      readOnly={!checkClickEdit}
                    ></Input>
                  </Form.Item>
                </Col>
                <Col
                  span={2}
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Tooltip
                    placement="top"
                    title={
                      valueCheck_no38.length !== 0
                        ? valueCheck_no38[0]["check_no38"]
                        : ""
                    }
                  >
                    <Form.Item
                      name="check_no38"
                      valuePropName="checked"
                      style={{
                        height: "28.84px",
                        marginLeft: "auto",
                        marginBottom: 0,
                        marginRight: 5,
                      }}
                      className={
                            valueCheck_no38.length !== 0
                              ? "style-error-checkbox"
                              : ""
                          }
                    >
                      <Checkbox
                        disabled={!checkClickEdit}
                        // className={
                        //   valueCheck_no38.length !== 0
                        //     ? "check-box-checksheet"
                        //     : ""
                        // }
                      ></Checkbox>
                    </Form.Item>
                  </Tooltip>
                </Col>
              </Row>
              <Row style={{ borderBottom: "1px solid black" }}>
                <Col
                  span={24}
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Row
                    style={{
                      width: "100%",
                      height: 28.84,
                    }}
                  >
                    <Col span={22}>
                      <Tooltip
                        placement="top"
                        title={value39.length !== 0 ? value39[0]["39"] : ""}
                      >
                        <Form.Item
                          name="39"
                          className="checksheet-form-item"
                          style={{
                            // outline:
                            //   value39.length !== 0 ? "1px solid red" : "none",
                            height: "100%",
                            alignItems: "center",
                            display: "flex",
                          }}
                        >
                          <Radio.Group
                            size="small"
                            className={`custom-radio-button ${value39.length !== 0 ? 'style-error-radio-btn' : ''}`}
                          >
                            {options39.map((option, index) => (
                              <React.Fragment key={option.value}>
                                <Radio.Button
                                  value={option.value}
                                  disabled={!checkClickEdit}
                                >
                                  {option.label}
                                </Radio.Button>
                                {index < options39.length - 1 && <span>/</span>}
                              </React.Fragment>
                            ))}
                          </Radio.Group>
                        </Form.Item>
                      </Tooltip>
                    </Col>
                    <Col
                      span={2}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <span
                        style={{
                          marginLeft: 5,
                        }}
                      >
                        V
                      </span>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row style={{ borderBottom: "1px solid black" }}>
                <Col
                  span={24}
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Row style={{ width: "100%", height: 28.84 }}>
                    <Col span={22}>
                      <Tooltip
                        placement="top"
                        title={value40.length !== 0 ? value40[0]["40"] : ""}
                      >
                        <Form.Item
                          name="40"
                          className="checksheet-form-item"
                          style={{
                            // outline:
                            //   value40.length !== 0 ? "1px solid red" : "none",
                            height: "100%",
                            alignItems: "center",
                            display: "flex",
                          }}
                        >
                          <Radio.Group size="small"
                            className={`${value40.length !== 0 ? 'style-error-radio-btn' : ''}`}
                          >
                            {options40.map((option, index) => (
                              <React.Fragment key={option.value}>
                                <Radio.Button
                                  value={option.value}
                                  disabled={!checkClickEdit}
                                >
                                  {option.label}
                                </Radio.Button>
                                {index < options40.length - 1 && <span>/</span>}
                              </React.Fragment>
                            ))}
                          </Radio.Group>
                        </Form.Item>
                      </Tooltip>
                    </Col>
                    <Col
                      span={2}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <span
                        style={{
                          marginLeft: 5,
                        }}
                      >
                        Hz
                      </span>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row style={{ borderBottom: "1px solid black" }}>
                <Col
                  span={12}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    borderRight: "1px solid black",
                  }}
                >
                  <Row>
                    <Col span={12}>
                      <Tooltip
                        placement="top"
                        title={value41.length !== 0 ? value41[0]["41"] : ""}
                      >
                        <Form.Item
                          name="41"
                          className="checksheet-form-item"
                          // style={{
                          //   outline:
                          //     value41.length !== 0 ? "1px solid red" : "none",
                          // }}
                        >
                          <Input
                            style={{
                              width: "80%",
                            }}
                            className={`page-check-sheet-input ${value41.length !== 0 ? 'style-error-input' : ''}`}
                            data-input-body-center={true}
                            readOnly={!checkClickEdit}
                          ></Input>
                        </Form.Item>
                      </Tooltip>
                    </Col>
                    <Col
                      span={12}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <span
                        style={{
                          marginLeft: 5,
                        }}
                      >
                        A
                      </span>
                    </Col>
                  </Row>
                </Col>
                <Col span={12} style={{ textAlign: "center" }}>
                  <Row>
                    <Col span={12}>
                      <Tooltip
                        placement="top"
                        title={value42.length !== 0 ? value42[0]["42"] : ""}
                      >
                        <Form.Item
                          name="42"
                          className="checksheet-form-item"
                          // style={{
                          //   outline:
                          //     value42.length !== 0 ? "1px solid red" : "none",
                          // }}
                        >
                          <Input
                            style={{
                              width: "80%",
                            }}
                            className={`page-check-sheet-input ${value42.length !== 0 ? 'style-error-input' : ''}`}
                            data-input-body-center={true}
                            readOnly={!checkClickEdit}
                          ></Input>
                        </Form.Item>
                      </Tooltip>
                    </Col>
                    <Col
                      span={12}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <span
                        style={{
                          marginLeft: 5,
                        }}
                      >
                        min<sup>-1</sup>
                      </span>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row style={{ borderBottom: "2px solid black" }}>
                <Col
                  span={7}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    borderRight: "1px solid black",
                  }}
                >
                  <span style={{ marginLeft: 5 }}>SERIAL.No.</span>
                </Col>
                <Col span={17} style={{ textAlign: "center" }}>
                  <Tooltip
                    placement="top"
                    title={value43.length !== 0 ? value43[0]["43"] : ""}
                  >
                    <Form.Item
                      name="43"
                      className="checksheet-form-item"
                      // style={{
                      //   outline:
                      //     value43.length !== 0 ? "1px solid red" : "none",
                      // }}
                    >
                      <Input
                        style={{
                          width: "100%",
                        }}
                        className={`page-check-sheet-input ${value43.length !== 0 ? 'style-error-input' : ''}`}
                        data-input-body-center={true}
                        readOnly={!checkClickEdit}
                      ></Input>
                    </Form.Item>
                  </Tooltip>
                </Col>
              </Row>
              <Row style={{ borderBottom: "1px solid black", height: 28.84 }}>
                <Col
                  span={18}
                  style={{
                    background: "#ced4da",
                    borderRight: "1px solid black",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  付属品
                </Col>
                <Col
                  span={6}
                  style={{
                    background: "#ced4da",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  数
                </Col>
              </Row>
              <Row style={{ borderBottom: "1px solid black" }}>
                <Col
                  span={16}
                  style={{
                    textAlign: "center",
                  }}
                >
                  <Form.Item name="BP65" className="checksheet-form-item">
                    <Input
                      style={{
                        width: "100%",
                      }}
                      className="page-check-sheet-input"
                      data-input-body-center={true}
                      readOnly={!checkClickEdit}
                    ></Input>
                  </Form.Item>
                </Col>
                <Col
                  span={2}
                  style={{
                    borderRight: "1px solid black",
                    height: "28.84px",
                  }}
                        className="align-col-checkbox"
                >
                  <Tooltip
                    placement="top"
                    title={
                      valueCheck_no44_bp65.length !== 0
                        ? valueCheck_no44_bp65[0]["check_no44_bp65"]
                        : ""
                    }
                  >
                    <Form.Item
                      name="check_no44_bp65"
                      valuePropName="checked"
                      style={{
                        height: "100%",
                      }}
                      className={
                            valueCheck_no44_bp65.length !== 0
                              ? "style-error-checkbox"
                              : ""
                          }
                    >
                      <Checkbox
                        disabled={!checkClickEdit}
                        // className={
                        //   valueCheck_no44_bp65.length !== 0
                        //     ? "check-box-checksheet"
                        //     : ""
                        // }
                      ></Checkbox>
                    </Form.Item>
                  </Tooltip>
                </Col>
                <Col
                  span={6}
                  style={{
                    textAlign: "center",
                  }}
                >
                  <Form.Item className="checksheet-form-item">
                    <Input
                      style={{
                        width: "100%",
                      }}
                      className="page-check-sheet-input"
                      data-input-body-center={true}
                      readOnly={!checkClickEdit}
                    ></Input>
                  </Form.Item>
                </Col>
              </Row>
              <Row style={{ borderBottom: "1px solid black" }}>
                <Col
                  span={16}
                  style={{
                    textAlign: "center",
                  }}
                >
                  <Form.Item name="BP68" className="checksheet-form-item">
                    <Input
                      style={{
                        width: "100%",
                      }}
                      className="page-check-sheet-input"
                      data-input-body-center={true}
                      readOnly={!checkClickEdit}
                    ></Input>
                  </Form.Item>
                </Col>
                <Col
                  span={2}
                  style={{
                    borderRight: "1px solid black",
                    height: "28.84px",
                  }}
                        className="align-col-checkbox"
                >
                  <Tooltip
                    placement="top"
                    title={
                      valueCheck_no44_bp68.length !== 0
                        ? valueCheck_no44_bp68[0]["check_no44_bp68"]
                        : ""
                    }
                  >
                    <Form.Item
                      name="check_no44_bp68"
                      valuePropName="checked"
                      style={{
                        height: "100%",
                      }}
                      className={
                            valueCheck_no44_bp68.length !== 0
                              ? "style-error-checkbox"
                              : ""
                          }
                    >
                      <Checkbox
                        disabled={!checkClickEdit}
                        // className={
                        //   valueCheck_no44_bp68.length !== 0
                        //     ? "check-box-checksheet"
                        //     : ""
                        // }
                      ></Checkbox>
                    </Form.Item>
                  </Tooltip>
                </Col>
                <Col
                  span={6}
                  style={{
                    textAlign: "center",
                  }}
                >
                  <Form.Item className="checksheet-form-item">
                    <Input
                      style={{
                        width: "100%",
                      }}
                      className="page-check-sheet-input"
                      data-input-body-center={true}
                      readOnly={!checkClickEdit}
                    ></Input>
                  </Form.Item>
                </Col>
              </Row>
              <Row style={{ borderBottom: "1px solid black" }}>
                <Col
                  span={16}
                  style={{
                    textAlign: "center",
                  }}
                >
                  <Form.Item name="BP71" className="checksheet-form-item">
                    <Input
                      style={{
                        width: "100%",
                      }}
                      className="page-check-sheet-input"
                      data-input-body-center={true}
                      readOnly={!checkClickEdit}
                    ></Input>
                  </Form.Item>
                </Col>
                <Col
                  span={2}
                  style={{
                    borderRight: "1px solid black",
                    height: "28.84px",
                  }}
                        className="align-col-checkbox"
                >
                  <Tooltip
                    placement="top"
                    title={
                      valueCheck_no44_bp71.length !== 0
                        ? valueCheck_no44_bp71[0]["check_no44_bp71"]
                        : ""
                    }
                  >
                    <Form.Item
                      name="check_no44_bp71"
                      valuePropName="checked"
                      style={{
                        height: "100%",
                      }}
                      className={
                            valueCheck_no44_bp71.length !== 0
                              ? "style-error-checkbox"
                              : ""
                          }
                    >
                      <Checkbox
                        disabled={!checkClickEdit}
                        // className={
                        //   valueCheck_no44_bp71.length !== 0
                        //     ? "check-box-checksheet"
                        //     : ""
                        // }
                      ></Checkbox>
                    </Form.Item>
                  </Tooltip>
                </Col>
                <Col
                  span={6}
                  style={{
                    textAlign: "center",
                  }}
                >
                  <Form.Item className="checksheet-form-item">
                    <Input
                      style={{
                        width: "100%",
                      }}
                      className="page-check-sheet-input"
                      data-input-body-center={true}
                      readOnly={!checkClickEdit}
                    ></Input>
                  </Form.Item>
                </Col>
              </Row>
              <Row style={{ borderBottom: "1px solid black" }}>
                <Col
                  span={16}
                  style={{
                    textAlign: "center",
                  }}
                >
                  <Form.Item name="BP74" className="checksheet-form-item">
                    <Input
                      style={{
                        width: "100%",
                      }}
                      className="page-check-sheet-input"
                      data-input-body-center={true}
                      readOnly={!checkClickEdit}
                    ></Input>
                  </Form.Item>
                </Col>
                <Col
                  span={2}
                  style={{
                    borderRight: "1px solid black",
                    height: "28.84px",
                  }}
                        className="align-col-checkbox"
                >
                  <Tooltip
                    placement="top"
                    title={
                      valueCheck_no44_bp74.length !== 0
                        ? valueCheck_no44_bp74[0]["check_no44_bp74"]
                        : ""
                    }
                  >
                    <Form.Item
                      name="check_no44_bp74"
                      valuePropName="checked"
                      style={{
                        height: "100%",
                      }}
                      className={
                            valueCheck_no44_bp74.length !== 0
                              ? "style-error-checkbox"
                              : ""
                          }
                    >
                      <Checkbox
                        disabled={!checkClickEdit}
                        // className={
                        //   valueCheck_no44_bp74.length !== 0
                        //     ? "check-box-checksheet"
                        //     : ""
                        // }
                      ></Checkbox>
                    </Form.Item>
                  </Tooltip>
                </Col>
                <Col
                  span={6}
                  style={{
                    textAlign: "center",
                  }}
                >
                  <Form.Item className="checksheet-form-item">
                    <Input
                      style={{
                        width: "100%",
                      }}
                      className="page-check-sheet-input"
                      data-input-body-center={true}
                      readOnly={!checkClickEdit}
                    ></Input>
                  </Form.Item>
                </Col>
              </Row>
              <Row style={{ borderBottom: "1px solid black" }}>
                <Col
                  span={16}
                  style={{
                    textAlign: "center",
                  }}
                >
                  <Form.Item name="BP77" className="checksheet-form-item">
                    <Input
                      style={{
                        width: "100%",
                      }}
                      className="page-check-sheet-input"
                      data-input-body-center={true}
                      readOnly={!checkClickEdit}
                    ></Input>
                  </Form.Item>
                </Col>
                <Col
                  span={2}
                  style={{
                    borderRight: "1px solid black",
                    height: "28.84px",
                  }}
                        className="align-col-checkbox"
                >
                  <Tooltip
                    placement="top"
                    title={
                      valueCheck_no44_bp77.length !== 0
                        ? valueCheck_no44_bp77[0]["check_no44_bp77"]
                        : ""
                    }
                  >
                    <Form.Item
                      name="check_no44_bp77"
                      valuePropName="checked"
                      style={{
                        height: "100%",
                      }}
                       className={
                            valueCheck_no44_bp77.length !== 0
                              ? "style-error-checkbox"
                              : ""
                          }
                    >
                      <Checkbox
                        disabled={!checkClickEdit}
                        // className={
                        //   valueCheck_no44_bp77.length !== 0
                        //     ? "check-box-checksheet"
                        //     : ""
                        // }
                      ></Checkbox>
                    </Form.Item>
                  </Tooltip>
                </Col>
                <Col
                  span={6}
                  style={{
                    textAlign: "center",
                  }}
                >
                  <Form.Item className="checksheet-form-item">
                    <Input
                      style={{
                        width: "100%",
                      }}
                      className="page-check-sheet-input"
                      data-input-body-center={true}
                      readOnly={!checkClickEdit}
                    ></Input>
                  </Form.Item>
                </Col>
              </Row>
              <Row style={{ borderBottom: "1px solid black" }}>
                <Col
                  span={16}
                  style={{
                    textAlign: "center",
                  }}
                >
                  <Form.Item name="BP80" className="checksheet-form-item">
                    <Input
                      style={{
                        width: "100%",
                      }}
                      className="page-check-sheet-input"
                      data-input-body-center={true}
                      readOnly={!checkClickEdit}
                    ></Input>
                  </Form.Item>
                </Col>
                <Col
                  span={2}
                  style={{
                    borderRight: "1px solid black",
                    height: "28.84px",
                  }}
                        className="align-col-checkbox"
                >
                  <Tooltip
                    placement="top"
                    title={
                      valueCheck_no44_bp80.length !== 0
                        ? valueCheck_no44_bp80[0]["check_no44_bp80"]
                        : ""
                    }
                  >
                    <Form.Item
                      name="check_no44_bp80"
                      valuePropName="checked"
                      style={{
                        height: "100%",
                      }}
                      className={
                            valueCheck_no44_bp80.length !== 0
                              ? "style-error-checkbox"
                              : ""
                          }
                    >
                      <Checkbox
                        disabled={!checkClickEdit}
                        // className={
                        //   valueCheck_no44_bp80.length !== 0
                        //     ? "check-box-checksheet"
                        //     : ""
                        // }
                      ></Checkbox>
                    </Form.Item>
                  </Tooltip>
                </Col>
                <Col
                  span={6}
                  style={{
                    textAlign: "center",
                  }}
                >
                  <Form.Item className="checksheet-form-item">
                    <Input
                      style={{
                        width: "100%",
                      }}
                      className="page-check-sheet-input"
                      data-input-body-center={true}
                      readOnly={!checkClickEdit}
                    ></Input>
                  </Form.Item>
                </Col>
              </Row>
              <Row style={{ borderBottom: "1px solid black" }}>
                <Col
                  span={16}
                  style={{
                    textAlign: "center",
                  }}
                >
                  <Form.Item name="BP83" className="checksheet-form-item">
                    <Input
                      style={{
                        width: "100%",
                      }}
                      className="page-check-sheet-input"
                      data-input-body-center={true}
                      readOnly={!checkClickEdit}
                    ></Input>
                  </Form.Item>
                </Col>
                <Col
                  span={2}
                  style={{
                    borderRight: "1px solid black",
                    height: "28.84px",
                  }}
                        className="align-col-checkbox"
                >
                  <Tooltip
                    placement="top"
                    title={
                      valueCheck_no44_bp83.length !== 0
                        ? valueCheck_no44_bp83[0]["check_no44_bp83"]
                        : ""
                    }
                  >
                    <Form.Item
                      name="check_no44_bp83"
                      valuePropName="checked"
                      style={{
                        height: "100%",
                      }}
                      className={
                            valueCheck_no44_bp83.length !== 0
                              ? "style-error-checkbox"
                              : ""
                          }
                    >
                      <Checkbox
                        disabled={!checkClickEdit}
                        // className={
                        //   valueCheck_no44_bp83.length !== 0
                        //     ? "check-box-checksheet"
                        //     : ""
                        // }
                      ></Checkbox>
                    </Form.Item>
                  </Tooltip>
                </Col>
                <Col
                  span={6}
                  style={{
                    textAlign: "center",
                  }}
                >
                  <Form.Item className="checksheet-form-item">
                    <Input
                      style={{
                        width: "100%",
                      }}
                      className="page-check-sheet-input"
                      data-input-body-center={true}
                      readOnly={!checkClickEdit}
                    ></Input>
                  </Form.Item>
                </Col>
              </Row>
              <Row style={{ borderBottom: "1px solid black" }}>
                <Col
                  span={16}
                  style={{
                    textAlign: "center",
                  }}
                >
                  <Form.Item name="BP86" className="checksheet-form-item">
                    <Input
                      style={{
                        width: "100%",
                      }}
                      className="page-check-sheet-input"
                      data-input-body-center={true}
                      readOnly={!checkClickEdit}
                    ></Input>
                  </Form.Item>
                </Col>
                <Col
                  span={2}
                  style={{
                    borderRight: "1px solid black",
                    height: "28.84px",
                  }}
                        className="align-col-checkbox"
                >
                  <Tooltip
                    placement="top"
                    title={
                      valueCheck_no44_bp86.length !== 0
                        ? valueCheck_no44_bp86[0]["check_no44_bp86"]
                        : ""
                    }
                  >
                    <Form.Item
                      name="check_no44_bp86"
                      valuePropName="checked"
                      style={{
                        height: "100%",
                      }}
                      className={
                            valueCheck_no44_bp86.length !== 0
                              ? "style-error-checkbox"
                              : ""
                          }
                    >
                      <Checkbox
                        disabled={!checkClickEdit}
                        // className={
                        //   valueCheck_no44_bp86.length !== 0
                        //     ? "check-box-checksheet"
                        //     : ""
                        // }
                      ></Checkbox>
                    </Form.Item>
                  </Tooltip>
                </Col>
                <Col
                  span={6}
                  style={{
                    textAlign: "center",
                  }}
                >
                  <Form.Item className="checksheet-form-item">
                    <Input
                      style={{
                        width: "100%",
                      }}
                      className="page-check-sheet-input"
                      data-input-body-center={true}
                      readOnly={!checkClickEdit}
                    ></Input>
                  </Form.Item>
                </Col>
              </Row>
              <Row style={{ borderBottom: "1px solid black" }}>
                <Col
                  span={16}
                  style={{
                    textAlign: "center",
                  }}
                >
                  <Form.Item name="BP89" className="checksheet-form-item">
                    <Input
                      style={{
                        width: "100%",
                      }}
                      className="page-check-sheet-input"
                      data-input-body-center={true}
                      readOnly={!checkClickEdit}
                    ></Input>
                  </Form.Item>
                </Col>
                <Col
                  span={2}
                  style={{
                    borderRight: "1px solid black",
                    height: "28.84px",
                  }}
                        className="align-col-checkbox"
                >
                  <Tooltip
                    placement="top"
                    title={
                      valueCheck_no44_bp89.length !== 0
                        ? valueCheck_no44_bp89[0]["check_no44_bp89"]
                        : ""
                    }
                  >
                    <Form.Item
                      name="check_no44_bp89"
                      valuePropName="checked"
                      style={{
                        height: "100%",
                      }}
                      className={
                            valueCheck_no44_bp89.length !== 0
                              ? "style-error-checkbox"
                              : ""
                          }
                    >
                      <Checkbox
                        disabled={!checkClickEdit}
                        // className={
                        //   valueCheck_no44_bp89.length !== 0
                        //     ? "check-box-checksheet"
                        //     : ""
                        // }
                      ></Checkbox>
                    </Form.Item>
                  </Tooltip>
                </Col>
                <Col
                  span={6}
                  style={{
                    textAlign: "center",
                  }}
                >
                  <Form.Item className="checksheet-form-item">
                    <Input
                      style={{
                        width: "100%",
                      }}
                      className="page-check-sheet-input"
                      data-input-body-center={true}
                      readOnly={!checkClickEdit}
                    ></Input>
                  </Form.Item>
                </Col>
              </Row>
              <Row style={{ borderBottom: "2px solid black" }}>
                <Col
                  span={16}
                  style={{
                    textAlign: "center",
                  }}
                >
                  <Form.Item name="BP92" className="checksheet-form-item">
                    <Input
                      style={{
                        width: "100%",
                      }}
                      className="page-check-sheet-input"
                      data-input-body-center={true}
                      readOnly={!checkClickEdit}
                    ></Input>
                  </Form.Item>
                </Col>
                <Col
                  span={2}
                  style={{
                    borderRight: "1px solid black",
                    height: "28.84px",
                  }}
                        className="align-col-checkbox"
                >
                  <Tooltip
                    placement="top"
                    title={
                      valueCheck_no44_bp92.length !== 0
                        ? valueCheck_no44_bp92[0]["check_no44_bp92"]
                        : ""
                    }
                  >
                    <Form.Item
                      name="check_no44_bp92"
                      valuePropName="checked"
                      style={{
                        height: "100%",
                      }}
                      className={
                            valueCheck_no44_bp92.length !== 0
                              ? "style-error-checkbox"
                              : ""
                          }
                    >
                      <Checkbox
                        disabled={!checkClickEdit}
                        // className={
                        //   valueCheck_no44_bp92.length !== 0
                        //     ? "check-box-checksheet"
                        //     : ""
                        // }
                      ></Checkbox>
                    </Form.Item>
                  </Tooltip>
                </Col>
                <Col
                  span={6}
                  style={{
                    textAlign: "center",
                  }}
                >
                  <Form.Item className="checksheet-form-item">
                    <Input
                      style={{
                        width: "100%",
                      }}
                      className="page-check-sheet-input"
                      data-input-body-center={true}
                      readOnly={!checkClickEdit}
                    ></Input>
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className="body-3">
            <Col span={16} className="body-3-area-left">
              <Row>
                <Col
                  span={3}
                  style={{
                    borderRight: "1px solid black",
                  }}
                >
                  <Row>
                    <Col
                      span={24}
                      style={{
                        background: "#ced4da",
                        textAlign: "center",
                        borderBottom: "1px solid black",
                      }}
                    >
                      Hz
                    </Col>
                    <Col
                      span={24}
                      style={{
                        textAlign: "center",
                        borderBottom: "1px solid black",
                      }}
                    >
                      <Tooltip
                        placement="top"
                        title={value49.length !== 0 ? value49[0]["49"] : ""}
                      >
                        <Form.Item
                          name="49"
                          className="checksheet-form-item"
                          // style={{
                          //   outline:
                          //     value49.length !== 0 ? "1px solid red" : "none",
                          // }}
                        >
                          <Input
                            style={{
                              width: "100%",
                            }}
                            className={`page-check-sheet-input ${value49.length !== 0 ? 'style-error-input' : ''}`}
                            data-input-body-center={true}
                            readOnly={!checkClickEdit}
                          ></Input>
                        </Form.Item>
                      </Tooltip>
                    </Col>
                    <Col
                      span={24}
                      style={{
                        textAlign: "center",
                        borderBottom: "1px solid black",
                      }}
                    >
                      <Form.Item className="checksheet-form-item">
                        <Input
                          style={{
                            width: "100%",
                          }}
                          className="page-check-sheet-input"
                          data-input-body-center={true}
                          readOnly={!checkClickEdit}
                        ></Input>
                      </Form.Item>
                    </Col>
                    <Col
                      span={24}
                      style={{
                        textAlign: "center",
                        borderBottom: "2px solid black",
                      }}
                    >
                      <Form.Item className="checksheet-form-item">
                        <Input
                          style={{
                            width: "100%",
                          }}
                          className="page-check-sheet-input"
                          data-input-body-center={true}
                          readOnly={!checkClickEdit}
                        ></Input>
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                <Col
                  span={3}
                  style={{
                    borderRight: "1px solid black",
                  }}
                >
                  <Row>
                    <Col
                      span={24}
                      style={{
                        background: "#ced4da",
                        textAlign: "center",
                        borderBottom: "1px solid black",
                      }}
                    >
                      Mpa
                    </Col>
                    <Col
                      span={24}
                      style={{
                        textAlign: "center",
                        borderBottom: "1px solid black",
                      }}
                    >
                      <Tooltip
                        placement="top"
                        title={value50.length !== 0 ? value50[0]["50"] : ""}
                      >
                        <Form.Item
                          name="50"
                          className="checksheet-form-item"
                          // style={{
                          //   outline:
                          //     value50.length !== 0 ? "1px solid red" : "none",
                          // }}
                        >
                          <Input
                            style={{
                              width: "100%",
                            }}
                            className={`page-check-sheet-input ${value50.length !== 0 ? 'style-error-input' : ''}`}
                            data-input-body-center={true}
                            readOnly={!checkClickEdit}
                          ></Input>
                        </Form.Item>
                      </Tooltip>
                    </Col>
                    <Col
                      span={24}
                      style={{
                        textAlign: "center",
                        borderBottom: "1px solid black",
                      }}
                    >
                      <Form.Item className="checksheet-form-item">
                        <Input
                          style={{
                            width: "100%",
                          }}
                          className="page-check-sheet-input"
                          data-input-body-center={true}
                          readOnly={!checkClickEdit}
                        ></Input>
                      </Form.Item>
                    </Col>
                    <Col
                      span={24}
                      style={{
                        textAlign: "center",
                        borderBottom: "2px solid black",
                      }}
                    >
                      <Form.Item className="checksheet-form-item">
                        <Input
                          style={{
                            width: "100%",
                          }}
                          className="page-check-sheet-input"
                          data-input-body-center={true}
                          readOnly={!checkClickEdit}
                        ></Input>
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                <Col span={3}>
                  <Row
                    style={{
                      borderRight: "1px solid black",
                    }}
                  >
                    <Col
                      span={24}
                      style={{
                        textAlign: "center",
                        borderBottom: "1px solid black",
                      }}
                    >
                      25%
                    </Col>
                    <Col
                      span={24}
                      style={{
                        textAlign: "center",
                        borderBottom: "1px solid black",
                      }}
                    >
                      <Form.Item name="51.1" className="checksheet-form-item">
                        <Input
                          style={{
                            width: "100%",
                          }}
                          className="page-check-sheet-input"
                          data-input-body-center={true}
                          readOnly={!checkClickEdit}
                        ></Input>
                      </Form.Item>
                    </Col>
                    <Col
                      span={24}
                      style={{
                        textAlign: "center",
                        borderBottom: "1px solid black",
                      }}
                    >
                      <Form.Item className="checksheet-form-item">
                        <Input
                          style={{
                            width: "100%",
                          }}
                          className="page-check-sheet-input"
                          data-input-body-center={true}
                          readOnly={!checkClickEdit}
                        ></Input>
                      </Form.Item>
                    </Col>
                    <Col
                      span={24}
                      style={{
                        textAlign: "center",
                        borderBottom: "1px solid black",
                      }}
                    >
                      <Form.Item className="checksheet-form-item">
                        <Input
                          style={{
                            width: "100%",
                          }}
                          className="page-check-sheet-input"
                          data-input-body-center={true}
                          readOnly={!checkClickEdit}
                        ></Input>
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                <Col span={3}>
                  <Row
                    style={{
                      borderRight: "1px solid black",
                    }}
                  >
                    <Col
                      span={24}
                      style={{
                        textAlign: "center",
                        borderBottom: "1px solid black",
                      }}
                    >
                      50%
                    </Col>
                    <Col
                      span={24}
                      style={{
                        textAlign: "center",
                        borderBottom: "1px solid black",
                      }}
                    >
                      <Form.Item name="51.2" className="checksheet-form-item">
                        <Input
                          style={{
                            width: "100%",
                          }}
                          className="page-check-sheet-input"
                          data-input-body-center={true}
                          readOnly={!checkClickEdit}
                        ></Input>
                      </Form.Item>
                    </Col>
                    <Col
                      span={24}
                      style={{
                        textAlign: "center",
                        borderBottom: "1px solid black",
                      }}
                    >
                      <Form.Item className="checksheet-form-item">
                        <Input
                          style={{
                            width: "100%",
                          }}
                          className="page-check-sheet-input"
                          data-input-body-center={true}
                          readOnly={!checkClickEdit}
                        ></Input>
                      </Form.Item>
                    </Col>
                    <Col
                      span={24}
                      style={{
                        textAlign: "center",
                        borderBottom: "1px solid black",
                      }}
                    >
                      <Form.Item className="checksheet-form-item">
                        <Input
                          style={{
                            width: "100%",
                          }}
                          className="page-check-sheet-input"
                          data-input-body-center={true}
                          readOnly={!checkClickEdit}
                        ></Input>
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                <Col span={4}>
                  <Row
                    style={{
                      borderRight: "1px solid black",
                    }}
                  >
                    <Col
                      span={24}
                      style={{
                        textAlign: "center",
                        borderBottom: "1px solid black",
                      }}
                    >
                      75%
                    </Col>
                    <Col
                      span={24}
                      style={{
                        textAlign: "center",
                        borderBottom: "1px solid black",
                      }}
                    >
                      <Form.Item name="51.3" className="checksheet-form-item">
                        <Input
                          style={{
                            width: "100%",
                          }}
                          className="page-check-sheet-input"
                          data-input-body-center={true}
                          readOnly={!checkClickEdit}
                        ></Input>
                      </Form.Item>
                    </Col>
                    <Col
                      span={24}
                      style={{
                        textAlign: "center",
                        borderBottom: "1px solid black",
                      }}
                    >
                      <Form.Item className="checksheet-form-item">
                        <Input
                          style={{
                            width: "100%",
                          }}
                          className="page-check-sheet-input"
                          data-input-body-center={true}
                          readOnly={!checkClickEdit}
                        ></Input>
                      </Form.Item>
                    </Col>
                    <Col
                      span={24}
                      style={{
                        textAlign: "center",
                        borderBottom: "1px solid black",
                      }}
                    >
                      <Form.Item className="checksheet-form-item">
                        <Input
                          style={{
                            width: "100%",
                          }}
                          className="page-check-sheet-input"
                          data-input-body-center={true}
                          readOnly={!checkClickEdit}
                        ></Input>
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                <Col span={4}>
                  <Row
                    style={{
                      borderRight: "1px solid black",
                    }}
                  >
                    <Col
                      span={24}
                      style={{
                        textAlign: "center",
                        borderBottom: "1px solid black",
                      }}
                    >
                      100%
                    </Col>
                    <Col
                      span={24}
                      style={{
                        textAlign: "center",
                        borderBottom: "1px solid black",
                      }}
                    >
                      <Form.Item name="51.4" className="checksheet-form-item">
                        <Input
                          style={{
                            width: "100%",
                          }}
                          className="page-check-sheet-input"
                          data-input-body-center={true}
                          readOnly={!checkClickEdit}
                        ></Input>
                      </Form.Item>
                    </Col>
                    <Col
                      span={24}
                      style={{
                        textAlign: "center",
                        borderBottom: "1px solid black",
                      }}
                    >
                      <Form.Item className="checksheet-form-item">
                        <Input
                          style={{
                            width: "100%",
                          }}
                          className="page-check-sheet-input"
                          data-input-body-center={true}
                          readOnly={!checkClickEdit}
                        ></Input>
                      </Form.Item>
                    </Col>
                    <Col
                      span={24}
                      style={{
                        textAlign: "center",
                        borderBottom: "1px solid black",
                      }}
                    >
                      <Form.Item className="checksheet-form-item">
                        <Input
                          style={{
                            width: "100%",
                          }}
                          className="page-check-sheet-input"
                          data-input-body-center={true}
                          readOnly={!checkClickEdit}
                        ></Input>
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                <Col span={4}>
                  <Row>
                    <Col
                      span={24}
                      style={{
                        textAlign: "center",
                        borderBottom: "1px solid black",
                      }}
                    >
                      電流値
                    </Col>
                    <Col
                      span={24}
                      style={{
                        textAlign: "center",
                        borderBottom: "1px solid black",
                      }}
                    >
                      <Tooltip
                        placement="top"
                        title={value52.length !== 0 ? value52[0]["52"] : ""}
                      >
                        <Form.Item
                          name="52"
                          className="checksheet-form-item"
                          // style={{
                          //   outline:
                          //     value52.length !== 0 ? "1px solid red" : "none",
                          // }}
                        >
                          <Input
                            style={{
                              width: "100%",
                            }}
                            className={`page-check-sheet-input ${value52.length !== 0 ? 'style-error-input' : ''}`}
                            data-input-body-center={true}
                            readOnly={!checkClickEdit}
                          ></Input>
                        </Form.Item>
                      </Tooltip>
                    </Col>
                    <Col
                      span={24}
                      style={{
                        textAlign: "center",
                        borderBottom: "1px solid black",
                      }}
                    >
                      <Form.Item className="checksheet-form-item">
                        <Input
                          style={{
                            width: "100%",
                          }}
                          className="page-check-sheet-input"
                          data-input-body-center={true}
                          readOnly={!checkClickEdit}
                        ></Input>
                      </Form.Item>
                    </Col>
                    <Col
                      span={24}
                      style={{
                        textAlign: "center",
                        borderBottom: "1px solid black",
                      }}
                    >
                      <Form.Item className="checksheet-form-item">
                        <Input
                          style={{
                            width: "100%",
                          }}
                          className="page-check-sheet-input"
                          data-input-body-center={true}
                          readOnly={!checkClickEdit}
                        ></Input>
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col
                  span={6}
                  style={{
                    background: "#ced4da",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRight: "2px solid black",
                  }}
                >
                  特注要素・備考
                </Col>
                <Col
                  span={6}
                  style={{
                    borderBottom: "2px solid black",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  最高吐出量確認
                </Col>
                <Col
                  span={8}
                  style={{
                    textAlign: "center",
                    borderBottom: "2px solid black",
                  }}
                >
                  <Tooltip
                    placement="top"
                    title={value53.length !== 0 ? value53[0]["53"] : ""}
                  >
                    <Form.Item
                      name="53"
                      className="checksheet-form-item"
                      // style={{
                      //   outline:
                      //     value53.length !== 0 ? "1px solid red" : "none",
                      // }}
                    >
                      <Input
                        style={{
                          width: "100%",
                        }}
                        className={`page-check-sheet-input ${value53.length !== 0 ? 'style-error-input' : ''}`}
                        data-input-body-center={true}
                        readOnly={!checkClickEdit}
                      ></Input>
                    </Form.Item>
                  </Tooltip>
                </Col>
                <Col
                  span={4}
                  style={{
                    borderBottom: "2px solid black",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  ｍL/min
                </Col>
              </Row>
              <Row style={{ width: "100%", height: "60%" }}>
                <Col span={23} style={{ height: "100%" }}>
                  <Form.Item
                    name="54"
                    className="checksheet-form-item item-text-area"
                    style={{ width: "100%", height: "100%" }}
                  >
                    <Input.TextArea
                      style={{
                        width: "100%",
                        height: "99%",
                      }}
                      className="page-check-sheet-input"
                      readOnly={!checkClickEdit}
                    />
                  </Form.Item>
                </Col>
                <Col
                  span={1}
                  // style={{
                  //   height: "29.84px",
                  // }}
                        className="align-col-checkbox"
                >
                  <Tooltip
                    placement="top"
                    title={
                      valueCheck_no54.length !== 0
                        ? valueCheck_no54[0]["check_no54"]
                        : ""
                    }
                  >
                    <Form.Item
                      name="check_no54"
                      valuePropName="checked"
                      style={{
                        height: "30px",
                      }}
                      className={
                            valueCheck_no54.length !== 0
                              ? "style-error-checkbox"
                              : ""
                          }
                    >
                      <Checkbox
                        disabled={!checkClickEdit}
                        // className={
                        //   valueCheck_no54.length !== 0
                        //     ? "check-box-checksheet"
                        //     : ""
                        // }
                      ></Checkbox>
                    </Form.Item>
                  </Tooltip>
                </Col>
              </Row>
            </Col>
            <Col span={8} className="body-3-area-right">
              <Row style={{ borderBottom: "1px solid black" }}>
                <Col
                  span={9}
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <span style={{ marginLeft: 5 }}>試験装置No.E</span>
                </Col>
                <Col
                  span={15}
                  style={{
                    textAlign: "center",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Tooltip
                    placement="top"
                    title={value45.length !== 0 ? value45[0]["45"] : ""}
                  >
                    <Form.Item
                      name="45"
                      className="checksheet-form-item"
                      // style={{
                      //   outline:
                      //     value45.length !== 0 ? "1px solid red" : "none",
                      // }}
                    >
                      <Radio.Group size="small"
                        className={`custom-radio-button ${value45.length !== 0 ? 'style-error-radio-btn' : ''}`}
                      >
                        {options45.map((option, index) => (
                          <React.Fragment key={option.value}>
                            <Radio.Button
                              value={option.value}
                              disabled={!checkClickEdit}
                            >
                              {option.label}
                            </Radio.Button>
                            {index < options45.length - 1 && <span>/</span>}
                          </React.Fragment>
                        ))}
                      </Radio.Group>
                    </Form.Item>
                  </Tooltip>
                </Col>
              </Row>
              <Row style={{ borderBottom: "1px solid black" }}>
                <Col
                  span={6}
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <span style={{ marginLeft: 5 }}>試験日</span>
                </Col>
                <Col span={6} style={{ textAlign: "center" }}>
                  <Tooltip
                    placement="top"
                    title={
                      valueBY98_1.length !== 0 ? valueBY98_1[0]["46"] : ""
                    }
                  >
                    <Form.Item
                      name="BY98_1"
                      className="checksheet-form-item"
                      // style={{
                      //   outline:
                      //     valueBY98_1.length !== 0 ? "1px solid red" : "none",
                      // }}
                    >
                      <Input
                        style={{
                          width: "100%",
                        }}
                        className={`page-check-sheet-input ${valueBY98_1.length !== 0 ? 'style-error-input' : ''}`}
                        data-input-body-center={true}
                        readOnly={!checkClickEdit}
                      ></Input>
                    </Form.Item>
                  </Tooltip>
                </Col>
                <Col span={3} style={{ alignItems: "center", display: "flex" }}>
                  <span style={{ marginLeft: 5 }}>月</span>
                </Col>
                <Col span={6} style={{ textAlign: "center" }}>
                  <Tooltip
                    placement="top"
                    title={
                      valueBY98_2.length !== 0 ? valueBY98_2[0]["46"] : ""
                    }
                  >
                    <Form.Item
                      name="BY98_2"
                      className="checksheet-form-item"
                      // style={{
                      //   outline:
                      //     valueBY98_2.length !== 0 ? "1px solid red" : "none",
                      // }}
                    >
                      <Input
                        style={{
                          width: "100%",
                        }}
                        className={`page-check-sheet-input ${valueBY98_2.length !== 0 ? 'style-error-input' : ''}`}
                        data-input-body-center={true}
                        readOnly={!checkClickEdit}
                      ></Input>
                    </Form.Item>
                  </Tooltip>
                </Col>
                <Col span={3} style={{ alignItems: "center", display: "flex" }}>
                  <span style={{ marginLeft: 5 }}>日</span>
                </Col>
              </Row>
              <Row style={{ borderBottom: "1px solid black" }}>
                <Col
                  span={6}
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <span style={{ marginLeft: 5 }}>試験者</span>
                </Col>
                <Col span={18} style={{ textAlign: "center" }}>
                  <Tooltip
                    placement="top"
                    title={value47.length !== 0 ? value47[0]["47"] : ""}
                  >
                    <Form.Item
                      name="47"
                      className="checksheet-form-item"
                      // style={{
                      //   outline:
                      //     value47.length !== 0 ? "1px solid red" : "none",
                      // }}
                    >
                      <Input
                        style={{
                          width: "100%",
                        }}
                        className={`page-check-sheet-input ${value47.length !== 0 ? 'style-error-input' : ''}`}
                        data-input-body-center={true}
                        readOnly={!checkClickEdit}
                      ></Input>
                    </Form.Item>
                  </Tooltip>
                </Col>
              </Row>
              <Row style={{ borderBottom: "2px solid black" }}>
                <Col
                  span={6}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <span style={{ marginLeft: 5 }}>合否判定</span>
                </Col>
                <Col
                  span={18}
                  style={{
                    textAlign: "center",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Tooltip
                    placement="top"
                    title={value48.length !== 0 ? value48[0]["48"] : ""}
                  >
                    <Form.Item
                      name="48"
                      className="checksheet-form-item"
                      // style={{
                      //   outline:
                      //     value48.length !== 0 ? "1px solid red" : "none",
                      // }}
                    >
                      <Radio.Group size="small"
                        className={`${value48.length !== 0 ? 'style-error-radio-btn' : ''}`}
                      >
                        {options48.map((option, index) => (
                          <React.Fragment key={option.value}>
                            <Radio.Button
                              value={option.value}
                              disabled={!checkClickEdit}
                            >
                              {option.label}
                            </Radio.Button>
                            {index < options48.length - 1 && <span>/</span>}
                          </React.Fragment>
                        ))}
                      </Radio.Group>
                    </Form.Item>
                  </Tooltip>
                </Col>
              </Row>
              <Row style={{ borderBottom: "2px solid black" }}>
                <Col
                  span={24}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    background: "#ced4da",
                    justifyContent: "center",
                    height: 29.84,
                  }}
                >
                  <span style={{ marginLeft: 5 }}>指定塗装</span>
                </Col>
                <Col span={22} style={{ height: 100 }}>
                  <Row style={{ width: "100%", height: "100%" }}>
                    <Form.Item
                      name="55"
                      className="checksheet-form-item item-text-area"
                      style={{ width: "100%", height: "100%" }}
                    >
                      <Input.TextArea
                        style={{
                          width: "100%",
                          height: "100%",
                        }}
                        className="page-check-sheet-input"
                        readOnly={!checkClickEdit}
                      />
                    </Form.Item>
                  </Row>
                </Col>
                <Col
                  span={2}
                  style={{
                    height: "29.84px",
                  }}
                        className="align-col-checkbox"
                >
                  <Tooltip
                    placement="top"
                    title={
                      valueCheck_no55.length !== 0
                        ? valueCheck_no55[0]["check_no55"]
                        : ""
                    }
                  >
                    <Form.Item
                      name="check_no55"
                      valuePropName="checked"
                      style={{
                        height: "100%",
                      }}
                      className={
                                valueCheck_no55.length !== 0
                                  ? "style-error-checkbox"
                                  : ""
                              }
                    >
                      <Checkbox
                        disabled={!checkClickEdit}
                        // className={
                        //   valueCheck_no55.length !== 0
                        //     ? "check-box-checksheet"
                        //     : ""
                        // }
                      ></Checkbox>
                    </Form.Item>
                  </Tooltip>
                </Col>
                <Col span={24} style={{ marginBottom: 10 }}>
                  <Row>
                    <Col
                      span={12}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      合計
                    </Col>
                    <Col
                      span={12}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      μｍ以上
                    </Col>
                  </Row>
                </Col>
                <Col span={24} style={{ marginBottom: 10 }}>
                  <Row>
                    <Col
                      span={13}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      モータ塗装
                    </Col>
                    <Col span={2}></Col>
                    <Col
                      span={4}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      有
                    </Col>
                    <Col
                      span={4}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      無
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col span={12} style={{ borderRight: "1px solid black" }}>
                  <Row>
                    <Col span={4}>不合格印</Col>
                    <Col span={4}>最終検査</Col>
                    <Col span={16}></Col>
                  </Row>
                </Col>
                <Col span={12}>
                  <Row>
                    <Col span={4} style={{ display: "flex" }}>
                      合格印
                    </Col>
                    <Col span={4}>最終検査</Col>
                    <Col span={16}></Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </div>

      {checkClickEdit && (
        <Row>
          <Col
            span={24}
            style={{
              display: "flex",
              justifyContent: "center",
              padding: "10px 0",
            }}
          >
            <Button
              variant="outlined"
              style={{ marginRight: 20 }}
              onClick={handleCancelEdit}
            >
              {/* Cancel */}
              <b>キャンセル</b>
            </Button>
            <Button
              variant="outlined"
              style={{ marginRight: 20 }}
              onClick={handleClickSaveDraft}
            >
            {/* Save draft */}
              <b>一時保存</b>
            </Button>
            <Button variant="outlined" onClick={() => checkLogicRule()}>
            {/* Done */}
              <b>完了</b>
            </Button>
          </Col>
        </Row>
      )}

      <ModalCheckSheetError
        isModalOpenCheckSheetError={isModalOpenCheckSheetError}
        setIsModalOpenCheckSheetError={setIsModalOpenCheckSheetError}
        listCheckSheetError={listCheckSheetError}
      />
    </>
  );
};

export default PageCheckSheet;
