export const dataCheckSheet = [
  {
    id: 0,
    1: "24.2.26-大野",
    2: "LK-F32VCH EUR",
    3: "E1064982_1",
    4: "E24012847~E24012847",
    5: "",
    6: "",
    57: "1.0",
    7.1: "0.50/0.60",
    7.2: "1.0",
    7.3: "96/116",
    7.4: "50/60",
    8: "",
    9: "",
    10: "PVC",
    11: "セラミックス",
    12: "PVC",
    13: "FKM",
    14: "FKM",
    15: "PTFE",
    16: "ホースΦ12xΦ18",
    17: "ホースΦ12xΦ18",
    18: "Φ12xΦ18",
    19: "S-V12H",
    20: "BVC-1PVL-12H",
    21: "FKM",
    22: "",
    23: "",
    24: "",
    25: "",
    26: "",
    27: "",
    28: "",
    29: "",
    30: "",
    34: "0.4",
    35: "4.0",
    36: "3.0",
    37: "",
    38: "",
    39: "",
    40: "",
    41: "",
    42: "",
    43: "",
    49: "",
    50: "",
    51.1: "",
    51.2: "",
    51.3: "",
    51.4: "",
    52: "",
    53: "",
    31: "",
    32: "",
    33: "",
    44: "",
    45: "",
    46: "",
    47: "",
    48: "",
    54: "・オイル抜き別ボトル /n ・EUR向け取説（MFGNo.テブラを袋に貼り付け）/n ・Mなし",
    55: "",
    56: "",
    58: "02.12.05",
    59: "02.12.05",
    60: "24.02.16",
    61: "21821174",
    62: "Rainscales",
    63: "H1064982_1",
    64: "",
    65: "",
    66: "",

    check_no1: "",
    check_no2: "",
    check_no3: "",
    check_no4: "",
    check_no5: "",
    check_no6: "",
    check_no57: "",
    check_no7_1: "",
    check_no7_2: "",
    check_no7_3: "",
    check_no7_4: "",
    check_no18: "",
    check_no19: "",
    check_no20: "",
    check_no21: "",
    check_no34: "",
    check_no35: "",
    check_no36: "",
    check_no37: "",
    check_no38: "",
    check_no31: "",
    check_no32: "",
    check_no33: "",
    check_no44_bp65: "",
    check_no44_bp68: "",
    check_no44_bp71: "",
    check_no44_bp74: "",
    check_no44_bp77: "",
    check_no44_bp80: "",
    check_no44_bp83: "",
    check_no44_bp86: "",
    check_no44_bp89: "",
    check_no44_bp92: "",
    check_no54: "",
    check_no55: "",
    check_no62: "",
    check_no63: "",
    check_no64: "",

    status: '未検査',
    orderNumber: 'E1064982'
  },
  {
    id: 1,
    1: "24.2.26-大野",
    2: "LK-32VCU-02",
    3: "E1056327_1",
    4: "E24012797~E24012797",
    5: "",
    6: "",
    57: "1.0",
    7.1: "0.50/0.60",
    7.2: "1.0",
    7.3: "96/116",
    7.4: "50/60",
    8: "",
    9: "",
    10: "PVC",
    11: "セラミックス",
    12: "PVC",
    13: "FKM",
    14: "FKM",
    15: "PTFE",
    16: "ユニオン VP16",
    17: "ユニオン VP16",
    18: "Φ12xΦ18",
    19: "S-V12H",
    20: "",
    21: "FKM",
    22: "",
    23: "",
    24: "",
    25: "",
    26: "",
    27: "",
    28: "",
    29: "",
    30: "",
    34: "0.2",
    35: "4.0",
    36: "3.0",
    37: "B",
    38: "",
    39: "",
    40: "",
    41: "",
    42: "",
    43: "",
    49: "",
    50: "",
    51.1: "",
    51.2: "",
    51.3: "",
    51.4: "",
    52: "",
    53: "",
    31: "日東",
    32: "全閉外扇",
    33: "屋外",
    44: "",
    45: "",
    46: "",
    47: "",
    48: "",
    54: "",
    55: "",
    56: "",
    58: "02.12.05",
    59: "02.12.05",
    60: "24.02.16",
    61: "21821171",
    62: "Rainscales",
    63: "H1056327_1",
    64: "",
    65: "",
    66: "",

    check_no1: "",
    check_no2: "",
    check_no3: "",
    check_no4: "",
    check_no5: "",
    check_no6: "",
    check_no57: "",
    check_no7_1: "",
    check_no7_2: "",
    check_no7_3: "",
    check_no7_4: "",
    check_no18: "",
    check_no19: "",
    check_no20: "",
    check_no21: "",
    check_no34: "",
    check_no35: "",
    check_no36: "",
    check_no37: "",
    check_no38: "",
    check_no31: "",
    check_no32: "",
    check_no33: "",
    check_no44_bp65: "",
    check_no44_bp68: "",
    check_no44_bp71: "",
    check_no44_bp74: "",
    check_no44_bp77: "",
    check_no44_bp80: "",
    check_no44_bp83: "",
    check_no44_bp86: "",
    check_no44_bp89: "",
    check_no44_bp92: "",
    check_no54: "",
    check_no55: "",
    check_no62: "",
    check_no63: "",
    check_no64: "",

    status: '未検査',
    orderNumber: 'E1056327'
  },
  {
    id: 2,
    1: "24.2.26-大野",
    2: "LK-31S6-02F",
    3: "E1064796_1",
    4: "E24012796~E24012796",
    5: "",
    6: "実性能",
    57: "1.0",
    7.1: "0.4",
    7.2: "1.5",
    7.3: "78",
    7.4: "80",
    8: "",
    9: "",
    10: "SUS316",
    11: "SUS316",
    12: "SUS316",
    13: "SUS316",
    14: "",
    15: "PTFE",
    16: "JIS 16K 15A FF ストレートフランジ",
    17: "JIS 16K 15A FF ストレートフランジ",
    18: "",
    19: "",
    20: "",
    21: "",
    22: "",
    23: "",
    24: "",
    25: "",
    26: "",
    27: "",
    28: "",
    29: "",
    30: "",
    34: "0.2",
    35: "4.0",
    36: "3.0",
    37: "B",
    38: "",
    39: "",
    40: "",
    41: "",
    42: "",
    43: "",
    49: "",
    50: "",
    51.1: "",
    51.2: "",
    51.3: "",
    51.4: "",
    52: "",
    53: "",
    31: "日東",
    32: "インバータ",
    33: "屋外",
    44: "",
    45: "",
    46: "",
    47: "",
    48: "",
    54: "バルブ材質:SUS316 QC暫-510",
    55: "",
    56: "",
    58: "02.12.05",
    59: "02.12.05",
    60: "24.02.16",
    61: "E000000883",
    62: "Rainscales",
    63: "H1064796_1",
    64: "",
    65: "",
    66: "",

    check_no1: "",
    check_no2: "",
    check_no3: "",
    check_no4: "",
    check_no5: "",
    check_no6: "",
    check_no57: "",
    check_no7_1: "",
    check_no7_2: "",
    check_no7_3: "",
    check_no7_4: "",
    check_no18: "",
    check_no19: "",
    check_no20: "",
    check_no21: "",
    check_no34: "",
    check_no35: "",
    check_no36: "",
    check_no37: "",
    check_no38: "",
    check_no31: "",
    check_no32: "",
    check_no33: "",
    check_no44_bp65: "",
    check_no44_bp68: "",
    check_no44_bp71: "",
    check_no44_bp74: "",
    check_no44_bp77: "",
    check_no44_bp80: "",
    check_no44_bp83: "",
    check_no44_bp86: "",
    check_no44_bp89: "",
    check_no44_bp92: "",
    check_no54: "",
    check_no55: "",
    check_no62: "",
    check_no63: "",
    check_no64: "",

    status: '未検査',
    orderNumber: 'E1064796'
  },
  {
    id: 3,
    1: "24.2.26-大野",
    2: "LK-45VS-02",
    3: "E1062446_1",
    4: "E24012802~E24012802",
    5: "",
    6: "",
    57: "2.0",
    7.1: "0.85/1",
    7.2: "1.0",
    7.3: "48/58",
    7.4: "50/60",
    8: "",
    9: "",
    10: "PVC",
    11: "ハステロイC",
    12: "PVC",
    13: "SUS304",
    14: "EPDM",
    15: "PTFE",
    16: "JIS 10K 15A FF エルボフランジ",
    17: "JIS 10K 15A FF ストレートフランジ",
    18: "Φ12xΦ18",
    19: "S-V12H",
    20: "",
    21: "EPDM",
    22: "",
    23: "",
    24: "",
    25: "",
    26: "",
    27: "",
    28: "",
    29: "",
    30: "",
    34: "0.2",
    35: "4.0",
    36: "3.0",
    37: "B",
    38: "",
    39: "",
    40: "",
    41: "",
    42: "",
    43: "",
    49: "",
    50: "",
    51.1: "",
    51.2: "",
    51.3: "",
    51.4: "",
    52: "",
    53: "",
    31: "日東",
    32: "全閉外扇",
    33: "屋外",
    44: "",
    45: "",
    46: "",
    47: "",
    48: "",
    54: "",
    55: "",
    56: "",
    58: "02.12.05",
    59: "02.12.05",
    60: "24.02.16",
    61: "E000000507",
    62: "Rainscales",
    63: "H1062446_1",
    64: "",
    65: "",
    66: "",

    check_no1: "",
    check_no2: "",
    check_no3: "",
    check_no4: "",
    check_no5: "",
    check_no6: "",
    check_no57: "",
    check_no7_1: "",
    check_no7_2: "",
    check_no7_3: "",
    check_no7_4: "",
    check_no18: "",
    check_no19: "",
    check_no20: "",
    check_no21: "",
    check_no34: "",
    check_no35: "",
    check_no36: "",
    check_no37: "",
    check_no38: "",
    check_no31: "",
    check_no32: "",
    check_no33: "",
    check_no44_bp65: "",
    check_no44_bp68: "",
    check_no44_bp71: "",
    check_no44_bp74: "",
    check_no44_bp77: "",
    check_no44_bp80: "",
    check_no44_bp83: "",
    check_no44_bp86: "",
    check_no44_bp89: "",
    check_no44_bp92: "",
    check_no54: "",
    check_no55: "",
    check_no62: "",
    check_no63: "",
    check_no64: "",

    status: '未検査',
    orderNumber: 'E1062446'
  },
  {
    id: 4,
    1: "24.2.26-大野",
    2: "LK-45VS-02",
    3: "E1062446_2",
    4: "E24012803~E24012803",
    5: "",
    6: "",
    57: "2.0",
    7.1: "0.85/1",
    7.2: "1.0",
    7.3: "48/58",
    7.4: "50/60",
    8: "",
    9: "",
    10: "PVC",
    11: "SUS316",
    12: "PVC",
    13: "SUS304",
    14: "EPDM",
    15: "PTFE",
    16: "JIS 10K 15A FF エルボフランジ",
    17: "JIS 10K 15A FF ストレートフランジ",
    18: "Φ12xΦ18",
    19: "S-V12H",
    20: "",
    21: "EPDM",
    22: "",
    23: "",
    24: "",
    25: "",
    26: "",
    27: "",
    28: "",
    29: "",
    30: "",
    34: "0.2",
    35: "4.0",
    36: "3.0",
    37: "B",
    38: "",
    39: "",
    40: "",
    41: "",
    42: "",
    43: "",
    49: "",
    50: "",
    51.1: "",
    51.2: "",
    51.3: "",
    51.4: "",
    52: "",
    53: "",
    31: "日東",
    32: "全閉外扇",
    33: "屋外",
    44: "",
    45: "",
    46: "",
    47: "",
    48: "",
    54: "バルブ材質：SUS316 QC暫-510",
    55: "",
    56: "",
    58: "02.12.05",
    59: "02.12.05",
    60: "24.02.16",
    61: "E000000507",
    62: "Rainscales",
    63: "H1062446_2",
    64: "",
    65: "",
    66: "",

    check_no1: "",
    check_no2: "",
    check_no3: "",
    check_no4: "",
    check_no5: "",
    check_no6: "",
    check_no57: "",
    check_no7_1: "",
    check_no7_2: "",
    check_no7_3: "",
    check_no7_4: "",
    check_no18: "",
    check_no19: "",
    check_no20: "",
    check_no21: "",
    check_no34: "",
    check_no35: "",
    check_no36: "",
    check_no37: "",
    check_no38: "",
    check_no31: "",
    check_no32: "",
    check_no33: "",
    check_no44_bp65: "",
    check_no44_bp68: "",
    check_no44_bp71: "",
    check_no44_bp74: "",
    check_no44_bp77: "",
    check_no44_bp80: "",
    check_no44_bp83: "",
    check_no44_bp86: "",
    check_no44_bp89: "",
    check_no44_bp92: "",
    check_no54: "",
    check_no55: "",
    check_no62: "",
    check_no63: "",
    check_no64: "",

    status: '未検査',
    orderNumber: 'E1062446'
  },
];
