import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { data as dataMaster } from "../../data/dataMaster";
import PageCheckSheet from "./PageCheckSheet";
import { Form } from "antd";

const PageCheckSheetIndex = () => {
  const [form] = Form.useForm();
  const history = useHistory();

  const [valueCheckSheet, setValueCheckSheet] = useState(undefined);
  const [listCheckSheetError, setListCheckSheetError] = useState([]);
  const [isModalOpenCheckSheetError, setIsModalOpenCheckSheetError] =
    useState(false);
  const [checkClickEdit, setCheckClickEdit] = useState(false);
  const [orderNumber, setOrderNumber] = useState("");

  const options8 = [
    {
      label: "有",
      value: "有",
      disabled: !checkClickEdit,
    },
    {
      label: "無",
      value: "無",
      disabled: !checkClickEdit,
    },
  ];

  const options45 = [
    {
      label: "144",
      value: "144",
    },
    {
      label: "145",
      value: "145",
    },
    {
      label: "146",
      value: "146",
    },
  ];

  const options48 = [
    {
      label: "合",
      value: "合",
      disabled: !checkClickEdit,
    },
    {
      label: "否",
      value: "否",
      disabled: !checkClickEdit,
    },
  ];
  // const array_logic48 = options48.map(item => item.value)

  const options39 = [
    {
      label: "200",
      value: "200",
      disabled: !checkClickEdit,
    },
    {
      label: "220",
      value: "220",
      disabled: !checkClickEdit,
    },
    {
      label: "380",
      value: "380",
      disabled: !checkClickEdit,
    },
    {
      label: "400",
      value: "400",
      disabled: !checkClickEdit,
    },
    {
      label: "440",
      value: "440",
      disabled: !checkClickEdit,
    },
  ];

  const options40 = [
    {
      label: "50",
      value: "50",
      disabled: !checkClickEdit,
    },
    {
      label: "60",
      value: "60",
      disabled: !checkClickEdit,
    },
  ];

  const array_logic30 = ["作業者A", "作業者B"];

  function checkFormValuesEmpty(keys, form) {
    // kiểm tra ô trống
    const errors = [];
    keys.forEach((key) => {
      const value = form[key];
      if (value === undefined || value === "") {
        errors.push(key);
      }
    });
    return errors;
  }

  function checkFormValues(keys, form, validValues) {
    // Kiểm tra ô không đúng giá trị
    const errors = [];
    keys.forEach((key) => {
      const value = form[key];
      if (!validValues.includes(value)) {
        errors.push(key);
      }
    });

    return errors;
  }

  function checkRequiredForm_Master(
    keyCell,
    valueForm,
    getValueMasterNo4,
    dictError
  ) {
    // Kiểm tra với master và bắt buộc nhập
    var errorKeys_Empty = checkFormValuesEmpty([keyCell], valueForm);
    if (errorKeys_Empty.length != 0) {
      dictError.push({ [keyCell]: "必須情報", cell_error: errorKeys_Empty }); // Reuired
    } else {
      // Check No với master | Not match Master
      if (getValueMasterNo4 === undefined) {
        dictError.push({
          [keyCell]: "マスターと一致しない",
          cell_error: [keyCell],
        });
      } else {
        if (String(valueForm[keyCell]) !== String(getValueMasterNo4[keyCell])) {
          dictError.push({
            [keyCell]: "マスターと一致しない",
            cell_error: [keyCell],
          });
        }
      }
    }
  }

  const checkLogicRule = () => {
    // Chưa xử lý: No54, No55
    const valueForm = form.getFieldsValue();
    var getValueMasterNo4 = dataMaster.filter(
      (key) => key["4"] === valueForm["4"]
    )[0];

    const dictError = [];

    var arrKey30 = [
      "BE32",
      "BE35",
      "BE38",
      "BE41",
      "BE44",
      "BE47",
      "BE50",
      "BE53",
      "BE56",
      "BE59",
      "BE62",
      "BE65",
      "BE68",
      "BE74",
      "BE77",
      "BE80",
      "BE86",
      "BE89",
    ];

    // check No30
    var errorKeys_Empty = checkFormValuesEmpty(
      arrKey30,
      valueForm,
      array_logic30
    );
    if (errorKeys_Empty.length != 0) {
    // Required
      dictError.push({ 30: "必須情報", cell_error: errorKeys_Empty });
    }
    const arrKey30_diff = arrKey30.filter(
      (key) => !errorKeys_Empty.includes(key)
    );
    var errorKeys = checkFormValues(arrKey30_diff, valueForm, array_logic30);
    if (errorKeys.length != 0) {
    // Không đúng giá trị option | Not match Master
      dictError.push({ 30: "マスターと一致しない", cell_error: errorKeys });
    }

    // check No bắt buộc nhập
    var arrKey = [
      "1",
      "2",
      "3",
      "4",
      "57",
      "7.1",
      "7.2",
      "7.3",
      "7.4",
      "8",
      "9",
      "22",
      "23",
      "24",
      "25",
      "26",
      "27",
      "28",
      "29",
      "45",
      "47",
      "35",
      "36",
      "39",
      "40",
      "41",
      "42",
      "43",
    ];

    var errorKeys_Empty = checkFormValuesEmpty(arrKey, valueForm);
    if (errorKeys_Empty.length != 0) {
      errorKeys_Empty.forEach((item) => {
      // Required
        dictError.push({ [item]: "必須情報", cell_error: [item] });
      });
    }

    function handleCheckBoxErrors(keyCheckBox, valueForm, keyToCheck) {
      //  Checkbox bắt buộc nếu Trường đó không trống
      if (valueForm[keyToCheck] !== undefined && valueForm[keyToCheck] !== "") {
        if (valueForm[keyCheckBox] !== true) {
          dictError.push({
            [keyCheckBox]: "必須情報",
            cell_error: [keyCheckBox],
          });
        }
      } else {
        // TH textfield trống thì không cần check
        if (valueForm[keyCheckBox] === true) {
          dictError.push({
            [keyCheckBox]: "Not check required",
            cell_error: [keyCheckBox],
          });
        }
      }
    }

    // Use the function for each case
    handleCheckBoxErrors("check_no1", valueForm, "1");
    handleCheckBoxErrors("check_no2", valueForm, "2");
    handleCheckBoxErrors("check_no3", valueForm, "3");
    handleCheckBoxErrors("check_no4", valueForm, "4");
    handleCheckBoxErrors("check_no5", valueForm, "5");
    handleCheckBoxErrors("check_no6", valueForm, "6");
    handleCheckBoxErrors("check_no57", valueForm, "57");
    handleCheckBoxErrors("check_no7_1", valueForm, "7.1");
    handleCheckBoxErrors("check_no7_2", valueForm, "7.2");
    handleCheckBoxErrors("check_no7_3", valueForm, "7.3");
    handleCheckBoxErrors("check_no7_4", valueForm, "7.4");
    handleCheckBoxErrors("check_no18", valueForm, "18");
    handleCheckBoxErrors("check_no19", valueForm, "19");
    handleCheckBoxErrors("check_no20", valueForm, "20");
    handleCheckBoxErrors("check_no21", valueForm, "21");
    handleCheckBoxErrors("check_no34", valueForm, "34");
    handleCheckBoxErrors("check_no35", valueForm, "35");
    handleCheckBoxErrors("check_no36", valueForm, "36");
    handleCheckBoxErrors("check_no37", valueForm, "37");
    handleCheckBoxErrors("check_no38", valueForm, "38");
    handleCheckBoxErrors("check_no31", valueForm, "31");
    handleCheckBoxErrors("check_no32", valueForm, "32");
    handleCheckBoxErrors("check_no33", valueForm, "33");
    handleCheckBoxErrors("check_no44_bp65", valueForm, "BP65");
    handleCheckBoxErrors("check_no44_bp68", valueForm, "BP68");
    handleCheckBoxErrors("check_no44_bp71", valueForm, "BP71");
    handleCheckBoxErrors("check_no44_bp74", valueForm, "BP74");
    handleCheckBoxErrors("check_no44_bp77", valueForm, "BP77");
    handleCheckBoxErrors("check_no44_bp80", valueForm, "BP80");
    handleCheckBoxErrors("check_no44_bp83", valueForm, "BP83");
    handleCheckBoxErrors("check_no44_bp86", valueForm, "BP86");
    handleCheckBoxErrors("check_no44_bp89", valueForm, "BP89");
    handleCheckBoxErrors("check_no44_bp92", valueForm, "BP92");
    handleCheckBoxErrors("check_no44_bp92", valueForm, "BP92");
    handleCheckBoxErrors("check_no54", valueForm, "54");
    handleCheckBoxErrors("check_no55", valueForm, "55");
    handleCheckBoxErrors("check_no62", valueForm, "62");
    handleCheckBoxErrors("check_no63", valueForm, "63");
    handleCheckBoxErrors("check_no64", valueForm, "64");
    handleCheckBoxErrors("check_no65", valueForm, "65");
    handleCheckBoxErrors("check_no66", valueForm, "66");

    // check No46
    var arrKey = ["BY98_1", "BY98_2"];
    var errorKeys_Empty = checkFormValuesEmpty(arrKey, valueForm);
    if (errorKeys_Empty.length != 0) {
      // Required
      dictError.push({ 46: "必須情報", cell_error: errorKeys_Empty });
    }

    // check No52
    var keyCell = "52";
    var errorKeys_Empty = checkFormValuesEmpty([keyCell], valueForm);
    if (errorKeys_Empty.length != 0) {
      // Required
      dictError.push({ [keyCell]: "必須情報", cell_error: errorKeys_Empty });
    } else {
      // Check No52: No52<No41 --> Thì hợp lệ, Nếu không thì cảnh báo: "Not less than  モータ定格電流値"
      if (valueForm[keyCell] >= valueForm["41"]) {
        dictError.push({
          [keyCell]: "モーター定格電流値より大きい", // "Not less than  モータ定格電流値",
          cell_error: [keyCell],
        });
      }
    }

    // check No49
    var keyCell = "49";
    var errorKeys_Empty = checkFormValuesEmpty([keyCell], valueForm);
    if (errorKeys_Empty.length != 0) {
      // Required
      dictError.push({ [keyCell]: "必須情報", cell_error: errorKeys_Empty });
    } else {
      // Check No49: Nếu No49=No40 --> thì hợp lệ, nếu không thì cảnh báo: "Not equal to 周波数"
      // console.log(valueForm[keyCell]);
      // console.log(valueForm["40"]);
      // console.log(String(valueForm[keyCell]) !== String(valueForm["40"]));

      if (String(valueForm[keyCell]) !== String(valueForm["40"])) {
      console.log("「周波数」と等しくない");

        dictError.push({
          [keyCell]: "「周波数」と等しくない", // "Not equal to 周波数",
          cell_error: [keyCell],
        });
      }
    }

    // check No?? với master
    var arrKeyCheckMaster = ["14", "18", "19", "20", "21"];
    arrKeyCheckMaster.forEach((item) => {
      if (getValueMasterNo4 === undefined) {
        dictError.push({
          [item]: "マスターと一致しない", // Not match Master
          cell_error: [item],
        });
      } else {
        if (String(valueForm[item]) !== String(getValueMasterNo4[item])) {
          dictError.push({
            [item]: "マスターと一致しない",
            cell_error: [item],
          });
        }
      }
    });

    checkRequiredForm_Master("48", valueForm, getValueMasterNo4, dictError);
    checkRequiredForm_Master("50", valueForm, getValueMasterNo4, dictError);
    checkRequiredForm_Master("53", valueForm, getValueMasterNo4, dictError);
    checkRequiredForm_Master("34", valueForm, getValueMasterNo4, dictError);
    checkRequiredForm_Master("11", valueForm, getValueMasterNo4, dictError);
    checkRequiredForm_Master("12", valueForm, getValueMasterNo4, dictError);
    checkRequiredForm_Master("13", valueForm, getValueMasterNo4, dictError);
    checkRequiredForm_Master("10", valueForm, getValueMasterNo4, dictError);
    checkRequiredForm_Master("15", valueForm, getValueMasterNo4, dictError);
    checkRequiredForm_Master("16", valueForm, getValueMasterNo4, dictError);
    checkRequiredForm_Master("17", valueForm, getValueMasterNo4, dictError);

    setListCheckSheetError(dictError);
    // handleClickSubmit();

    if (dictError.length == 0) {
      setCheckClickEdit(false);
      handleClickSubmit();
    }
  };
  let listDataGoc = JSON.parse(sessionStorage.getItem("listDataGoc"));

  useEffect(() => {
    const id_cs = sessionStorage.getItem("id_cs");
    // const IndexTable = sessionStorage.getItem("index");
    if (listDataGoc === null){
      history.push(`/`);
      return;
    }
    const targetItem = listDataGoc.find((item) =>
      // item.items.some((dict) => dict["4"] === IndexTable)
      item.items.some((dict) => String(dict["id"]) === String(id_cs))
    );
    // let getCS = targetItem.items.filter((dict) => dict["4"] === IndexTable);
    let getCS = targetItem.items.filter((dict) => String(dict["id"]) === String(id_cs));
    setOrderNumber(targetItem.orderNumber);
    setValueCheckSheet(getCS[0]);
  }, []);

  useEffect(() => {
    if (valueCheckSheet !== undefined) {
      // Set value No30
      let get_value_no30 = valueCheckSheet[30];
      if (String(get_value_no30).includes("‡")) {
        get_value_no30 = get_value_no30.split("‡");
        form.setFieldsValue({
          BE32: get_value_no30[0],
          BE35: get_value_no30[1],
          BE38: get_value_no30[2],
          BE41: get_value_no30[3],
          BE44: get_value_no30[4],
          BE47: get_value_no30[5],
          BE50: get_value_no30[6],
          BE53: get_value_no30[7],
          BE56: get_value_no30[8],
          BE59: get_value_no30[9],
          BE62: get_value_no30[10],
          BE65: get_value_no30[11],
          BE68: get_value_no30[12],
          BE74: get_value_no30[13],
          BE77: get_value_no30[14],
          BE80: get_value_no30[15],
          BE86: get_value_no30[16],
          BE89: get_value_no30[17],
        });
      }

      // Set value No44
      let get_value_no44 = valueCheckSheet[44];
      if (String(get_value_no44).includes("‡")) {
        get_value_no44 = get_value_no44.split("‡");
        form.setFieldsValue({
          BP65: get_value_no44[0],
          BP68: get_value_no44[1],
          BP71: get_value_no44[2],
          BP74: get_value_no44[3],
          BP77: get_value_no44[4],
          BP80: get_value_no44[5],
          BP83: get_value_no44[6],
          BP86: get_value_no44[7],
          BP89: get_value_no44[8],
          BP92: get_value_no44[9],

        });
      }

      let get_value_no46 = valueCheckSheet[46];
      if (String(get_value_no46).includes("‡")) {
        get_value_no46 = get_value_no46.split("‡");
        form.setFieldsValue({
          BY98_1: get_value_no46[0],
          BY98_2: get_value_no46[1],
        });
      }

      form.setFieldsValue({
        1: valueCheckSheet[1],
        2: valueCheckSheet[2],
        3: valueCheckSheet[3],
        4: valueCheckSheet[4],
        5: valueCheckSheet[5],
        6: valueCheckSheet[6],
        57: valueCheckSheet[57],
        7.1: valueCheckSheet[7.1],
        7.2: valueCheckSheet[7.2],
        7.3: valueCheckSheet[7.3],
        7.4: valueCheckSheet[7.4],
        8: valueCheckSheet[8],
        8.1: valueCheckSheet[8.1],
        9: valueCheckSheet[9],
        10: valueCheckSheet[10],
        11: valueCheckSheet[11],
        12: valueCheckSheet[12],
        13: valueCheckSheet[13],
        14: valueCheckSheet[14],
        15: valueCheckSheet[15],
        16: valueCheckSheet[16],
        17: valueCheckSheet[17],
        18: valueCheckSheet[18],
        19: valueCheckSheet[19],
        20: valueCheckSheet[20],
        21: valueCheckSheet[21],
        22: valueCheckSheet[22],
        23: valueCheckSheet[23],
        24: valueCheckSheet[24],
        25: valueCheckSheet[25],
        26: valueCheckSheet[26],
        27: valueCheckSheet[27],
        28: valueCheckSheet[28],
        29: valueCheckSheet[29],
        34: valueCheckSheet[34],
        35: valueCheckSheet[35],
        36: valueCheckSheet[36],
        37: valueCheckSheet[37],
        38: valueCheckSheet[38],
        39: valueCheckSheet[39],
        40: valueCheckSheet[40],
        41: valueCheckSheet[41],
        42: valueCheckSheet[42],
        43: valueCheckSheet[43],
        49: valueCheckSheet[49],
        50: valueCheckSheet[50],
        51.1: valueCheckSheet[51.1],
        51.2: valueCheckSheet[51.2],
        51.3: valueCheckSheet[51.3],
        51.4: valueCheckSheet[51.4],
        52: valueCheckSheet[52],
        53: valueCheckSheet[53],
        31: valueCheckSheet[31],
        32: valueCheckSheet[32],
        33: valueCheckSheet[33],
        45: valueCheckSheet[45],
        46: valueCheckSheet[46],
        47: valueCheckSheet[47],
        48: valueCheckSheet[48],
        54: valueCheckSheet[54],
        55: valueCheckSheet[55],
        58: valueCheckSheet[58],
        59: valueCheckSheet[59],
        60: valueCheckSheet[60],
        61: valueCheckSheet[61],
        62: valueCheckSheet[62],
        63: valueCheckSheet[63],
        64: valueCheckSheet[64],
        65: valueCheckSheet[66],
        66: valueCheckSheet[66],

        check_no1: valueCheckSheet["check_no1"],
        check_no2: valueCheckSheet["check_no2"],
        check_no3: valueCheckSheet["check_no3"],
        check_no4: valueCheckSheet["check_no4"],
        check_no5: valueCheckSheet["check_no5"],
        check_no6: valueCheckSheet["check_no6"],
        check_no57: valueCheckSheet["check_no57"],
        check_no7_1: valueCheckSheet["check_no7_1"],
        check_no7_2: valueCheckSheet["check_no7_2"],
        check_no7_3: valueCheckSheet["check_no7_3"],
        check_no7_4: valueCheckSheet["check_no7_4"],
        check_no18: valueCheckSheet["check_no18"],
        check_no19: valueCheckSheet["check_no19"],
        check_no20: valueCheckSheet["check_no20"],
        check_no21: valueCheckSheet["check_no21"],
        check_no34: valueCheckSheet["check_no34"],
        check_no35: valueCheckSheet["check_no35"],
        check_no36: valueCheckSheet["check_no36"],
        check_no37: valueCheckSheet["check_no37"],
        check_no38: valueCheckSheet["check_no38"],
        check_no31: valueCheckSheet["check_no31"],
        check_no32: valueCheckSheet["check_no32"],
        check_no33: valueCheckSheet["check_no33"],
        check_no44_bp65: valueCheckSheet["check_no44_bp65"],
        check_no44_bp68: valueCheckSheet["check_no44_bp68"],
        check_no44_bp71: valueCheckSheet["check_no44_bp71"],
        check_no44_bp74: valueCheckSheet["check_no44_bp74"],
        check_no44_bp77: valueCheckSheet["check_no44_bp77"],
        check_no44_bp80: valueCheckSheet["check_no44_bp80"],
        check_no44_bp83: valueCheckSheet["check_no44_bp83"],
        check_no44_bp86: valueCheckSheet["check_no44_bp86"],
        check_no44_bp89: valueCheckSheet["check_no44_bp89"],
        check_no44_bp92: valueCheckSheet["check_no44_bp92"],
        check_no54: valueCheckSheet["check_no54"],
        check_no55: valueCheckSheet["check_no55"],
        check_no62: valueCheckSheet["check_no62"],
        check_no63: valueCheckSheet["check_no63"],
        check_no64: valueCheckSheet["check_no64"],
        check_no65: valueCheckSheet["check_no65"],
        check_no66: valueCheckSheet["check_no66"],
      });
    }
  }, [form, valueCheckSheet]);

  const handleClickBack = () => {
    history.push("/");
  };

  const handleClickEdit = () => {
    setCheckClickEdit(true);
  };

  const handleCancelEdit = () => {
    setCheckClickEdit(false);
    form.resetFields();

    // const IndexTable = sessionStorage.getItem("index");
    const id_cs = sessionStorage.getItem("id_cs");
    const targetItem = listDataGoc.find((item) =>
      item.items.some((dict) => String(dict["id"]) === String(id_cs))
    );
    let getCS = targetItem.items.filter(
      (dict) => String(dict["id"]) === String(id_cs)
    );
    setOrderNumber(targetItem.orderNumber);
    setValueCheckSheet(getCS[0]);
  };

  const handleClickSubmit = () => {
    // const IndexTable = sessionStorage.getItem("index");
    const id_cs = sessionStorage.getItem("id_cs");
    let get_listDataGoc = JSON.parse(sessionStorage.getItem("listDataGoc"));
    let targetItem = get_listDataGoc.find(
      (item) => item.orderNumber === orderNumber
    );
    let getCS_old = targetItem.items.filter(
      (dict) => String(dict["id"]) === String(id_cs)
    );

    const valueForm = form.getFieldsValue();

    // Tạo giá trị lưu lại CS
    // No30
    getCS_old[0]["30"] = [
      valueForm["BE32"],
      valueForm["BE35"],
      valueForm["BE38"],
      valueForm["BE41"],
      valueForm["BE44"],
      valueForm["BE47"],
      valueForm["BE50"],
      valueForm["BE53"],
      valueForm["BE56"],
      valueForm["BE59"],
      valueForm["BE62"],
      valueForm["BE65"],
      valueForm["BE68"],
      valueForm["BE74"],
      valueForm["BE77"],
      valueForm["BE80"],
      valueForm["BE86"],
      valueForm["BE89"],
    ].join("‡");

    getCS_old[0]["44"] = [
      valueForm["BP65"],
      valueForm["BP68"],
      valueForm["BP71"],
      valueForm["BP74"],
      valueForm["BP77"],
      valueForm["BP80"],
      valueForm["BP83"],
      valueForm["BP86"],
      valueForm["BP89"],
      valueForm["BP92"],
    ].join("‡");

    getCS_old[0]["46"] = [valueForm["BY98_1"], valueForm["BY98_2"]].join("‡");

    getCS_old[0]["48"] = valueForm["48"];
    getCS_old[0]["50"] = valueForm["50"];
    getCS_old[0]["53"] = valueForm["53"];
    getCS_old[0]["34"] = valueForm["34"];
    getCS_old[0]["11"] = valueForm["11"];
    getCS_old[0]["12"] = valueForm["12"];
    getCS_old[0]["13"] = valueForm["13"];
    getCS_old[0]["14"] = valueForm["14"];
    getCS_old[0]["15"] = valueForm["15"];
    getCS_old[0]["16"] = valueForm["16"];
    getCS_old[0]["17"] = valueForm["17"];
    getCS_old[0]["10"] = valueForm["10"];
    getCS_old[0]["18"] = valueForm["18"];
    getCS_old[0]["19"] = valueForm["19"];
    getCS_old[0]["20"] = valueForm["20"];
    getCS_old[0]["21"] = valueForm["21"];
    getCS_old[0]["49"] = valueForm["49"];
    getCS_old[0]["52"] = valueForm["52"];
    getCS_old[0]["8"] = valueForm["8"];
    getCS_old[0]["8.1"] = valueForm["8.1"];
    getCS_old[0]["9"] = valueForm["9"];
    getCS_old[0]["22"] = valueForm["22"];
    getCS_old[0]["23"] = valueForm["23"];
    getCS_old[0]["24"] = valueForm["24"];
    getCS_old[0]["25"] = valueForm["25"];
    getCS_old[0]["26"] = valueForm["26"];
    getCS_old[0]["27"] = valueForm["27"];
    getCS_old[0]["28"] = valueForm["28"];
    getCS_old[0]["29"] = valueForm["29"];
    getCS_old[0]["45"] = valueForm["45"];
    getCS_old[0]["47"] = valueForm["47"];
    getCS_old[0]["35"] = valueForm["35"];
    getCS_old[0]["36"] = valueForm["36"];
    getCS_old[0]["39"] = valueForm["39"];
    getCS_old[0]["40"] = valueForm["40"];
    getCS_old[0]["41"] = valueForm["41"];
    getCS_old[0]["42"] = valueForm["42"];
    getCS_old[0]["43"] = valueForm["43"];

    getCS_old[0]["51.1"] = valueForm["51.1"];
    getCS_old[0]["51.2"] = valueForm["51.2"];
    getCS_old[0]["51.3"] = valueForm["51.3"];
    getCS_old[0]["51.4"] = valueForm["51.4"];
    getCS_old[0]["31"] = valueForm["31"];
    getCS_old[0]["32"] = valueForm["32"];
    getCS_old[0]["33"] = valueForm["33"];
    getCS_old[0]["37"] = valueForm["37"];
    getCS_old[0]["38"] = valueForm["38"];
    getCS_old[0]["54"] = valueForm["54"];
    getCS_old[0]["55"] = valueForm["55"];
    getCS_old[0]["58"] = valueForm["58"];
    getCS_old[0]["59"] = valueForm["59"];
    getCS_old[0]["60"] = valueForm["60"];
    getCS_old[0]["61"] = valueForm["61"];
    getCS_old[0]["62"] = valueForm["62"];
    getCS_old[0]["63"] = valueForm["63"];
    getCS_old[0]["64"] = valueForm["64"];
    getCS_old[0]["65"] = valueForm["65"];
    getCS_old[0]["66"] = valueForm["66"];

    getCS_old[0]["check_no1"] = valueForm["check_no1"];
    getCS_old[0]["check_no2"] = valueForm["check_no2"];
    getCS_old[0]["check_no3"] = valueForm["check_no3"];
    getCS_old[0]["check_no4"] = valueForm["check_no4"];
    getCS_old[0]["check_no5"] = valueForm["check_no5"];
    getCS_old[0]["check_no6"] = valueForm["check_no6"];
    getCS_old[0]["check_no57"] = valueForm["check_no57"];
    getCS_old[0]["check_no7_1"] = valueForm["check_no7_1"];
    getCS_old[0]["check_no7_2"] = valueForm["check_no7_2"];
    getCS_old[0]["check_no7_3"] = valueForm["check_no7_3"];
    getCS_old[0]["check_no7_4"] = valueForm["check_no7_4"];
    getCS_old[0]["check_no18"] = valueForm["check_no18"];
    getCS_old[0]["check_no19"] = valueForm["check_no19"];
    getCS_old[0]["check_no20"] = valueForm["check_no20"];
    getCS_old[0]["check_no21"] = valueForm["check_no21"];
    getCS_old[0]["check_no34"] = valueForm["check_no34"];
    getCS_old[0]["check_no35"] = valueForm["check_no35"];
    getCS_old[0]["check_no36"] = valueForm["check_no36"];
    getCS_old[0]["check_no37"] = valueForm["check_no37"];
    getCS_old[0]["check_no38"] = valueForm["check_no38"];
    getCS_old[0]["check_no31"] = valueForm["check_no31"];
    getCS_old[0]["check_no32"] = valueForm["check_no32"];
    getCS_old[0]["check_no33"] = valueForm["check_no33"];
    getCS_old[0]["check_no44_bp65"] = valueForm["check_no44_bp65"];
    getCS_old[0]["check_no44_bp68"] = valueForm["check_no44_bp68"];
    getCS_old[0]["check_no44_bp71"] = valueForm["check_no44_bp71"];
    getCS_old[0]["check_no44_bp74"] = valueForm["check_no44_bp74"];
    getCS_old[0]["check_no44_bp77"] = valueForm["check_no44_bp77"];
    getCS_old[0]["check_no44_bp80"] = valueForm["check_no44_bp80"];
    getCS_old[0]["check_no44_bp83"] = valueForm["check_no44_bp83"];
    getCS_old[0]["check_no44_bp86"] = valueForm["check_no44_bp86"];
    getCS_old[0]["check_no44_bp89"] = valueForm["check_no44_bp89"];
    getCS_old[0]["check_no44_bp92"] = valueForm["check_no44_bp92"];
    getCS_old[0]["check_no54"] = valueForm["check_no54"];
    getCS_old[0]["check_no55"] = valueForm["check_no55"];
    getCS_old[0]["check_no62"] = valueForm["check_no62"];
    getCS_old[0]["check_no63"] = valueForm["check_no63"];
    getCS_old[0]["check_no64"] = valueForm["check_no64"];
    getCS_old[0]["check_no65"] = valueForm["check_no65"];
    getCS_old[0]["check_no66"] = valueForm["check_no66"];

    getCS_old[0]["1"] = valueForm["1"];
    getCS_old[0]["2"] = valueForm["2"];
    getCS_old[0]["3"] = valueForm["3"];
    getCS_old[0]["4"] = valueForm["4"];
    getCS_old[0]["5"] = valueForm["5"];
    getCS_old[0]["6"] = valueForm["6"];
    getCS_old[0]["57"] = valueForm["57"];
    getCS_old[0]["7.1"] = valueForm["7.1"];
    getCS_old[0]["7.2"] = valueForm["7.2"];
    getCS_old[0]["7.3"] = valueForm["7.3"];
    getCS_old[0]["7.4"] = valueForm["7.4"];

    // Update the items with the new status
    const updatedDataGoc = get_listDataGoc.map((item) => {
      if (item.orderNumber === orderNumber) {
        return {
          ...item,
          items: item.items.map((dict) => {
            if (String(dict["id"]) === String(id_cs)) {
              return { ...getCS_old[0], status: "完了" }; // Complete: 完了
            }
            return dict;
          }),
        };
      }
      return item;
    });

    sessionStorage.setItem("listDataGoc", JSON.stringify(updatedDataGoc));

    // Lấy ra các phần tử có status là processing cùng 1 OrderNumber
    let get_orderN = updatedDataGoc.filter(
      (item) => item.orderNumber === orderNumber
    );
    let processingItems = get_orderN[0].items.filter(
      (dict) => dict["status"] === "未検査" //"Processing"
    );

    if (processingItems !== undefined && processingItems.length > 0) {
      // If there are still processing items
      const nextProcessingItem = processingItems[0];
      form.resetFields();
      setValueCheckSheet(nextProcessingItem);
      sessionStorage.setItem("index", nextProcessingItem[4]);
    } else {
      // If no processing items are left, navigate to home
      history.push("/");
    }
    sessionStorage.setItem("checkListData", 1);
  };

  const handleClickSaveDraft = () => {
    const IndexTable = sessionStorage.getItem("index");
    const id_cs = sessionStorage.getItem("id_cs");
    let get_listDataGoc = JSON.parse(sessionStorage.getItem("listDataGoc"));
    let targetItem = get_listDataGoc.find(
      (item) => item.orderNumber === orderNumber
    );
    let getCS_old = targetItem.items.filter(
      (dict) => String(dict["id"]) === String(id_cs)
    );

    const valueForm = form.getFieldsValue();

    // Tạo giá trị lưu lại CS
    // No30
    getCS_old[0]["30"] = [
      valueForm["BE32"],
      valueForm["BE35"],
      valueForm["BE38"],
      valueForm["BE41"],
      valueForm["BE44"],
      valueForm["BE47"],
      valueForm["BE50"],
      valueForm["BE53"],
      valueForm["BE56"],
      valueForm["BE59"],
      valueForm["BE62"],
      valueForm["BE65"],
      valueForm["BE68"],
      valueForm["BE74"],
      valueForm["BE77"],
      valueForm["BE80"],
      valueForm["BE86"],
      valueForm["BE89"],
    ].join("‡");
    getCS_old[0]["44"] = [
      valueForm["BP65"],
      valueForm["BP68"],
      valueForm["BP71"],
      valueForm["BP74"],
      valueForm["BP77"],
      valueForm["BP80"],
      valueForm["BP83"],
      valueForm["BP86"],
      valueForm["BP89"],
      valueForm["BP92"],
    ].join("‡");
    getCS_old[0]["46"] = [valueForm["BY98_1"], valueForm["BY98_2"]].join("‡");

    getCS_old[0]["48"] = valueForm["48"];
    getCS_old[0]["50"] = valueForm["50"];
    getCS_old[0]["53"] = valueForm["53"];
    getCS_old[0]["34"] = valueForm["34"];
    getCS_old[0]["11"] = valueForm["11"];
    getCS_old[0]["12"] = valueForm["12"];
    getCS_old[0]["13"] = valueForm["13"];
    getCS_old[0]["14"] = valueForm["14"];
    getCS_old[0]["15"] = valueForm["15"];
    getCS_old[0]["16"] = valueForm["16"];
    getCS_old[0]["17"] = valueForm["17"];
    getCS_old[0]["10"] = valueForm["10"];
    getCS_old[0]["18"] = valueForm["18"];
    getCS_old[0]["19"] = valueForm["19"];
    getCS_old[0]["20"] = valueForm["20"];
    getCS_old[0]["21"] = valueForm["21"];
    getCS_old[0]["49"] = valueForm["49"];
    getCS_old[0]["52"] = valueForm["52"];
    getCS_old[0]["8"] = valueForm["8"];
    getCS_old[0]["8.1"] = valueForm["8.1"];
    getCS_old[0]["9"] = valueForm["9"];
    getCS_old[0]["22"] = valueForm["22"];
    getCS_old[0]["23"] = valueForm["23"];
    getCS_old[0]["24"] = valueForm["24"];
    getCS_old[0]["25"] = valueForm["25"];
    getCS_old[0]["26"] = valueForm["26"];
    getCS_old[0]["27"] = valueForm["27"];
    getCS_old[0]["28"] = valueForm["28"];
    getCS_old[0]["29"] = valueForm["29"];
    getCS_old[0]["45"] = valueForm["45"];
    getCS_old[0]["47"] = valueForm["47"];
    getCS_old[0]["35"] = valueForm["35"];
    getCS_old[0]["36"] = valueForm["36"];
    getCS_old[0]["39"] = valueForm["39"];
    getCS_old[0]["40"] = valueForm["40"];
    getCS_old[0]["41"] = valueForm["41"];
    getCS_old[0]["42"] = valueForm["42"];
    getCS_old[0]["43"] = valueForm["43"];

    getCS_old[0]["51.1"] = valueForm["51.1"];
    getCS_old[0]["51.2"] = valueForm["51.2"];
    getCS_old[0]["51.3"] = valueForm["51.3"];
    getCS_old[0]["51.4"] = valueForm["51.4"];
    getCS_old[0]["31"] = valueForm["31"];
    getCS_old[0]["32"] = valueForm["32"];
    getCS_old[0]["33"] = valueForm["33"];
    getCS_old[0]["37"] = valueForm["37"];
    getCS_old[0]["38"] = valueForm["38"];
    getCS_old[0]["54"] = valueForm["54"];
    getCS_old[0]["55"] = valueForm["55"];
    getCS_old[0]["58"] = valueForm["58"];
    getCS_old[0]["59"] = valueForm["59"];
    getCS_old[0]["60"] = valueForm["60"];
    getCS_old[0]["61"] = valueForm["61"];
    getCS_old[0]["62"] = valueForm["62"];
    getCS_old[0]["63"] = valueForm["63"];
    getCS_old[0]["64"] = valueForm["64"];
    getCS_old[0]["65"] = valueForm["65"];
    getCS_old[0]["66"] = valueForm["66"];
    getCS_old[0]["check_no1"] = valueForm["check_no1"];
    getCS_old[0]["check_no2"] = valueForm["check_no2"];
    getCS_old[0]["check_no3"] = valueForm["check_no3"];
    getCS_old[0]["check_no4"] = valueForm["check_no4"];
    getCS_old[0]["check_no5"] = valueForm["check_no5"];
    getCS_old[0]["check_no6"] = valueForm["check_no6"];
    getCS_old[0]["check_no57"] = valueForm["check_no57"];
    getCS_old[0]["check_no7_1"] = valueForm["check_no7_1"];
    getCS_old[0]["check_no7_2"] = valueForm["check_no7_2"];
    getCS_old[0]["check_no7_3"] = valueForm["check_no7_3"];
    getCS_old[0]["check_no7_4"] = valueForm["check_no7_4"];
    getCS_old[0]["check_no18"] = valueForm["check_no18"];
    getCS_old[0]["check_no19"] = valueForm["check_no19"];
    getCS_old[0]["check_no20"] = valueForm["check_no20"];
    getCS_old[0]["check_no21"] = valueForm["check_no21"];
    getCS_old[0]["check_no34"] = valueForm["check_no34"];
    getCS_old[0]["check_no35"] = valueForm["check_no35"];
    getCS_old[0]["check_no36"] = valueForm["check_no36"];
    getCS_old[0]["check_no44_bp65"] = valueForm["check_no44_bp65"];
    getCS_old[0]["check_no44_bp68"] = valueForm["check_no44_bp68"];
    getCS_old[0]["check_no44_bp71"] = valueForm["check_no44_bp71"];
    getCS_old[0]["check_no44_bp74"] = valueForm["check_no44_bp74"];
    getCS_old[0]["check_no44_bp77"] = valueForm["check_no44_bp77"];
    getCS_old[0]["check_no44_bp80"] = valueForm["check_no44_bp80"];
    getCS_old[0]["check_no44_bp83"] = valueForm["check_no44_bp83"];
    getCS_old[0]["check_no44_bp86"] = valueForm["check_no44_bp86"];
    getCS_old[0]["check_no44_bp89"] = valueForm["check_no44_bp89"];
    getCS_old[0]["check_no44_bp92"] = valueForm["check_no44_bp92"];
    getCS_old[0]["check_no54"] = valueForm["check_no54"];
    getCS_old[0]["check_no55"] = valueForm["check_no55"];
    getCS_old[0]["check_no62"] = valueForm["check_no62"];
    getCS_old[0]["check_no63"] = valueForm["check_no63"];
    getCS_old[0]["check_no64"] = valueForm["check_no64"];
    getCS_old[0]["check_no65"] = valueForm["check_no65"];
    getCS_old[0]["check_no66"] = valueForm["check_no66"];
    getCS_old[0]["1"] = valueForm["1"];
    getCS_old[0]["2"] = valueForm["2"];
    getCS_old[0]["3"] = valueForm["3"];
    getCS_old[0]["4"] = valueForm["4"];
    getCS_old[0]["5"] = valueForm["5"];
    getCS_old[0]["6"] = valueForm["6"];
    getCS_old[0]["57"] = valueForm["57"];
    getCS_old[0]["7.1"] = valueForm["7.1"];
    getCS_old[0]["7.2"] = valueForm["7.2"];
    getCS_old[0]["7.3"] = valueForm["7.3"];
    getCS_old[0]["7.4"] = valueForm["7.4"];
    // Update the items with the new status
    const updatedDataGoc = get_listDataGoc.map((item) => {
      return item;
    });

    sessionStorage.setItem("listDataGoc", JSON.stringify(updatedDataGoc));
    setCheckClickEdit(false);
    sessionStorage.setItem("checkListData", 1);
  };

  return (
    <PageCheckSheet
      handleClickBack={handleClickBack}
      valueCheckSheet={valueCheckSheet}
      checkClickEdit={checkClickEdit}
      handleClickEdit={handleClickEdit}
      form={form}
      options8={options8}
      array_logic30={array_logic30}
      options45={options45}
      options48={options48}
      options39={options39}
      options40={options40}
      handleCancelEdit={handleCancelEdit}
      checkLogicRule={checkLogicRule}
      // handleClickSubmit={handleClickSubmit}
      isModalOpenCheckSheetError={isModalOpenCheckSheetError}
      setIsModalOpenCheckSheetError={setIsModalOpenCheckSheetError}
      listCheckSheetError={listCheckSheetError}
      handleClickSaveDraft={handleClickSaveDraft}
    />
  );
};

export default PageCheckSheetIndex;
