/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { Box, IconButton, Menu, MenuItem } from "@mui/material";
import "./Header.css";
import { Avatar, Col, Row } from "antd";
import logoIwaki from "../../images/LogoIwaki.svg";
import AvataHeader from "../../images/AvataHeader.svg";
import LogoutIcon from "@mui/icons-material/Logout";
import { UserOutlined } from "@ant-design/icons";

const HeaderWeb = () => {
  const [isAnchorEl, setIsAnchorEl] = useState(null);
  const open = Boolean(isAnchorEl);

  const handleClosePerson = () => {
    setIsAnchorEl(null);
  };

  const handleClickPerson = (event) => {
    setIsAnchorEl(event.currentTarget);
  };

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      sx={{
        backgroundColor: "#fff",
        height: "fit-content",
        width: "100%",
      }}
    >
      {/* SEARCH BAR */}
      <Row
        style={{
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Col span={12}>
          <Box
            display="flex"
            borderRadius="3px"
            sx={{
              paddingLeft: "18px",
              margin: "10px 0",
            }}
          >
            <img src={logoIwaki} alt="" key={"logo"} width="30%"></img>
          </Box>
        </Col>
        <Col span={12}>
          <IconButton
            onClick={handleClickPerson}
            style={{ float: "right" }}
          >
            <Avatar src={AvataHeader}></Avatar>
          </IconButton>
          <Menu
              id="basic-menu"
              anchorEl={isAnchorEl}
              open={open}
              onClose={handleClosePerson}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem
                className={"menu-user"}
                // onClick={handleClickOpenModalInfor}
              >
                <UserOutlined style={{ marginRight: "5%" }} />
                Thông tin người dùng
              </MenuItem>
              <MenuItem
                style={{ width: "100%" }}
                className={"menu-user"}
                // onClick={() => logout_new()}
              >
                <LogoutIcon style={{ marginRight: "5%" }} />
                Log out
              </MenuItem>
            </Menu>
        </Col>
      </Row>
    </Box>
  );
};

export default HeaderWeb;
