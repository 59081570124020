// eslint-disable-next-line no-useless-rename
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useLocation,
} from "react-router-dom";
import { useEffect } from "react";
import { authSuccess } from "../app/Actions/auth";
import NotPermission from "../notPermission";
import NotfoundLayout from "../notfoundLayout";
import Cookies from "universal-cookie";
import { useDispatch } from "react-redux";
import { CustomLayout } from "../components/Layout";
import PropTypes from "prop-types";
import UserLayout from "../components/UserLayout";
import ListCheckSheets from "../pages/Home/ListCheckSheets";
import PageCheckSheetIndex from "../pages/PageCheckSheet/PageCheckSheetIndex";

const cookies = new Cookies();

// const ContainerFileManager = lazy(() => import('../management_of_user/ContainerFileManager'));

function PageTitleUpdater() {
  const location = useLocation();

  const pathToTitleMap = {
    "/CaptureCamera": "Camera",
    "/dashboard": "Dashboard",
    "/entry": "Entry",
    "/entry_classification": "CLF",
    "/check_classification": "CHECK_CLF",
    "/check": "Check",
    "/last_check": "Last check",
    "/user": "User",
  };

  useEffect(() => {
    const path = location.pathname;
    const baseTitle = "IWAKI";
    const pageTitle = pathToTitleMap[path]
      ? `${pathToTitleMap[path]} - ${baseTitle}`
      : baseTitle;
    document.title = pageTitle;
  }, [location]);

  return null;
}

function Main() {
  const dispatch = useDispatch();
  const lsPermissions = [sessionStorage.getItem("Role_Title")];

  let userId = "";
  if (
    sessionStorage.getItem("info_user") !== undefined &&
    sessionStorage.getItem("info_user") !== null
  ) {
    userId = JSON.parse(sessionStorage.getItem("info_user")).user_id;
  }

  const token = cookies.get(`token_iwaki_${userId}`);
  if (token) {
    dispatch(authSuccess(token));
  }

  let auth = token !== null && token !== undefined;

  if (lsPermissions[0] === null) {
    cookies.remove(`token_iwaki_${userId}`);
    cookies.remove(`refresh_iwaki_${userId}`);
    auth = false;
  }

  return (
    <Router>
      <PageTitleUpdater />
      <Switch>
        <ProtectLoginRoute exact path="/login" protect={auth}>
          <UserLayout></UserLayout>
        </ProtectLoginRoute>

        <RouteWithLayout
          component={ListCheckSheets}
          exact
          layout={CustomLayout}
          path="/"
          isPrivate={true}
          lsPermissions={[""]}
          permission={[""]}
          isLogged={true}
        />

        <RouteWithLayout
          component={PageCheckSheetIndex}
          exact
          layout={CustomLayout}
          path="/checksheet"
          isPrivate={true}
          lsPermissions={[""]}
          permission={[""]}
          isLogged={true}
        />

        <RouteWithLayout
          component={NotfoundLayout}
          layout={CustomLayout}
          path="/"
          lsPermissions={[""]}
          isPrivate={true}
          isLogged={auth}
          permission={"404"}
        />
      </Switch>
    </Router>
  );
}

const RouteWithLayout = (props) => {
  const {
    layout: Layout,
    isLogged,
    component: Component,
    isPrivate,
    lsPermissions,
    permission,
    path,
    ...rest
  } = props;

  const getRejectRoute = (type) => {
    if (type !== "404" && path !== "/") {
      type = "403";
    }

    switch (type) {
      case "403":
        return <NotPermission />;
      case "404":
        return <NotfoundLayout />;
      default:
        return <NotPermission />;
    }
  };

  const returnLogin = () => {
    window.location = "/login";
  };

  const renderComponent = () => {
    if (isPrivate) {
      // if (!isLogged) {
      //   return returnLogin();
      // }
      if (!lsPermissions || lsPermissions.length === 0) {
        return <span></span>;
      }
      if (!lsPermissions.some((r) => permission.includes(r))) {
        return getRejectRoute(permission);
      }
      return (
        <Layout isLogged={isLogged}>
          <Component {...props} />
        </Layout>
      );
    }

    return (
      <Layout isLogged={isLogged}>
        <Component {...props} />
      </Layout>
    );
  };

  return <Route {...rest} render={renderComponent} />;
};



const ProtectLoginRoute = ({
  protect,
  lsPermissionsType,
  lsPermissions,
  permission,
  user_info,
  children,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={() => (!protect ? children : <Redirect to={"/"}></Redirect>)}
    />
  );
};

ProtectLoginRoute.propTypes = {
  protect: PropTypes.any,
  lsPermissionsType: PropTypes.any,
  lsPermissions: PropTypes.any,
  permission: PropTypes.any,
  user_info: PropTypes.any,
  children: PropTypes.any,
};

RouteWithLayout.propTypes = {
  layout: PropTypes.any,
  isLogged: PropTypes.any,
  component: PropTypes.any,
  isPrivate: PropTypes.any,
  lsPermissions: PropTypes.any,
  permission: PropTypes.any,
  path: PropTypes.any,
};

export default Main;
