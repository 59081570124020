export const data = [
  {
    4: "E24012847~E24012847",
    7.1: "0.50/0.60",
    7.2: "1.0",
    7.3: "96/116",
    7.4: "50/60",
    10: "PVC",
    11: "セラミックス",
    12: "PVC",
    13: "FKM",
    14: "FKM",
    15: "PTFE",
    16: "ホースΦ12xΦ18",
    17: "ホースΦ12xΦ18",
    18: "Φ12xΦ18",
    19: "S-V12H",
    20: "BVC-1PVL-12H",
    21: "FKM",
    34: "0.4",
    50: "1.0",
    53: "500",
    48: "合",
  },
  {
    4: "E24012797~E24012797",
    7.1: "0.50/0.60",
    7.2: "1.0",
    7.3: "96/116",
    7.4: "50/60",
    10: "PVC",
    11: "セラミックス",
    12: "PVC",
    13: "FKM",
    14: "FKM",
    15: "PTFE",
    16: "ユニオン VP16",
    17: "ユニオン VP16",
    18: "Φ12xΦ18",
    19: "S-V12H",
    20: "",
    21: "FKM",
    34: "0.2",
    50: "1.0",
    53: "500",
    48: "合",
  },
  {
    4: "E24012796~E24012796",
    7.1: "0.25/0.30",
    7.2: "1.5",
    7.3: "48/58",
    7.4: "50/60",
    10: "SUS316",
    11: "SUS316",
    12: "SUS316",
    13: "SUS316",
    14: "",
    15: "PTFE",
    16: "JIS 16K 15A FF ストレートフランジ",
    17: "JIS 16K 15A FF ストレートフランジ",
    18: "",
    19: "",
    20: "",
    21: "",
    34: "0.2",
    50: "1.5",
    53: "300",
    48: "合",
  },
  {
    4: "E24012802~E24012802",
    7.1: "0.85/1.0",
    7.2: "1.0",
    7.3: "48/58",
    7.4: "50/60",
    10: "PVC",
    11: "ハステロイC",
    12: "PVC",
    13: "SUS304",
    14: "EPDM",
    15: "PTFE",
    16: "JIS 10K 15A FF エルボフランジ",
    17: "JIS 10K 15A FF ストレートフランジ",
    18: "Φ12xΦ18",
    19: "S-V12H",
    20: "",
    21: "EPDM",
    34: "0.2",
    50: "1.0",
    53: "850",
    48: "合",
  },
  {
    4: "E24012803~E24012803",
    7.1: "0.85/1.0",
    7.2: "1.0",
    7.3: "48/58",
    7.4: "50/60",
    10: "PVC",
    11: "SUS316",
    12: "PVC",
    13: "SUS304",
    14: "EPDM",
    15: "PTFE",
    16: "JIS 10K 15A FF エルボフランジ",
    17: "JIS 10K 15A FF ストレートフランジ",
    18: "Φ12xΦ18",
    19: "S-V12H",
    20: "",
    21: "EPDM",
    34: "0.2",
    50: "1.0",
    53: "850",
    48: "合",
  },
];
