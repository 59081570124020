import { Col, Input, Row, Table } from "antd";
import React, { useEffect, useState } from "react";
import { dataCheckSheet } from "../../data/dataChecksheet";
import { useHistory } from "react-router-dom";
import mingcute_edit from "../../images/mingcute_edit.svg";
import icon_done from "../../images/icon_done.svg";

import "./styles.scss";

const ListCheckSheets = () => {
  const [valueCheckSheet, setValueCheckSheet] = useState([]);
  const history = useHistory();

  const getCurrentDateTime = () => {
    const now = new Date();
    const date = now.toLocaleDateString(); // Format: MM/DD/YYYY or based on locale
    const time = now.toLocaleTimeString(); // Format: HH:MM:SS AM/PM or based on locale

    return `${date} ${time}`;
  };

  useEffect(() => {
    let time_start = sessionStorage.getItem("time_start");
    if (time_start === null) {
      sessionStorage.setItem("time_start", getCurrentDateTime());
    } else {
      const [storedDate, storedTime] = time_start.split(" ");
      const currentDate = new Date().toLocaleDateString();

      if (storedDate === currentDate) {
        const storedDateTime = new Date();
        storedDateTime.setHours(...storedTime.split(":"));
        const now = new Date();
        const diffInMilliseconds = now - storedDateTime;
        const diffInHours = diffInMilliseconds / (1000 * 60 * 60);

        if (diffInHours >= 23) {
          sessionStorage.clear();
          sessionStorage.setItem("time_start", getCurrentDateTime());
          console.log("Đã qua 23 tiếng");
        }
      } else {
        sessionStorage.clear();
        sessionStorage.setItem("time_start", getCurrentDateTime());
        console.log("Khác ngày");
      }
    }
  }, []);

  // let listData = JSON.parse(sessionStorage.getItem("listData"));
  let listDataGoc = JSON.parse(sessionStorage.getItem("listDataGoc"));

  const columns = [
    {
      title: "Order Number",
      dataIndex: "orderNumber",
      key: "orderNumber",
      width: 150,
    },
    {
      title: "MFG No.",
      dataIndex: "MFGNo.",
      key: "MFGNo.",
    },
    {
      title: "Model name",
      dataIndex: "ModelName",
      key: "ModelName",
    },
    {
      title: "Status",
      key: "state",
    },
  ];

  const expandedRowRender = (records) => {
    const columns = [
      {
        dataIndex: "",
        key: "",
        width: 150,
      },
      {
        dataIndex: "4",
        key: "4",
      },
      {
        dataIndex: "2",
        key: "2",
      },
      {
        dataIndex: "status",
        key: "status",
        render: (text, record, index) => (
          <div style={{ display: "flex", alignContent: "center" }}>
            <span>
            {/* // Complete: 完了 */}
              {record.status === "完了" ? (
                <img src={icon_done} alt="" />
              ) : (
                <img src={mingcute_edit} alt="" />
              )}
            </span>
            <span className="status-column-table" data-status={record.status}>
              {record.status}
            </span>
          </div>
        ),
      },
    ];

    return (
      <Table
        columns={columns}
        dataSource={records.items}
        pagination={false}
        className="color-table"
        bordered
        onRow={(record, index) => {
          return {
            onClick: () => {
              const clickedOrderNumber = record.orderNumber;
              const matchedOrder = valueCheckSheet.find(
                (item) => item.orderNumber === clickedOrderNumber
              );
              if (matchedOrder) {
                console.log(matchedOrder.items); // Items of the matched order
                sessionStorage.setItem("index", record[4]);
                sessionStorage.setItem("id_cs", record["id"]);
                history.push(`/checksheet`);

                const checkListData = sessionStorage.getItem("checkListData");
                if (checkListData === "1") {
                  console.log(123);
                } else {
                  sessionStorage.setItem(
                    "listDataGoc",
                    JSON.stringify(valueCheckSheet)
                  );
                }
              } else {
                console.log("Order not found");
              }
            },
          };
        }}
      />
    );
  };

  useEffect(() => {
    const groupedData = dataCheckSheet.reduce((acc, item) => {
      const { orderNumber } = item;
      if (!acc[orderNumber]) {
        acc[orderNumber] = [];
      }
      acc[orderNumber].push(item);
      return acc;
    }, {});

    const groupedListArray = Object.keys(groupedData).map((orderNumber) => ({
      key: orderNumber,
      orderNumber,
      items: groupedData[orderNumber],
    }));

    setValueCheckSheet(groupedListArray);
  }, []);

  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  return (
    <>
      <Row style={{ margin: 20 }}>
        <Col span={12}>
          <span style={{ fontSize: 18, fontWeight: 600, color: "#0236F1" }}>
            LK - CHECKSHEETS
          </span>
        </Col>
        <Col span={12}>
          <Input placeholder="Search MFG No."></Input>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Table
            columns={columns}
            expandable={{
              expandedRowRender,
              onExpand: (expanded, record) => {
                if (expanded) {
                  setExpandedRowKeys([record.key]); // Expand only the clicked row
                } else {
                  setExpandedRowKeys([]); // Collapse all rows
                }
              },
              expandedRowKeys: expandedRowKeys, // Controlled expanded row state
            }}
            dataSource={
              listDataGoc && listDataGoc.length !== 0
                ? listDataGoc
                : valueCheckSheet
            }
            className="table-list-checksheet"
            onRow={(record) => ({
              onClick: () => {
                const isExpanded = expandedRowKeys.includes(record.key);
                setExpandedRowKeys(isExpanded ? [] : [record.key]);
              },
            })}
          />
        </Col>
      </Row>
    </>
  );
};

export default ListCheckSheets;
