import { Modal, Table } from "antd";
import React from "react";

const ModalCheckSheetError = ({
  isModalOpenCheckSheetError,
  setIsModalOpenCheckSheetError,
  listCheckSheetError,
}) => {
  const handleCancelCheckSheetError = () => {
    setIsModalOpenCheckSheetError(false);
  };

  const result = listCheckSheetError.map((item) => {
    const [key, value] = Object.entries(item).find(([k]) => k !== "cell_error");
    return { name: key, value: value };
  });

  const columns = [
    {
      title: "No",
      dataIndex: "name",
      key: "name",
      align: "center",
    },
    {
      title: "Rules",
      dataIndex: "value",
      key: "value",
      align: "center",
    },
  ];

  return (
    <Modal
      title="Error"
      open={isModalOpenCheckSheetError}
      footer={null}
      onCancel={handleCancelCheckSheetError}
      centered
    >
      <Table
        dataSource={result}
        columns={columns}
        size="small"
        pagination={false}
        scroll={{
            y: 450,
          }}
      />
    </Modal>
  );
};

export default ModalCheckSheetError;
